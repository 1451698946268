import React              from 'react'
import { OrderProvider }  from '../../context/stripeOrder'
import Order              from './constants/agreement'

export default function OrderWrapper(props) {
    const { agreementId } = props.match.params
    return (
        <OrderProvider>
            <Order agreementId={agreementId}/>
        </OrderProvider>
    )
}