// REACT LIBRARY FRAMEWORK
import React from "react";

// THIRD PARTY COMPONENTS
import { Jumbotron, Row } from "react-bootstrap";

// CUSTOM COMPONENTS
import Navbar from "../navbar";

// REACT ICONS
import { IoIosArrowBack } from "react-icons/io";

// COMPONENT
function Terms() {
  return (
    <div>
      <div className="termsBackground">
        <Navbar />
      </div>
      <Jumbotron className="jumboSettings main">
        <Row className="justify-content-center">
          <div className="partnerDiv">
            <div className="ant-row">
              <a href="/" className="router notActive">
                Forsiden
              </a>
              <span className="router">
                <IoIosArrowBack />
                Salgsbetingelser
              </span>
            </div>
            <h1>Vilkår og retningslinjer for gaver og faste giveravtaler</h1>
            <div className="mt-4 terms mb-4">
              <h5>Foretaksinformation:</h5>
              <h3>BRAVE HEART STIFTELSEN</h3>
              <h3>Org. nr: 997 094 867</h3>

              <p className="terms">
                <h5>Post adresse: </h5>Krohnhøgda 9, 6421 Molde
              </p>
              <p className="terms">
                <h5>Besøksadresse:</h5> Tollbodgata 64, 4608 Kristiansand
              </p>
              <p className="terms">
                <h5>Telefonnr:</h5> 920 64 803
              </p>
              <p className="terms">
                <h5>Mobilnr:</h5> 405 41 180
              </p>
              <p className="terms">
                <h5>Epost: </h5>
                <a href="mailto:post@bheart.no">post@bheart.no</a>
              </p>
            </div>
            <p className="mt-4">
              På denne siden finner du våre vilkår og retningslinjer for gaver
              og giveravtaler.
            </p>
            <p className="mt-4">
              Brave Heart Stiftelsen eksisterer for å styrke, tjene og støtte
              flyktninger, vertssamfunn og andre mennesker i nød. BH arbeider
              for å fremme ekstrem godhet mot dem som har opplevd ekstrem
              ondskap. Organisasjonens arbeid er motivert av Guds
              betingelsesløse kjærlighet til alle mennesker, og særlig for dem
              som opplever krig, traumer og andre former for lidelse. Vi
              arbeider for å utrydde fattigdom, bekjempe alle former for urett
              og ulikheter blant flyktninger. Vi gir støtte til skolegang til
              flyktningbarn og voksenopplæring for flyktninger i leiren. Vi
              driver sykehus, ambulansetjeneste og eldresenter. Vi gir
              entreprenørskapskurs og mikrofinans til flyktning kvinner og
              trauma bearbeiding. Vi har fredsbyggende prosjekter, barnehjem og
              omsorgssenter for jenter som har vært utsatt for vold og overgrep.
              Vårt hovedmål er å skape langsiktig og bærekraftig endring for
              flyktninger gjennom å støtte, styrke og gi dem håp på deres vei
              til trygghet og et godt liv.
            </p>
            <div className="mt-4">
              <h4 className="terms">
                Brave Heart Stiftelsen er{" "}
                <a href="https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/">
                  medlem av Innsamlingskontrollen
                </a>
                .
              </h4>
              <h4 style={{ marginLeft: "2rem" }}>1. BETALING</h4>
              <div style={{ marginLeft: "4rem" }}>
                <h5>a. FAST GIVERSERVICE</h5>
                <div style={{ marginLeft: "2rem" }}>
                  <p>
                    • Det gjelder fastgiverforhold per giro, AvtaleGiro og
                    Vipps.
                  </p>
                  <p>
                    • Gjennom å registrere deg her, vil det bli opprettet en
                    AvtaleGiro med banken din som du signerer med Bank-ID eller
                    Bank-ID på mobil.
                  </p>
                  <p>
                    • Som standard gjennomføres trekk den 20. i hver måned.
                    Dette kan tilpasses. Det trekkes aldri annet enn avtalt
                    beløp.
                  </p>
                  <p>
                    • Fastgiverforhold per AvtaleGiro er tilgjengelig i givers
                    nettbank, og trekkes fra ønsket bankkonto.
                  </p>
                  <p>
                    • Ved inngåelse av avtale via Vipps Faste betalinger trekkes
                    første beløp ved avtaleinngåelse.
                  </p>
                  <p>
                    • Fastgiver per Vipps er tilgjengelige i App eller nettside,
                    og trekkes fra ønsket bankkort/ -konto.
                  </p>
                </div>

                <h5>b. ENGANGSGAVER</h5>
                <div style={{ marginLeft: "2rem" }}>
                  <p>
                    i. Betalinger kan gjøres via nett, enten som betaling med
                    bank- og kredittkort eller via Vipps.{" "}
                  </p>
                  <p>
                    ii. Betaling med kort eller vipps blir trukket fra din konto
                    umiddelbart.
                  </p>
                </div>
              </div>
              <h4 style={{ marginLeft: "2rem", marginTop: "2rem" }}>
                2. OPPSIGELSE AV FASTE AVTALER
              </h4>
              <div style={{ marginLeft: "4rem" }}>
                <p className="terms">
                  <h5>a.</h5> Fast giveravtalen løper til den blir sagt opp.{" "}
                </p>
                <p className="terms">
                  <h5>b.</h5> Det kan enkelt sies opp ved å sende en epost til{" "}
                  <a href="mailto:post@bheart.no">post@bheart.no</a> eller ring
                  oss på telefon <h5>40541180</h5> (hverdager mellom kl.09 –
                  15). Dette gjelder avtaler om faste bidrag via Avtalegiro,
                  Vipps og papirgiro.
                </p>
                <p className="terms">
                  <h5>c.</h5> E-post bør inkludere navn, adresse, mobilnummer
                  og/ eller e-postadresse på giver som ønsker å avslutte
                  giverforholdet med Brave Heart Stiftelsen.
                </p>
                <p className="terms">
                  <h5>d.</h5> Ved fastgiverforhold per AvtaleGiro må giver selv
                  inn i nettbanken og slette eventuelle trekk etter avtalen er
                  bekreftet avsluttet, da vi Brave Heart Stiftelsen ikke kan
                  gjøre dette etter trekkfiler er sendt.
                </p>
              </div>
              <h4 style={{ marginLeft: "2rem", marginTop: "2rem" }}>
                3. ANGREVILKÅR
              </h4>
              <div style={{ marginLeft: "4rem" }}>
                <p className="terms">
                  <h5>a.</h5> Inngåelse av faste betalinger
                </p>
                <div style={{ marginLeft: "2rem" }}>
                  <p>
                    • Du har mulighet til å angre ved inngåelse av ordninger med
                    faste betalinger. Med andre ord kan du si opp giveravtalen
                    din hos Brave Heart Stiftelsen når som helst ved å ta
                    kontakt med oss. Dette gjelder både papirgiro, Vipps og
                    AvtaleGiro.
                  </p>
                  <p>
                    • I utgangspunktet er det ikke mulig å trekke tilbake
                    tidligere gitt gaver.
                  </p>
                </div>
              </div>
              <h4 style={{ marginLeft: "2rem", marginTop: "2rem" }}>
                4. PERSONOPPLYSNINGER
              </h4>
              <div style={{ marginLeft: "2rem" }}>
                <p className="terms">
                  Brave Heart Stiftelsen behandler persondata ifølge Lov om
                  Personopplysninger. Opplysninger som kan knyttes til deg som
                  person vil aldri blir gjort tilgjengelig for andre
                  virksomheter eller bli koblet med andre eksterne register.{" "}
                  <a href="/personvernerklæring">
                    Les mer i vår personvernerklæring
                  </a>
                  .
                </p>
              </div>
              <h4 style={{ marginLeft: "2rem", marginTop: "2rem" }}>
                5. KORTINFORMASJON
              </h4>
              <div style={{ marginLeft: "4rem" }}>
                <p className="terms">
                  <h5>a.</h5> Når du gir en enkeltgave på våre hjemmesider blir
                  betalingen behandlet av Stripe, som er en sikker elektronisk
                  betalingsløsning for Visa, MasterCard og AMEX. All
                  kortinformasjon blir oppbevart i henhold til kortselskapenes
                  regelverk. Les mer på{" "}
                  <a href="https://stripe.com/en-no/privacy">stripe.com</a>.
                </p>
                <p className="terms">
                  <h5>b.</h5> Fast giverservice via Solidus blir behandlet i
                  Henhold til deres regelverk. Les mer på{" "}
                  <a href="https://solidus.no/personvernerklaering/">
                    solidus.no
                  </a>
                  .
                </p>
                <p className="terms">
                  <h5>c.</h5> Fast givertjeneste via Vipps faste betalinger blir
                  behandlet av Vipps i helhold til deres regelverk.
                </p>
                <p className="terms">
                  <h5>d.</h5> For spørsmål du måtte ha om vår behandling av dine
                  personopplysninger kan du kontakte oss på{" "}
                  <a href="mailto:post@bheart.no">post@bheart.no</a>.
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Jumbotron>
    </div>
  );
}

export default Terms;
