import React               from 'react'
import Navbar              from '../navbar'
import { innsamling }      from './constants/navigation'
import Body                from './constants/innsamling'
import { LanguageContext } from '../../context/language'
import                     './css/support.css'


function Startinnsamling() {
    const context          = React.useContext(LanguageContext)
    const {lang}           = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='startInnsamlingBackground'/>
                <p className='fotoText'>{ lang === 'eng' ? 'Our mission is to bring joy to all. ' : 'Vårt oppdrag er å gi glede for alle.' }</p>  
            </div>    
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? innsamling.eng : innsamling.nor}
                    <Body/>
                </div>
            </div>            
        </div>
    )
}

export default Startinnsamling
