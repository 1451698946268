import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function Sponsor() {
    const context = React.useContext(LanguageContext)
    const { lang } = context
    const content = {
        eng: (
            <div>
                <h1>Become a Sponsor - make a difference</h1>
                <p>
                    By becoming a sponsor, we stand together and can create lasting change in the lives of children in Meheba.
                    Your support helps us to carry out long-term planning and preventive work together.
                    You are helping to provide vital help to the children for whom we have taken responsibility.
                </p>
                <p>
                    By becoming a sponsor, you will receive an orphanage child to whom your support will go.
                    You will then receive information about this child, and updates on how the child is doing.
                    It is also possible for all sponsors to visit their sponsored child in Meheba.
                    With your support, we can provide education, free healthcare and protection to
                    several children in the Meheba refugee camp. This helps to change and save lives!
                </p>
                <p>Thank you for standing with us!</p>
            </div>
        ),
        nor: (
            <div>
                <h1>Bli Fadder  - gjør en forskjell</h1>
                <p>
                    Ved å bli fadder står vi sammen og kan skape varig endring i livene til barn i Meheba.
                    Din støtte bidrar til at vi sammen kan drive langsiktig planlegging og forebyggende arbeid.
                    Du er med på å gi livsviktig hjelp til barna som vi har tatt på oss ansvaret for.
                </p>
                <p>
                    Ved å bli fadder vil du få et barnehjemsbarn som din støtte går til.
                    Du vil da få informasjon om dette barnet, og oppdateringer på hvordan barnet har det.
                    Det er også mulighet for alle faddere å besøke fadderbarnet sitt i Meheba.
                    Med din støtte kan vi gi utdanning, gratis helsehjelp og beskyttelse til
                    flere barn i Meheba flyktningleir. Dette er med på å forandre og redde liv!
                </p>
                <p>Takk for at du står sammen med oss!</p>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
