import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function NgoConstant() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  const content = {
    eng: (
      <div>
        <h1>Cooperation with local authorities and other NGOs</h1>
        <p>Cooperation with the authorities is important to us. We do not want to come in with our own agenda, but to provide the support that the country and the people themselves need. Therefore, we work closely with the Zambian government through contact with the Commissioner for Refugees in Lusaka, and with local officers inside the camp. We have a good collaboration, and work together to find good solutions for the future.</p>
        <p>The UN is clearly the biggest player in the camp, but they also need support. We have taken over an elderly center after them, and are working to assist an orphanage that they have. We are together in the camp, and we have a common desire to help those in the camp with the best possible help. If we work together, we can complement each other and be stronger in what we do. That is why collaboration is an important part of our work!</p>
      </div>
    ),
    nor: (
      <div>
        <h1>
          Samarbeid med lokale myndigheter og andre ngo
        </h1>
        <p>Samarbeid med myndighetene er viktig for oss. Vi ønsker ikke å komme inn med vår egen agenda, men å gi den støtten som landet og folket selv trenger. Derfor jobber vi tett med Zambias regjering gjennom kontakt med Kommissæren for flyktninger i Lusaka, og med lokale offiserer inne i leiren. Vi har et godt samarbeid, og jobber sammen om å finne gode løsninger for fremtiden.</p>
        <p>FN er klart den største aktøren i leiren, men de trenger også støtte. Vi har tatt over et eldresenter etter dem, og jobber med å bistå et barnehjem som de har. Vi er sammen i leiren, og vi har et felles ønske om å hjelpe de som er i leiren med best mulig hjelp. Hvis vi jobber sammen kan vi utfylle hverandre, og stå sterkere i det vi gjør. Derfor er samarbeid en viktig del av arbeidet vårt! </p>
      </div>
    )
  }
  return lang === 'eng' ? content.eng : content.nor
}
