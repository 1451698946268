import React  from 'react'
import Navbar from '../navbar'
import { LanguageContext }          from '../../context/language'
import { CampaignBox, HeaderImage } from './elements'
import campaign  from './static/child.jpg'
import education from './clinic/skole.JPG'

export default function Main() {
  const context = React.useContext(LanguageContext)
  const { lang } = context
  return (
    <div>
      <Navbar/>
      <div className='navdisplace'>
        <HeaderImage img={campaign}>
          { lang === 'nor' ? 
            <p>
              Sult, overgrep og håpløshet er hverdagen til mange barn og unge i Meheba flyktningleir. Mange går mot jul med mye usikkerhet. Omikron sprer seg raskt i Zambia. Det er ingen intensivenhet eller respirator for å behandle de som blir alvorlig syke. Samtidig er skolestart rundt hjørnet. Et nytt skoleår begynner i januar. Å få gå på skole er ikke en selvfølge. Den blir bare en drøm for de fleste. Foreldrene har ikke råd. Alle ønsker seg en fadder. Juleønsket for mange barn i Meheba er å få lov å gå på skolen igjen. Om du ønsker å bli FADDER til et barn, bli FASTGIVER til HOPE CLINIC,  eller bare ønsker å gi en ENKELTGAVE, er du med å redde liv. Og gaven din varer livet ut!
            </p> :
            <p>
            Hunger, violence and hopelessness is an every day experience for refugee children in Meheba. As we approach Christmas, the future is uncertain for the majority. On hand the omicron virus is spreading pretty fast around Zambia. There is no intensive care unit in Meheba. No respiratory either for those infected. On the other hand, a new school year is starting in January. Attending school is just an unattainable dream for many. Parents cannot afford the cost of tuitions. The Christmas wish for many refugee kids is the opportunity to go Back2School. Would make their wish come true? Whether you choose to sponsor a child to school, to buy medicine for Hope Clinic in Meheba or to donate one time gift, know that you gift will save life. Your gift will create a long lasting impact. 
            </p>
          }
        </HeaderImage>
        <div className='justify-content-center m-0 row'>
          <div className='pageDiv'>
            <CampaignBox>
              <img src={education} alt="education"/>
              { lang === 'eng' ? 
                <p>
                  <h2>Education</h2>
                  Through Brave Heart Foundation you can give, may be the most important Christmas gift have given so far: Education to a refugee child. A gift with meaning. A gift that lasts a lifetime! 
                </p> : 
                <p>
                  <h2>Utdanning</h2>
                  I gjennom stiftelsen Brave Heart kan du gi Årets kanskje viktigste julegave, som er skolegang til et barn.
                </p>
              }
              <a href='/campaigns/education'>
                <button>{lang === 'eng' ? 'Read More' :  'Les Mer'} &#8594;</button>
              </a>
            </CampaignBox>
          </div>
        </div>
      </div>
    </div>
  )
}