import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Button } from 'react-bootstrap'

export default function Regular() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng: (
            <div>
                <h1>Become a regular donor - make a difference </h1>
                <p>
                    By becoming a regular doner, we stand together and can create lasting change in the lives of refugees.
                    Every day we fight to improve the living conditions of refugees in Meheba. Are you with us?
                </p>
                <div>
                    <ul>
                        <li>As a regular donor with us, you are not a sponsor for a refugee, but for all refugees who need our help in the forgotten refugee camp Meheba.</li>
                        <li>That's why we need you. You help us to carry out long-term planning and preventive work together. Without predictable income, it is not possible to ensure sustainable development. It is this work that helps most refugees.</li>
                        <li>You are also helping to provide vital help to the world's most vulnerable and lonely refugee children. </li>
                        <li>You make a life-saving difference and are the backbone of our economy.</li>
                        <li>You are helping to give hope, dignity and kindness to those who have experienced much evil. </li>
                        <li>Your contribution helps to buy medicines, vaccines and provides free health care to over 26,000 refugees in Meheba.</li>
                        <li>Your support goes to our work to protect refugee children from violence, to provide trauma processing and to create jobs for refugees in Meheba who have a daily life characterized by waiting time, unpredictability, trauma and extreme poverty. </li>
                    </ul>
                </div>
                <a href='https://www4.solidus.no/SolidusUser/placeorder/DE1E32D8-A7A6-44EC-9473-43B86D981167'>
                    <Button className='oButtons dOptionButton' variant='outline-primary'>Sign a giro agreement with Vipps or BankID</Button>
                </a>
            </div>
        ),
        nor: (
            <div>
                <h1>Bli fastgiver - gjør en forskjell</h1>
                <p>
                    Ved å bli fastgiver står vi sammen og kan skape varig endring i flyktningers liv.
                    Hver dag kjemper vi for å bedre levekårene for flyktninger i Meheba, og sammen kan vi hjelpe enda flere.
                </p>
                <div>
                    Hvilke muligheter gir faste bidrag?
                    <ul>
                        <li>
                            Det gir forutsigbarhet i økonomien til stiftelsen som kan gi en bærekraftig utvikling for arbeidet.
                        </li>
                        <li>
                            Fastgivere er ryggraden i økonomien vår og bidrar til daglig drift, innkjøp av medisiner
                            som gir gratis helsehjelp, drift av ambulanse, lønn til arbeidere, nødhjelp til både voksne og barn, traumebehandling, m.m.
                        </li>
                    </ul>
                </div>
                <a href='https://www4.solidus.no/SolidusUser/placeorder/DE1E32D8-A7A6-44EC-9473-43B86D981167'>
                    <Button className='oButtons dOptionButton' variant='outline-primary'>Signer avtale med Vipps eller BankID</Button>
                </a>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
