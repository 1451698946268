import React, { useState }                               from 'react'
import formData                                          from 'form-data'
import axios                                             from 'axios'
import { Button }                                        from 'react-bootstrap'
import { TextField, MenuItem }                           from '@material-ui/core'
import { Form, Divider, notification }                   from 'antd'
import Navbar                                            from '../../navbar'
import { LanguageContext }                               from '../../../context/language'
import { projects, frivilig, locations, bigFileMessage } from './navigation'
import                                                   '../css/engasjer.css'

function Frivillig(props) {
    const context                     = React.useContext(LanguageContext)
    const {lang}                      = context
    const [location, setLocation]     = useState('')  
    const [file, setFile]             = useState(null)
    const [firstname, setFirstname]   = useState('')
    const [lastname, setLastname]     = useState('')
    const [email, setEmail]           = useState('')
    const [profession, setProfession] = useState('')
    const [phone, setPhone]           = useState(0)      
    const [about, setAbout]           = useState('')
    const [project, setProject]       = useState('')
    const [filename, setFilename]     = useState('')
  
    const handleProjectSelect         = function(event) { setProject(event.target.value) }
    const handleProfessionChange      = function(event) { setProfession(event.target.value) }
    const handleFirstnameChange       = function(event) { setFirstname(event.target.value) }
    const handleLastnameChange        = function(event) { setLastname(event.target.value) }
    const handleEmailChange           = function(event) { setEmail(event.target.value) }
    const handlePhoneChange           = function(event) { setPhone(parseInt(event.target.value)) }
    const handleAboutChange           = function(event) { setAbout(event.target.value) }
    const handleLocationChnage        = function(event) { setLocation(event.target.value) }

    const handleFileUpload            = function(event) {
        const bigfile = lang === 'eng' ? bigFileMessage.eng : bigFileMessage.nor
        if (event.target.files[0].size > 3000000) {
            alert(bigfile)
            event.target.value = ''
        } 
        setFile(event.target.files[0])
        setFilename(event.target.files[0].name) 
    }

    async function handleSubmit() {
        try {
            // frivillig info
            const frivillig = { firstname, lastname, email, phone, profession, about, allocation: location, project, filename }
            const formdata  = new formData()
            formdata.append('file', file)
            
            // save cv file to database
            await axios.post('https://www.brave-heart.org/upload', formdata, { frivillig, js: true, }).then((result) => {
                console.log(result.data)
                if (result.data.error) return notification.open({
                    message: 'Please try again later.',
                    description: result.data.error.message,
                    duration: 10,
                    style: { border: 'solid 1px red', borderRadius: '4px' },
                })
            })

            // Save To MailChimp Audience.
            /* await axios.post('/addToList/volunteer', {
                reciever: frivillig,    
                js: true,
            }) */

            const response = await axios.post('https://www.brave-heart.org/volunteer', { frivillig, js: true, }).then((result) => {
                return result.data
            })

            if (response.error) return notification.open({
                message: 'Please try again later.',
                description: response.error.message,
                duration: 10,
                style: { border: 'solid 4px red', borderRadius: '4px' },
            })

            return props.history.push({ pathname:'/volunteer-success' , frivillig })
            
        } catch (error) { return notification.open({
                message: 'Please try again later.',
                description: error.message,
                duration: 10,
                style: { border: 'solid 4px red', borderRadius: '4px' },
            })
        }
    }
    
    return (
        <div>           
            <Navbar/>
            <div className='navdisplace'>
                <div className='blifrivilligBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>              
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'> 
                    { lang === 'eng' ? 
                        <div>
                            {frivilig.eng}
                            <h1>Be A Volunteer At Brave Heart </h1>
                            <p>
                                We do need volunteers all the time for both our domestic or international operations. With you on our team we can do more and 
                                achieve more for forgotten refugees. We can create sustainable and durable change for refugees and host community. Through your 
                                voluntary work, you contribute to sharing hope to refugees. At the same time, you also gain knowledge about Brave Heart’s work with 
                                refugees locally and internationally. 
                            </p> 
                            <p>We have many activities you can choose to be involved in. And we will give you the knowledge you need prior to starting with your voluntary work so that you can be safe during your work.</p>
                            <p>Fyll in the form below and a member of our staff will contact you shortly. We look far to having you as our colleague.</p>
                        </div>
                        : 
                        <div>
                            {frivilig.nor}
                            <h1>Bli Frivillig I Brave Heart</h1>
                            <p>
                                Vi har hele tiden behov for deg som vil bidra med frivillig arbeid i stiftelsen. Med deg på laget kan vi gjøre en viktig innsats for glemte 
                                flyktninger. Vi kan skape bærekraftige og varige positive endringer i flyktninger og deres samfunn. Gjennom ditt frivillig arbeid for 
                                Brave Heart bidrar du til å gi mennesker på flukt en bedre fremtid. Samtidig skaffer du deg nyttig kunnskap om både Brave Hearts arbeid 
                                i flyktningleir og lokalt i Norge.
                            </p>
                            <p>Vi har mange aktiviteter du kan engasjere deg i. Hos oss får du god opplæring så du blir trygg i rollen som frivillig.</p>
                            <p>Fyll skjema nedenfor og en medarbeider vil ta kontakt med deg innen kort tid. Vi ser fram til å ha deg som kollega. </p>
                        </div> 
                    }
                    <div className='bliVoluntør row justify-content-center m-0'>
                        <Form onFinish={handleSubmit} className='confirmForm'>
                            <h5 className='text-white m-0'>{lang === 'eng' ? 'Your Information' : 'Din Informasjon'}</h5>
                            <Divider style={{ borderColor: 'white'}}/>
                            <Form.Item><TextField className='voluntørInput' required type='text' id="name" label={lang === 'eng' ? 'Firstname' : "Fornavn"} variant="filled" onChange={handleFirstnameChange}/></Form.Item>
                            <Form.Item><TextField required type='text' id="name" label={lang === 'eng' ? 'Lastname' : "Etternavn"} variant="filled" className='voluntørInput' onChange={handleLastnameChange}/></Form.Item>
                            <Form.Item><TextField required type='email' id="email" label={lang === 'eng' ? 'E-mail' : "Epost"} variant="filled" className='voluntørInput' onChange={handleEmailChange}/></Form.Item>          
                            <Form.Item>
                                <TextField id="prosjectSelect" select label={lang === 'eng' ? 'Choose a project' : "Velg et prosjekt"} value={project} onChange={handleProjectSelect} variant="filled" required className='voluntørInput'>
                                    { lang === 'eng' ?
                                        projects.eng.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        )) :
                                        projects.nor.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Form.Item>
                            <Form.Item>
                                <TextField id="location" select label={lang === 'eng' ? 'Choose a location' : "Velg et sted"} value={location} onChange={handleLocationChnage} variant="filled" required className='voluntørInput'>
                                    { lang === 'eng' ?
                                        locations.eng.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        )) :
                                        locations.nor.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.value}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Form.Item>         
                            <div className='row m-0'>
                                <Form.Item className='mr-2'>
                                    <TextField required type='text' id="yrke" label={lang === 'eng' ? 'Occupation' : "Yrke"} variant="filled" onChange={handleProfessionChange}/>
                                </Form.Item>
                                <Form.Item className='mr-2'>
                                    <TextField required type='number' id="phone" label={lang === 'eng' ? 'Phone number' : "Telefonnummer"} variant="filled" onChange={handlePhoneChange}/>
                                </Form.Item>
                            </div>        
                            <Form.Item>
                                <div className='volunteerInfo'><TextField required multiline rows={10} type='text' id="about" label={lang === 'eng' ? 'Tell us about you ...' : "Fortell oss om deg ..."} variant="filled" className='voluntørInput' onChange={handleAboutChange}/></div>
                            </Form.Item>         
                            <Form.Item>
                                <div className='fileUpload'>
                                    <input type="file" accept='.txt,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' required onChange={handleFileUpload} id='file'/>
                                </div>
                                <p className="MuiFormHelperText-root Mui-required ml-3">{lang === 'eng' ? 'Upload CV': 'Last opp CV'}</p>
                            </Form.Item>        
                            <Form.Item><Button type='submit' variant='outline-primary' className='oButtons w-100'>{lang === 'eng' ? 'Submit' : 'Send'}</Button></Form.Item>
                        </Form>
                    </div>
                </div>
            </div>      
        </div>
    )
}

export default Frivillig
