import React from "react";
import PropTypes from "prop-types";

import injectCss from "./injectCss";
import generateStyleSheet from "./generateStyleSheet";
import { LanguageContext } from "../../../context/language";

const NAME = "ReactBackgroundSlider";

function BackgroundSlider({ images, duration, transition, text, donateText }) {
  React.useEffect(() => {
    injectCss(
      generateStyleSheet({
        imagesCount: images.length,
        duration,
        transition
      }),
      NAME
    );
  });

  // language context
  const context = React.useContext(LanguageContext);
  const { lang } = context;

  return (
    <div id={NAME} className="homeSlider">
      {images.map((img, key) => (
        <>
          <figure
            key={key}
            className="headerDiv row m-0 justify-content-center"
            style={{
              backgroundImage: `url(${img})`,
              boxShadow: "inset 0rem 2rem 10rem #1b2631",
              animationDelay: `${(duration + transition) * key}s`
            }}
          >
            <span>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://nettbutikk.solidus.no/hurtigkasse/brave_heart_stiftelsen/vipps_hurtigkasse"
                >
                  <h1>{lang === "eng" ? text.eng[key] : text.nor[key]}</h1>
                </a>
              </div>
            </span>
          </figure>
        </>
      ))}
    </div>
  );
}

// props
BackgroundSlider.defaultProps = {
  duration: 10,
  transition: 2
};

// proptypes
BackgroundSlider.propTypes = {
  images: PropTypes.array.isRequired,
  duration: PropTypes.number,
  transition: PropTypes.number
};

export default BackgroundSlider;
