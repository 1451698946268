import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function JenteinternatConstant() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  const content = {
    nor: (
      <div>
        <h1>Jenteinternat</h1>
        <p>
          I Meheba er det langt mellom skoler, og leiren er på over 700 km&#xb2;. Konsekvensen av dette er at barn må gå opp til 18 km til skolen, eller bosette seg i nærheten av en skole. Dette byr spesielt på utfordringer for unge jenter som blir sårbare på skoleveien, eller som har flyttet alene og bor ubeskyttet i nærheten av skolen. Dette gjør at det er mange som opplever seksuell-trakasering, og voldtekt. For å være med på å forebygge dette har vi et jenteinternat med gjerde og sikkerhet som i dag huser 40 jenter. Alternativet vi gir de er bedre enn det de hadde, men vi ønsker å kunne utbedre dette tilbudet, og vi ønsker også å kunne ta imot enda flere jenter enn vi har i dag.
        </p>

        <p>
          Vi har også et Safe Home med hemmelig adresse hvor vi huser overgrepsutsatte barn, for å gi dem det de trenger for å kunne bygges opp igjen, og få et verdig liv etter at de har blitt utsatt for uverdige handlinger.
        </p>

      </div>
    ),
    eng: (
      <div>
        <h1>Girls boarding school</h1>
        <p>
          In Meheba it is far between schools, and the camp is over 700 km&#xb2;. The consequence of this is that children have to walk up to 18 km to school, or settle near a school. This presents particular challenges for young girls who become vulnerable on the way to school, or who have moved alone and live unprotected near the school. This means that there are many who experience sexual harassment and rape. To help prevent this, we have a girls' boarding school with a fence and security, which currently houses 40 girls. The alternative we give them is better than what they had, but we want to be able to improve this offer, and we also want to be able to accept even more girls than we have today.
        </p>
        <p>
          We also have a Safe Home with a secret address where we house abused children, to give them what they need to be able to rebuild and get a dignified life after they have been exposed to undignified acts.        </p>
      </div>
    )

  }

  return lang === 'eng' ? content.eng : content.nor
}
