import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Row } from 'react-bootstrap'

// related images
import menigheter from '../files/samarbeid.jpg'
import pureHome from '../files/purehome.jpg'
import hopeClinic from '../files/helse.png'
import road from '../files/road44.jpg'
import utdanning from '../files/utdanning.png'
import peace from '../files/peace.jpg'
import jenteinternat from '../files/barnehjem.jpg'
import idrett from '../files/idrett.png'
import jobb from '../files/jobs.png'

export default function MainConstant() {
    const context = React.useContext(LanguageContext)
    const { lang } = context
    const content = {
        eng: (
            <div>
                <h1>Our work in Norway and internationally</h1>
                <Row className='støttMain m-0'>
                    <Row className='støttDiv'>
                        <a href='/hopeClinic'>
                            <img src={hopeClinic} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Hope Clinic</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/purehome'>
                            <img src={pureHome} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Pure Hope Children's Home</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/road44'>
                            <img src={road} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Road 44</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/utdanning'>
                            <img src={utdanning} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Education</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/peace'>
                            <img src={peace} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Peace Project</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/jenteinternat'>
                            <img src={jenteinternat} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Girls boarding school</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/sport'>
                            <img src={idrett} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Support for sports</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/jobb'>
                            <img src={jobb} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Job creation and entrepreneurship</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/menigheter'>
                            <img src={menigheter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Cooperation with local congregations</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/ngo'>
                            <img src={menigheter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Cooperation with local authorities and other NGOs</h5>
                        </a>
                    </Row>

                </Row>
            </div>
        ),
        nor: (
            <div>
                <h1>Vårt arbeid i Norge og internasjonalt</h1>
                <Row className='støttMain m-0'>
                    <Row className='støttDiv'>
                        <a href='/hopeClinic'>
                            <img src={hopeClinic} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Hope Clinic</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/purehome'>
                            <img src={pureHome} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Pure Hope Children's Home</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/road44'>
                            <img src={road} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Road 44</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/utdanning'>
                            <img src={utdanning} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Utdanning</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/peace'>
                            <img src={peace} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Peace Project</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/jenteinternat'>
                            <img src={jenteinternat} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Jenteinternat</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/sport'>
                            <img src={idrett} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Støtte til idrett</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/jobb'>
                            <img src={jobb} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Jobbskaping</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/menigheter'>
                            <img src={menigheter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Samarbeid med menigheter</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/ngo'>
                            <img src={menigheter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='eager' />
                            <h5 className='stottText text-white'>Samarbeid med myndigheter</h5>
                        </a>
                    </Row>
                </Row>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
