import React from 'react'
import { LanguageContext } from '../../../context/language'
import {forbruk} from '../constants/navigation'
import Navbar from '../../navbar'
import m12 from '../files/m12.png'

export default function Ansavrligforbruk() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div>          
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? forbruk.eng : forbruk.nor}  
                    <div>
                        {lang === 'eng' ? <h1>Responsible consumption and production </h1> : <h1>Ansvarlig forbruk og produksjon</h1>}
                        <img src={m12} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        {lang === 'eng' ? 
                            <p>
                                In fact, as much as 1.3 billion tons of food is thrown away annually in the world. This
                                had been enough to feed all the malnourished in the whole world! We have already said
                                that food supply and agriculture are important to us, but so is consumption.
                                We always try to repair and save before we throw, that's how our whole operation is.
                                Both in Meheba and Norway. We want to lead by example. And for our friends
                                in Meheba, and all children in the future, should be able to have the opportunity to live a good life - must
                                we all help to lower our consumption. Therefore, this goal means a lot to us, because we want
                                that everyone should be able to have the opportunity for a good and secure future. 
                            </p> 
                            : 
                            <p>
                                Faktisk kastes det så mye som 1.3 milliarder tonn mat, årlig i verden. Dette 
                                hadde vært nok til å mette alle underernærte i hele verden! Vi har allerede sagt 
                                at mattilførsel og jordbruk er viktig for oss, men det er også forbruk. 
                                Vi prøver alltid å reparere og redde før vi kaster, sånn er hele vår operasjon. 
                                Både i Meheba og Norge. Vi ønsker å lede med et godt eksempel. Og for at våre venner 
                                i Meheba, og alle barn i fremtiden, skal kunne få muligheten til å leve et godt liv – må 
                                vi alle bidra å senke forbruket vårt. Derfor betyr dette målet mye for oss, fordi vi ønsker 
                                at alle skal kunne ha muligheten til en god og trygg fremtid. 
                            </p>                           
                        }
                    </div>
                </div>
            </div>            
        </div>
    )
}