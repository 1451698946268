import styled from "styled-components"

export const CampaignBody = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 6rem 0;
`


export const ClinicContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  & button, a {
    width: 100%;
    border-radius: 12px;
    border: none;
    height: 4rem;
    background-color: #009EE0;
    color: white;
    margin-bottom: 1rem;
  }
`

export const ClinicCampaignContainer = styled.div`
  width: 100%;
  max-width: 980px;
  padding: 2rem;
  & button {
    background-color: transparent;
    border: none;
  }
  & .campaign {
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-content: center;
    width: 100%;
    max-width: 300px;
    border-radius: 12px;
    border: none;
    height: 4rem;
    padding: 8px;
    background-color: #009EE0;
    color: white;
    margin: 1rem;
  }
  & h1 {
    display: inline-block;
    margin-bottom: 1rem;
  }
  `

export const CampaignImage = styled.div`
  width: 100%;
  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`