import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Button } from 'react-bootstrap'
import { Progress } from 'antd'
import inslg from '../files/innsamlingskontrollen.png'

export function Formaal() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng: (
            <div>
                <div className='m-5 text-center'>
                    <h3>How much money goes into our objective</h3>
                    <div className='row justify-content-center text-white m-0'>
                        <div className='progressText row m-2 justify-content-center text-center'>
                            <Progress type="circle" percent={98.1} strokeColor={{ '0%': 'red', '100%': '#F24934', }} strokeWidth={4} width={120} />
                            <p>Collection percentage</p>
                        </div>
                        <div className='progressText row m-2 justify-content-center text-center'>
                            <Progress type="circle" percent={6.3} strokeColor={{ '0%': 'red', '100%': '#F24934', }} strokeWidth={4} width={120} />
                            <p>Administration percentage</p>
                        </div>
                        <div className='progressText row m-2 justify-content-center text-center'>
                            <Progress type="circle" percent={93.3} strokeColor={{ '0%': 'red', '100%': '#F24934', }} strokeWidth={4} width={120} />
                            <p>Purpose percentage</p>
                        </div>
                    </div>
                    <div className='row justify-content-center m-0 align-items-center'>
                        <a rel="noopener noreferrer" target='_blank' href='https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/'>
                            <span><img id='insamlingsLogo' src={inslg} alt='insamlingskontrollen' width='60px' height='54px' loading='lazy' /> Member of innsamlingskontrollen</span>
                        </a>
                    </div>
                </div>
                <div className='text-center'>
                    <p>Every donation makes a difference no matter how small</p>
                </div>
                <div className='justify-content-center row m-0'>
                    <a href='/stottOss'><Button className='oButtons' variant='outline-primary'>Give support now</Button></a>
                </div>
            </div>
        ),
        nor: (
            <div>
                <div className='m-5 text-center'>
                    <h3>Hvor mye penger går til formålet</h3>
                    <div className='row justify-content-center text-white m-0'>
                        <div className='progressText row m-2 justify-content-center text-center'>
                            <Progress type="circle" percent={98.1} strokeColor={{ '0%': 'red', '100%': '#F24934', }} strokeWidth={4} width={120} />
                            <p>Innsamlingsprosent</p>
                        </div>
                        <div className='progressText row m-2 justify-content-center text-center'>
                            <Progress type="circle" percent={6.3} strokeColor={{ '0%': 'red', '100%': '#F24934', }} strokeWidth={4} width={120} />
                            <p>Administrasjonsprosent</p>
                        </div>
                        <div className='progressText row m-2 justify-content-center text-center'>
                            <Progress type="circle" percent={93.3} strokeColor={{ '0%': 'red', '100%': '#F24934', }} strokeWidth={4} width={120} />
                            <p>Formålsprosent</p>
                        </div>
                    </div>
                    <div className='row justify-content-center m-0 align-items-center'>
                        <a rel="noopener noreferrer" target='_blank' href='https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/'>
                            <span><img id='insamlingsLogo' src={inslg} alt='insamlingskontrollen' width='60px' height='54px' loading='lazy' /> Medlem av innsamlingskontrollen</span>
                        </a>
                    </div>
                </div>
                <div className='text-center'>
                    <p>Hver donasjon gjør en forskjell uansett hvor liten </p>
                </div>
                <div className='row justify-content-center'>
                    <a href='/stottOss'><Button className='oButtons' variant='outline-primary'>Gi støtte nå</Button></a>
                </div>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
