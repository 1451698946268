import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function HelseConstant() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    const content = {
        eng: (
            <div>
                <h1>Health</h1>
                <h3>
                    Status 2021: Employees 25, midwife, general practitioner, nurses, clinic officers, pharmacy technicians, cleaners, kitchen staff
                    Patients / consultations / treatments per year approx: 5000 - check facts 
                </h3>
                <p>
                    In the Meheba refugee community live around 30,000 people who have had a bad situation for a very long time
                    and poorly developed health services. Malnutrition, malaria, unclean water, HIV and poor living conditions combined
                    with a weak health service leads to a lot of illness and suffering. For many, it also means a short life.  
                </p>
                <p>
                    The Brave Heart Foundation saw this and started in 2011 to train nurses. With volunteer doctors and nurses
                    from Norway, we conducted a number of health days in Meheba's many villages. We started handing out medicines
                    and had many seriously ill people sent to hospitals. In 2014, our offer to include ambulance and we increased
                    opened a long-distance clinic. Since 2015, we have run a large village clinic 24/7 that saves lives every day
                    and which annually reaches thousands of patients. 
                </p>
                <p>
                    Klinikksjef: Millika. , bilde , tittel, Jordmor 
                    Lege: Dr. Jeff ...   bilde 
                    Prosjektansvarlig og medisinsk ansvarlig: Steinar Taksdal – lege. Bilde 
                </p>
                <p>
                    Do you want to volunteer at our clinic?
                    Become a regular doner - giving health costs! <a href="/gigave">Click her to register</a>. 
                </p>

            </div>
        ),
        nor: (
            <div>
                <h1>Helse</h1>
                <h3>
                    Status 2021: Ansatte 25, jordmor, allmennlege, sykepleiere, klinikkoffiserer, apotekteknikere, renholdere, kjøkkenpersonell 
                    Pasienter/ konsultasjoner/ behandlinger pr år ca: 5000 – sjekk fakta 
                </h3>
                <p>
                    I Meheba flyktningesamfunn lever rundt 30.000 mennesker som i svært lang tid har hatt dårlig 
                    og lite utviklet helsetilbud. Feilernæring, malaria, urent vann, HIV og dårlige boforhold kombinert 
                    med et svakt helsetilbud fører til mye sykdom og lidelse. For mange betyr det også et kort liv.  
                </p>
                <p>
                    Brave Heart Stiftelsen så dette og startet i 2011 å trene sykepleiere. Med frivillige leger og sykepleiere 
                    fra Norge gjennomførte vi en rekke helsedager i Mehebas mange landsbyer. Vi startet å dele ut medisiner 
                    og fikk sendt mange alvorlig syke til sykehus.  I 2014 økte tilbudet vårt til å inkludere ambulanse og vi 
                    åpnet en langsbyklinikk. Siden 2015 har vi drevet en stor landsbyklinikk 24/7 som hver dag redder liv 
                    og som årlig når tusenvis av pasienter.
                </p>
                <p>
                    Klinikksjef: Millika. , bilde , tittel, Jordmor 
                    Lege: Dr. Jeff ...   bilde 
                    Prosjektansvarlig og medisinsk ansvarlig: Steinar Taksdal – lege. Bilde 
                </p>
                <p>
                    Ønsker du å jobbe frivillig på vår klinikk? 
                    Bli fastgiver – å gi helse koster! <a href="/gigave">Registerer her</a>. 
                </p>
                
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
