import React from 'react'
import { LanguageContext } from '../../../context/language'
import {likestilling} from '../constants/navigation'
import Navbar from '../../navbar'
import m5 from '../files/m5.png'

export default function Likestilling() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div>          
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? likestilling.eng : likestilling.nor}
                    <div>
                        {lang === 'eng' ? <h1>Gender equality</h1> : <h1>Likestilling mellom kjønnene</h1>}
                        <img src={m5} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        {lang === 'eng' ? 
                            <p>
                                Gender equality is very important, there are many large families in Meheba.
                                And we want these families to feel good about each other. In some parts of the world one has
                                not the same seems to the sexes as we have, but we are actively working to change this. 

                                We hold courses and workshops for women and men, to teach them about equality.
                                This sustainability goal also applies to discrimination and violence. These are also topics like
                                is repeated in our teaching and workshops. This has proven to be inconceivable
                                great value for the families in Meheba. And this in turn helps the children again, as now
                                to a greater extent than before growing up in safer homes. And then they are also safer people.
                                Read more about this <a href="/coming-soon">here (LINK 2) </a> we have a lot to tell! 

                            </p> 
                            : 
                            <p>
                                Likestilling mellom kjønnene er veldig viktig, det finnes mange og store familier i Meheba. 
                                Og vi ønsker at disse familiene skal ha det bra med hverandre. I noen deler av verden har man 
                                ikke det samme synes på kjønnene slik vi har, men dette jobber vi aktivt for å endre.

                               
                                Vi holder kurs og workshops for kvinner og menn, for å lære dem om likestilling. 
                                Dette bærekraftmålet gjelder også diskriminering og vold. Dette er også temaer som 
                                går igjen i undervisningene og workshopene våre. Dette har vist seg å ha ufattelig 
                                stor verdi for familiene i Meheba. Og dette hjelper på sin side igjen barna, som nå 
                                i større grad enn før vokser opp i tryggere hjem. Og da er de også tryggere mennesker.  
                                Les mer om dette <a href="/coming-soon">her (LINK 2)</a> vi har mye å fortelle!
                                
                            </p> 
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}