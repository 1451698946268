import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'

export const main = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <span className='router'><IoIosArrowBack />get involved</span>
        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <span className='router'><IoIosArrowBack />engasjer deg</span>
        </div>
    )
}

export const frivilig = {
    eng: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>home</a>
            <a href='/engasjer' className='router notActive'><IoIosArrowBack />get involved</a>
            <span className='router'><IoIosArrowBack />volunteer</span>
        </div>
    ),
    nor: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/engasjer' className='router notActive'><IoIosArrowBack />engasjer deg</a>
            <span className='router'><IoIosArrowBack />frivillig</span>
        </div>
    )
}

// projects
export const projects = {
    nor: [
        { value: 'Helse og Trauma bearbeiding' },
        { value: 'Utdanning og å beskytte jenter mot vold og barneekteskap' },
        { value: 'Utrydde sult, fattigdom og Ulikheter' },
        { value: 'Misjon' },
    ],
    eng: [
        { value: 'Health and Trauma Processing ' },
        { value: 'Education and protecting girls from violence and child marriage ' },
        { value: 'Eradicate hunger, poverty and inequality ' },
        { value: 'Mission ' },
    ]
}

// locations
export const locations = {
    eng: [
        { value: 'Zambia' },
        { value: 'Norway' },
    ],
    nor: [
        { value: 'Zambia' },
        { value: 'Norge' },
    ]
}

// file size message
export const bigFileMessage = {
    eng: 'The CV file is too large! Maximum size is set to 3 MB. ',
    nor: 'CV-filen er for stor! Maks størrelse er satt til 3 MB.',
}