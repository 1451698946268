import React                        from 'react'
import axios                        from 'axios'
const OrderContext                  = React.createContext()

function OrderProvider(props) {
    // state
    const [order, setOrder]         = React.useState(null)
    const [agreement, setAgreement] = React.useState(null)

    // fetch agreement information
    async function collectAgreement(id) {
        if (!id || id === null || id === undefined) return setAgreement({ status: 'EXPIRED' })
        try {
            // request
            const {status, price, productDescription} = await axios.get('https://www.brave-heart.org/vipps/recurring/agreements/', 
                {headers: {agreementId: id},}).then((result) => result.data) 
            if (status) return setAgreement({status, price, productDescription})
        } catch (error) { return setAgreement({ status: 'ERROR' }) }
    }

    // fetch order information
    async function collectOrder(id) {
        try {
            const options = {
                method: 'GET',
                url: 'https://www.brave-heart.org/vipps/details',
                headers: {order_id: id},
            }
            // request
            const result = await axios(options)
            // response
            if(result.data.order) {
                setOrder(result.data.order)
            } else { setOrder({error: {message: { nor: 'Fant ikke bestillingsdetaljer, prøv igjen senere.', eng: 'Could not find order details please try again later.' }}}) }
        } catch (error) { setOrder({error: {message: { nor: 'Fant ikke bestillingsdetaljer, prøv igjen senere.', eng: 'Could not find order details please try again later.' }}}) }
    }

    return (
        <OrderContext.Provider value={{order, collectOrder, agreement, collectAgreement}}>
            {props.children}
        </OrderContext.Provider>
    )
}

export { OrderContext, OrderProvider }