import React from 'react'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { RiEmpathizeLine } from 'react-icons/ri'
import { BsPeople } from 'react-icons/bs'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import { Row, Col } from 'react-bootstrap'

// engasjer deg menu
export const EngasjerDegMenu = {
    eng:
        <div className="drop-content">
            <Row className='justify-content-center align-items-center'>
                <a href='/frivillig' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <AiOutlineUsergroupAdd className='iconSettings' />
                        <Col>
                            <h6>Volunteer in Norway</h6>
                            <p>Join us in Norway</p>
                        </Col>
                    </Row>
                </a>
                <a href='/frivillig' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <AiOutlineUsergroupAdd className='iconSettings' />
                        <Col>
                            <h6>Volunteer in Zambia</h6>
                            <p>Join us in Zambia</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <RiEmpathizeLine className='iconSettings' />
                        <Col>
                            <h6>Take action</h6>
                            <p>Engage in big events</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaChalkboardTeacher className='iconSettings' />
                        <Col>
                            <h6>School sponsorship</h6>
                            <p>Help provide education</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaChalkboardTeacher className='iconSettings' />
                        <Col>
                            <h6>Colleges and universities</h6>
                            <p>Become a school partner</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BsPeople className='iconSettings' />
                        <Col>
                            <h6>Congregations</h6>
                            <p>Spread the message to others</p>
                        </Col>
                    </Row>
                </a>
            </Row>
        </div>,
    no:
        <div className="drop-content">
            <Row className='justify-content-center align-items-center'>
                <a href='/frivillig' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <AiOutlineUsergroupAdd className='iconSettings' />
                        <Col>
                            <h6>Bli frivillig i Norge</h6>
                            <p>Bli med oss i Norge</p>
                        </Col>
                    </Row>
                </a>
                <a href='/frivillig' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <AiOutlineUsergroupAdd className='iconSettings' />
                        <Col>
                            <h6>Bli frivillig i Zambia</h6>
                            <p>Bli med oss i Zambia</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <RiEmpathizeLine className='iconSettings' />
                        <Col>
                            <h6>Din påvikringskraft</h6>
                            <p>Delta i store arrangementer</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaChalkboardTeacher className='iconSettings' />
                        <Col>
                            <h6>Skole fadder</h6>
                            <p>Hjelp med å gi utdanning</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaChalkboardTeacher className='iconSettings' />
                        <Col>
                            <h6>Høyskoler og universiteter</h6>
                            <p>Bli skolepartner</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BsPeople className='iconSettings' />
                        <Col>
                            <h6>Menigheter</h6>
                            <p>Spre budskapet til andre</p>
                        </Col>
                    </Row>
                </a>
            </Row>
        </div>
}