import React              from 'react'
import { OrderProvider }  from '../../context/stripeOrder'
import Order              from './constants/order'

export default function OrderWrapper(props) {
    const { orderId } = props.match.params
    return (
        <OrderProvider>
            <Order orderId={orderId}/>
        </OrderProvider>
    )
}