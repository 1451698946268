import React from "react";
import { LanguageContext } from "../../../context/language";
import { Row } from "react-bootstrap";
import { Tooltip } from "antd";
import { bedriftsSamarbeidMenu } from "./bedrift";
import { EngasjerDegMenu } from "./engasjer";
import { StottOssMenu } from "./stottoss";
import { VårtArbeidMenu } from "./vårtarbeid";
import logo from "../files/bheart.svg";

export function DesktopNav() {
  // context
  const context = React.useContext(LanguageContext);

  // language
  const { lang, setEnglish, setNorwegian } = context;

  // pathname
  const __lcn = window.location.pathname;

  // desktop nav
  const content = {
    eng: (
      <div className="DesktopNav" id="DesktopNav">
        <Row className="justify-content-around align-items-center">
          <Row className="align-items-center">
            <div className="Logo">
              <a href="/">
                <img src={logo} alt="Logo" height="50px" width="165px" />
              </a>
            </div>
          </Row>
          <Row className="align-items-center m-0 justify-content-center">
            <Row className="DesktopNavLinks align-items-center m-0">
              <a
                href="/omOss"
                className={
                  __lcn === "/omOss"
                    ? "text-brown orangeHover"
                    : "text-white orangeHover"
                }
              >
                About Us
              </a>
              <Tooltip
                title={StottOssMenu.eng}
                color="white"
                placement="bottom"
                id="stottOssDropDown"
                mouseLeaveDelay={0.1}
              >
                <a
                  id="dropDownLink"
                  href="/stottOss"
                  className={
                    __lcn === "/stottOss"
                      ? "text-brown orangeHover"
                      : "text-white orangeHover"
                  }
                >
                  Support Us
                </a>
              </Tooltip>
              <Tooltip
                title={VårtArbeidMenu.eng}
                color="white"
                placement="bottom"
                id="vårtArbeidDropDown"
                mouseLeaveDelay={0.1}
              >
                <a
                  id="dropDownLink"
                  href="/arbeid"
                  className={
                    __lcn === "/arbeid"
                      ? "text-brown orangeHover"
                      : "text-white orangeHover"
                  }
                >
                  Our Work
                </a>
              </Tooltip>
              <Tooltip
                title={EngasjerDegMenu.eng}
                color="white"
                placement="bottom"
                id="engasjerDegDropdown"
                mouseLeaveDelay={0.1}
              >
                <a
                  id="dropDownLink"
                  href="/engasjer"
                  className={
                    __lcn === "/engasjer"
                      ? "text-brown orangeHover"
                      : "text-white orangeHover"
                  }
                >
                  Get Involved
                </a>
              </Tooltip>
              <Tooltip
                title={bedriftsSamarbeidMenu.eng}
                color="white"
                placement="bottom"
                id="bedriftsSamarbeidDropdown"
                mouseLeaveDelay={0.1}
              >
                <a
                  href="/partner"
                  className={
                    __lcn === "/partner"
                      ? "text-brown orangeHover"
                      : "text-white orangeHover"
                  }
                >
                  Become A Partner
                </a>
              </Tooltip>
              <a
                href="https://crm.solidus.no/minside?ID=0107000000000010"
                className="language text-white"
              >
                My Page
              </a>
              <div
                className="language text-white"
                onClick={() => setNorwegian("nor")}
              >
                Norwegian
              </div>
            </Row>
          </Row>
        </Row>
      </div>
    ),
    no: (
      <div className="DesktopNav" id="DesktopNav">
        <Row className="justify-content-around align-items-center">
          <Row className="align-items-center">
            <div className="Logo">
              <a href="/">
                <img src={logo} alt="Logo" height="50px" width="165px" />
              </a>
            </div>
          </Row>
          <Row className="align-items-center m-0">
            <Row className="DesktopNavLinks align-items-center m-0">
              <a
                href="/omOss"
                className={
                  __lcn === "/omOss"
                    ? "text-brown orangeHover"
                    : "text-white orangeHover"
                }
              >
                Om Oss
              </a>
              <Tooltip
                title={StottOssMenu.no}
                color="white"
                placement="bottom"
                id="stottOssDropDown"
                mouseLeaveDelay={0.1}
              >
                <a
                  id="dropDownLink"
                  href="/stottOss"
                  className={
                    __lcn === "/stottOss"
                      ? "text-brown orangeHover"
                      : "text-white orangeHover"
                  }
                >
                  Støtt Arbeidet
                </a>
              </Tooltip>
              <Tooltip
                title={VårtArbeidMenu.no}
                color="white"
                placement="bottom"
                id="vårtArbeidDropDown"
                mouseLeaveDelay={0.1}
              >
                <a
                  id="dropDownLink"
                  href="/arbeid"
                  className={
                    __lcn === "/arbeid"
                      ? "text-brown orangeHover"
                      : "text-white orangeHover"
                  }
                >
                  Vårt Arbeid
                </a>
              </Tooltip>
              <Tooltip
                title={EngasjerDegMenu.no}
                color="white"
                placement="bottom"
                id="engasjerDegDropdown"
                mouseLeaveDelay={0.1}
              >
                <a
                  id="dropDownLink"
                  href="/engasjer"
                  className={
                    __lcn === "/engasjer"
                      ? "text-brown orangeHover"
                      : "text-white orangeHover"
                  }
                >
                  Engasjer Deg
                </a>
              </Tooltip>
              <Tooltip
                title={bedriftsSamarbeidMenu.no}
                color="white"
                placement="bottom"
                id="bedriftsSamarbeidDropdown"
                mouseLeaveDelay={0.1}
              >
                <a
                  href="/partner"
                  className={
                    __lcn === "/partner"
                      ? "text-brown orangeHover"
                      : "text-white orangeHover"
                  }
                >
                  Bli Partner
                </a>
              </Tooltip>
              <a
                href="https://crm.solidus.no/minside?ID=0107000000000010"
                className="language text-white"
              >
                Min Side
              </a>
              <div
                className="language text-white"
                onClick={() => setEnglish("eng")}
              >
                English
              </div>
            </Row>
          </Row>
        </Row>
      </div>
    )
  };
  return lang === "nor" ? content.no : content.eng;
}
