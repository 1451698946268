import React             from 'react'
import { OrderProvider } from '../../context/vippsOrder'
import Agreement         from './constants/agreementStatus'

export default function AgreementWrapper() {
    return (
        <OrderProvider>
            <Agreement/>
        </OrderProvider>
    )
}