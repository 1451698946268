import React from 'react'
import Navbar from '../navbar'
import { LanguageContext } from '../../context/language'
import { main } from './constants/navigator'
import Main from './constants/main'
import './css/donations.css'

export default function Index() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='stottOssBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Your donation can bring about change.' : 'Donasjonen din kan medføre endring.'}</p>
            </div>
            <div className='justify-content-center m-0 row'>
                <div className='pageDiv'>
                    {lang === 'eng' ? main.eng : main.nor}
                    <Main/>
                </div>
            </div>
        </div>
    )
}