import React from 'react'
import { LanguageContext } from '../../../context/language'
import {ulikhet} from '../constants/navigation'
import Navbar from '../../navbar'
import m10 from '../files/m10.png'

export default function Mindreulikhet() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    
    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div>
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'> 
                    {lang === 'eng' ? ulikhet.eng : ulikhet.nor}
                    <div>
                        { lang === 'eng' ? <h1>Less inequality</h1> : <h1>Mindre ulikhet</h1>}
                        <img src={m10} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        {lang === 'eng' ?
                            <p>
                                Great social inequality can create great unrest. We work actively 
                                to ensure that everyone in Meheba has the same opportunities, we have 
                                not reached that goal yet. Fortunately, we are well on our way!
                                By reducing inequality between people, we also create better opportunities 
                                for the relationship between people to improve. Therefore, poverty and access 
                                to good education are important for this goal to be achieved. We really want 
                                that, that is also why these <a href="/coming-soon">(LINK 4)</a> projects are so important to us! 
                            </p> 
                            : 
                            <p>
                                Stor sosial ulikhet kan skape stor uro. Vi arbeider aktivt for at alle i 
                                Meheba skal kunne få de samme mulighetene, vi har ikke nådd det målet helt enda. 
                                Heldigvis, er vi på god vei!  
                                Ved å minske ulikhet mellom mennesker, skaper vi også bedre muligheter for 
                                at relasjonen mellom folk bedres. Derfor er fattigdom, og tilgang til 
                                god utdannelse viktig for at dette målet skal nås. Vi ønsker virkelig det, 
                                det er også derfor disse <a href="/coming-soon">(LINK 4)</a> prosjektene er såpass viktige for oss!
                            </p>  
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}