import React from "react";

import Navbar from "../navbar";
import Mainheader from "./constants/parallax";
import { DonateFrame } from "./constants/donateFrame";
import FormaalProsent from "./constants/formalprosent";

import "./css/home.css";

function Homepage() {
  return (
    <div>
      <Navbar />
      <Mainheader />
      <DonateFrame />
      <FormaalProsent />
    </div>
  );
}

export default Homepage;
