import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'

export const main = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <span className='router'><IoIosArrowBack />sustainability goals</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <span className='router'><IoIosArrowBack />bærekraftsmål</span>
        </div>
    )
}

export const fattigdom = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />eradicate poverty </span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />utrydde fattigdom</span>
        </div>
    )
}

export const sult = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />eradicate hunger</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />utrydde sult</span>
        </div>
    )
}

export const godhelse = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />good health</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />god helse</span>
        </div>
    )
}

export const utdanning = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />good education</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />god utdanning</span>
        </div>
    )
}

export const likestilling = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />gender equality</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />likestilling</span>
        </div>
    )
}

export const rentvann = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />clean water and good sanitation</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />rent vann og gode sanitærforhold</span>
        </div>
    )
}

export const ulikhet = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />less inequality</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />mindre ulikhet</span>
        </div>
    )
}

export const forbruk = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />consumption and production</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />forbruk og produksjon</span>
        </div>
    )
}

export const klima = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />stop climate change</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />stoppe klimaendringene</span>
        </div>
    )
}

export const livet = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />life on the land</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />livet på land</span>
        </div>
    )
}

export const fred = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />justice and well-functioning</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />rettiferdighet og velfungerende</span>
        </div>
    )
}

export const samarbeid = {
    eng: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>home</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />sustainability goals</a>
            <span className='router'><IoIosArrowBack />cooperation</span>
        </div>
    ),
    nor: (
        <div className='row m-0'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/bærekraftsmål' className='router notActive'><IoIosArrowBack />bærekraftsmål</a>
            <span className='router'><IoIosArrowBack />samarbeid</span>
        </div>
    )
}