
import React from 'react'
import { LanguageContext } from '../../../context/language'
import {livet} from '../constants/navigation'
import Navbar from '../../navbar'
import m15 from '../files/m15.png'

export default function Livet() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div>          
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? livet.eng : livet.nor}
                    <div>
                        {lang === 'eng' ? <h1>Life on the land </h1> : <h1>Livet på land</h1>}
                        <img src={m15} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        { lang === 'eng' ? 
                            <p>
                                1.6 billion people live off what the forest offers. Climate change and deforestation, 
                                desertification are reducing diversity at an alarming rate. We have an obligation to slow 
                                down and stop this development. This is also about ensuring good topsoil by 2030. (15)
                                Unfortunately, climate change, if left unchecked, will hit the poorest first. 1.6 billion people 
                                in the world live off what the forest offers. The people of Meheba do the same, they are out in 
                                their fields planting all kinds of plants. They fish and chop wood, just like us. Unfortunately, 
                                climate change threatens these forests, rivers and soils. And then the situation, for everyone, 
                                will get worse. We are goal-oriented, and focused on helping to protect life on land. We do our 
                                part of the charity - and we are proud of it! 
                            </p>
                            :
                            <p>
                                1.6 milliarder mennesker lever av det skogen byr på. Klimaendringer og avskoging, ørkenspredning 
                                redusere mangfoldet i et alarmerende tempo. Vi har en forpliktelse til å senke og stoppe denne 
                                utviklingen. Dette handler også om å sikre god matjord innen 2030. (15) 
                                Klimaendringene vil dessverre, om de ikke stoppes, ramme de fattigste først. 1.6 milliarder 
                                mennesker i verden lever av det skogen byr på. Også folkene i Meheba gjør det, de er ute på åkrene 
                                sine og planter alle slags planter. De fisker og hugger ved, akkurat sånn som oss. Dessverre truer 
                                klimaendringene disse skogene, elvene og jordene. Og da vil situasjonen, for alle, bli verre. 
                                Vi er målrettet, og fokuserte på at vi skal være med å beskytte livet på land. Vi gjør vår del av dugnaden – og vi er stolte av det!
                            </p>
                        }  
                    </div>                  
                </div>
            </div>  
        </div>
    )
}