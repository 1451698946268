import React from 'react'
import NavBar from '../navbar'
import { About } from './constants/about'
import { Navigator } from './constants/navigator'
import { Employee } from './constants/employee'
import { LanguageContext } from '../../context/language'
import { Accordions } from './constants/accordions'
import './css/omoss.css'


function Omoss() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    return (
        <div>
            <NavBar />
            <div className='navdisplace'>
                <div className='omOssBackground' />
                <p className='fotoText'>{lang === 'eng' ? 'Promote diginity, fight inequality and eradicate poverty.' : 'Fremme verdighet, bekjempe ulikhet og utrydde fattigdom.'}</p>
            </div>
            <div className='justify-content-center m-0 row'>
                <div className='pageDiv'>
                    <div>
                        <Navigator />
                        <About />
                        <div className="mt-3 mb-5"><a href='brave-heart-historie'><button className='oButtons'>{lang === 'eng' ? 'More about us' : 'Mer om oss'}</button></a></div>
                        <div className="mt-3 mb-5"><Accordions /></div>
                        <div className='mt-5'><Employee /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Omoss
