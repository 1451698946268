import React from "react";

import BackgroundSlider from "../backgroundSlider";

// slider images
import verdighet from "../files/home/verdighet.jpg";
import sult from "../files/home/sult.jpg";
import cgrandma from "../files/home/6.jpg";
import redde from "../files/home/redde-liv.jpg";
import framtid from "../files/home/håp-fremtid.jpg";
import fattigdom from "../files/home/utrydde-fattigdom.jpg";

// text to images
const eng = [
  "Eradicate Poverty",
  "Promote Dignity",
  "End Hunger",
  "Reducing Inequalities",
  "Sharing Hope",
  "Save lives"
];

const nor = [
  "Utrydde fattigdom",
  "Fremme Verdighet",
  "Utrydde sult",
  "Bekjempe ulikhet",
  "Gi håp og framtid",
  "Redde liv"
];

const donateText = {
  eng: "Help provide vital aid to refugees in Meheba",
  nor: "Vær med å gi livsnødvendig hjelp til flyktninger i Meheba"
};

function Paralax() {
  return (
    <BackgroundSlider
      duration={4}
      transition={0.8}
      text={{ eng, nor }}
      donateText={donateText}
      images={[fattigdom, verdighet, sult, cgrandma, framtid, redde]}
    />
  );
}

export default Paralax;
