import React               from 'react'
import Navbar              from '../navbar'
import { LanguageContext } from '../../context/language'
import NOTFOUND            from '../404'
import img                 from './files/13.png'

function FrivilligSucess(props) {
    const context         = React.useContext(LanguageContext)
    const { lang }        = context
    const { frivillig }   = props.location
    return (
        <div>
            { frivillig !== undefined ? 
                <div>
                    <Navbar/>
                    <div className='webcontainer'>
                        <div className='row m-0 justify-content-center'>
                            <div className='pageDiv'>
                                { lang === 'eng' ? 
                                    <div>
                                        <h1 className='textUnderlineRed'>Thank you for choosing to be volunteer</h1>
                                        <p>
                                            We have received your application as volunteer in Brave Heart Foundation. A member of our staff will contact you shortly to agree on when you can start working and we can’t wait to welcome you among us.
                                            As volunteer we can contribute with as much as you can, only the sky is the limit. Even better for us if you can commit to work specific days or hours per week. It helps us plan well and evaluate our progress.
                                        </p>
                                        <p>If you have a question, do not hesitate to contact us at <a href="mailto:frivillig@bheart.no">frivillig@bheart.no</a> or call us at 405 41 180.</p>
                                        <img src={img} alt='Takk for din interesse' loading='lazy' className='wifs'/>
                                    </div> 
                                : 
                                    <div>
                                        <h1 className='textUnderlineRed'>Takk for din interesse</h1>
                                        <p>
                                            Vi har mottatt din interesse som frivillig. En av våre medarbeidere vil ta kontakt med deg om kort tid og avklare når du kan begynne. 
                                            Du kan bidra som frivillig så lite eller så mye som du vil. Har du tid til å forplikte deg på faste tidspunkt i uken, eller med et visst antall timer over tid, er det enklere for oss å finne oppgaver til deg - og du vil kanskje oppleve det som mer meningsfullt. 
                                        </p>
                                        <p>Om du har spørsmål, send oss gjerne en e-post på <a href="mailto:frivillig@bheat.no">frivillig@bheat.no</a> eller ring 40541180.</p>
                                        <img src={img} alt='Takk for din interesse' loading='lazy' className='wifs'/>
                                    </div> 
                                }                            
                            </div>
                        </div>
                    </div>
                </div>
            : <NOTFOUND/> }
        </div>
    )
}

export default FrivilligSucess