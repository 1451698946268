import React from 'react'
import { LanguageContext } from '../../../context/language'
import {sult} from '../constants/navigation'
import Navbar from '../../navbar'
import m2 from '../files/m2.png'

export default function Utryddesult() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/> {/* TODO: change background. */}
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>        
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? sult.eng : sult.nor}
                    <div>
                        {lang === 'eng' ? <h1>Eradicate hunger</h1> : <h1>Utrydde sult</h1>}
                        <img src={m2} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        {lang === 'eng' ? 
                            <p>
                                Poverty and hunger often go hand in hand, fortunately we have started work to do
                                the food supply in Meheba even better than it was. We have our own agricultural project in Meheba,
                                here we plant various wonderful and edible plants, and at the same time that this food
                                find the way to the inhabitants of Meheba, we also create jobs!  
                            </p>
                            : 
                            <p>
                                Fattigdom og sult går gjerne hånd i hånd, heldigvis har vi startet arbeider for å gjøre 
                                mattilførselen i Meheba enda bedre enn den var. Vi har vårt eget jordbruksprosjekt i Meheba, 
                                her planter vi diverse fantastiske og spiselige planter, og samtidig som at denne maten 
                                finner veien til innbyggerne i Meheba, skaper vi også arbeidsplasser!
                            </p>
                        }
                    </div>
                </div>
            </div>              
        </div>
    )
}