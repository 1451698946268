import React                              from 'react'
import {Stopped, Active, Pending, Error } from './status'
import                                    '../css/vipps.css'

export function AgreementDetails({status, price, productDescription}) {
    return (
        <div className='vp_sdiv'>
            { status === 'STOPPED' || status==='EXPIRED' ? <Stopped/> : null }
            { status === 'ACTIVE'  ? <Active price={price} productDescription={productDescription}/>  : null }
            { status === 'PENDING' ? <Pending/> : null }
            { status === 'ERROR'   ? <Error/> : null }
        </div>
    )
}