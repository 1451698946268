import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Image } from 'react-bootstrap'

import gorette from '../files/Gorette.png'

export default function PureHopeChildrensHomeConstant() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  const content = {
    nor: (
      <div>
        <h1>Pure Hope Children's Home</h1>
        <Image src={gorette} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
        <h3>Prosjektleder og supermamma: Gorette</h3>

        <p>
          Barnehjemmet vårt har ansvar for 21 barn. Barna kommer fra ulike situasjoner. Noen av barna er foreldreløse, mens andre kommer fra familier der foreldrene ikke er i stand til å ta vare på sine barn. Brave Heart Stiftelsen har i disse tilfellene tatt over forsørgeransvaret, men samarbeider og holder kontakt med den biologiske familien der det er mulig. For disse barna så betyr det at de bor hos oss, og de har da tilgang på seng og mat, de har et fellesskap, de blir tatt vare på og vist kjærlighet, og vi gir de muligheten til å få en fremtid. Våre barn får gå på skole og barn med funksjonshemminger får den nødvendige støtten de trenger for å leve et verdig liv.
        </p>

        <p>Dersom du ønsker å aktivt ta del i barnehjemmet kan du bli fadder for et barn ved å klikke <a href="/fadder">her</a>. </p>
      </div>
    ),
    eng: (
      <div>
        <h1>Pure Hope Children's Home</h1>
        <Image src={gorette} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
        <h3>Project manager and super mum: Gorette</h3>
        <p>
          Our orphanage is responsible for 21 children. The children come from different situations. Some of the children are orphans, while others come from families where the parents are unable to care for their children. In these cases, the Brave Heart Foundation has taken over the responsibility of providing care, but cooperates and maintains contact with the biological family where possible. For these children, it means that they live with us, and they then have access to a bed and food, they have a community, they are looked after and shown love, and we give them the opportunity to have a future. Our children get to go to school and children with disabilities get the necessary support they need to live a dignified life.
        </p>

        <p>If you want to actively take part in the orphanage, you can become a sponsor for a child by clicking <a href="/fadder">here</a>. </p>
      </div>
    )
  }

  return lang === 'eng' ? content.eng : content.nor
}
