import React                from 'react'
import Navbar               from '../navbar'
import { LanguageContext }  from '../../context/language'
import { main }             from './constants/navigation'
import Body                 from './constants/main'
import                      './css/engasjer.css'

function Engasjer() {
    const context           = React.useContext(LanguageContext)
    const {lang}            = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='engasjerDegBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Become apart of our team.' : 'Bli en del av teamet vårt.'}</p>
            </div>
            <div className='justify-content-center m-0 row'>
                <div className='pageDiv'>
                    {lang === 'eng' ? main.eng : main.nor}
                    <Body/>
                </div>
            </div>
        </div>
    )
}

export default Engasjer
