import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Drawer, Divider } from 'antd'
import { CgClose } from 'react-icons/cg'
import SocialMedia from '../../socialmedia'

export function MobileNav(props) {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng:
            <Drawer placement="right" onClose={props.onCloseMobileDrawer} visible={props.visible} width='100%' closable={false} bodyStyle={bodyStyle}>
                <div className='row justify-content-center m-0'>
                    <div className='row closeButtonRow justify-content-end'>
                        <CgClose id='closeButton' className='text-white' onClick={props.onCloseMobileDrawer} />
                    </div>
                </div>
                <div className='row m-0 justify-content-center'>
                    <div className='mobileContent'>
                        <Divider />
                        <a href='/omOss'><h2 className='text-white'>About Us</h2></a>
                        <a href='/stottOss'><h2 className='text-white'>Support Us</h2></a>
                        <a href='/arbeid'><h2 className='text-white'>Our Work</h2></a>
                        <a href='/engasjer'><h2 className='text-white'>Get Involved</h2></a>
                        <a href='/partner'><h2 className='text-white'>Become A Partner</h2></a>
                        <SocialMedia />
                    </div>
                </div>
            </Drawer>,
        no:
            <Drawer placement="right" onClose={props.onCloseMobileDrawer} visible={props.visible} width='100%' closable={false} bodyStyle={bodyStyle}>
                <div className='row justify-content-center m-0'>
                    <div className='row closeButtonRow justify-content-end'>
                        <CgClose id='closeButton' className='text-white' onClick={props.onCloseMobileDrawer} />
                    </div>
                </div>
                <div className='row m-0 justify-content-center'>
                    <div className='mobileContent'>
                        <Divider />
                        <a href='/omOss'><h2 className='text-white'>Om Oss</h2></a>
                        <a href='/stottOss'><h2 className='text-white'>Støtt Arbeidet</h2></a>
                        <a href='/arbeid'><h2 className='text-white'>Vårt Arbeid</h2></a>
                        <a href='/engasjer'><h2 className='text-white'>Engasjer Deg</h2></a>
                        <a href='/partner'><h2 className='text-white'>Bli Partner</h2></a>
                        <SocialMedia />
                    </div>
                </div>
            </Drawer>
    }
    return lang === 'nor' ? content.no : content.eng
}

// mobile menu styles
const bodyStyle = {
    padding: '1rem',
    backgroundColor: '#009EE0'
}