import { useContext } from "react";
import { LanguageContext } from "../../../context/language";

export const DonateFrame = () => {
  const context = useContext(LanguageContext)
  const { lang } = context;

  const donateText = {
    eng: "Help provide vital aid to refugees in Meheba",
    nor: "Vær med å gi livsnødvendig hjelp til flyktninger i Meheba"
  };


  return (
    <div className="donateFrame">
      <h4>{donateText[lang]}</h4>
      <iframe
        title="donate"
        src="https://nettbutikk.solidus.no/hurtigkasse/brave_heart_stiftelsen/vipps_hurtigkasse"
      />
    </div>
  );
}