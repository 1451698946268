import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'

export const barnhjem = {
    eng: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />children home</span>
        </div>
    ),
    nor: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />barnehjem</span>
        </div>
    )
}

export const helse = {
    eng: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />health</span>
        </div>
    ),
    nor: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />helse</span>
        </div>
    )
}

export const utdanning = {
    eng: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />education</span>
        </div>
    ),
    nor: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />utdanning</span>
        </div>
    )
}

export const jobbskapnning = {
    eng: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />jobs</span>
        </div>
    ),
    nor: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />jobbskaping</span>
        </div>
    )
}

export const misjon = {
    eng: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />mission</span>
        </div>
    ),
    nor: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />misjon</span>
        </div>
    )
}


export const safehome = {
    eng: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />safe home</span>
        </div>
    ),
    nor: (
        <div className='ant-row mt-4'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />trygt hjem for jenter</span>
        </div>
    )
}


export const main = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <span className='router'><IoIosArrowBack />our work</span>
        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <span className='router'><IoIosArrowBack />vårt arbeid</span>
        </div>
    )
}

export const hopeclinic = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />Hope clinic</span>

        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />Hope clinic</span>
        </div>
    )
}

export const road44 = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />Road 44</span>

        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />Road 44</span>
        </div>
    )
}

export const peace = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />Peace Project</span>

        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />Peace Prosjekt</span>
        </div>
    )
}

export const jenteinternat = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />Girls boarding school</span>

        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />Jenteinternat</span>
        </div>
    )
}

export const pureHome = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />Pure Hope Children's Home</span>

        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />Pure Hope Children's Home</span>
        </div>
    )
}

export const idrett = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />Support for sports</span>

        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />Støtte til idrett</span>
        </div>
    )
}

export const menigheter = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />Cooperation with local congregations</span>

        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />Samarbeid med lokal menigheter</span>
        </div>
    )
}

export const ngo = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />our work</a>
            <span className='router'><IoIosArrowBack />Cooperation with local authorities and other NGOs</span>

        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/arbeid' className='router notActive'><IoIosArrowBack />vårt arbeid</a>
            <span className='router'><IoIosArrowBack />Samarbeid med lokale myndigheter og andre ngo</span>
        </div>
    )
}