import React from "react";

import { LanguageContext } from "../../../context/language";

import { Row } from "react-bootstrap";
import { Newsletter } from "./newsletter";

import Norme from "../../../media/logos/Norme.png";
import BhWhite from "../../../media/logos/bheartWhite.svg";
import Inslg from "../../home/files/innsamlingskontrollenhvit.png";

export function About() {
  const context = React.useContext(LanguageContext);
  const { lang } = context;

  const about = {
    nor: (
      <div className="row m-0 justify-content-between fa-c">
        <div className="mb-5 ft_about_bd">
          <div className="mb-4">
            <a href="/">
              <img src={BhWhite} alt="bheart" width="180px" />
            </a>
          </div>
          <div className="ft_about w-100">
            <p>Org. nr: 997094867</p>
            <p>Post: Saturnvegen 1, 6419 Molde </p>
            <p>Tlf: +47 920 64 803</p>
            <p>E-post: post@brave-heart.no</p>
          </div>
          <Row className="m-0 footerLogos">
            <img src={Norme} alt="norme" height="50px" />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/"
            >
              <img src={Inslg} alt="innsamlingskontrollen" width="50px" />
            </a>
          </Row>
        </div>
        <Newsletter />
      </div>
    ),
    eng: (
      <div className="row m-0 justify-content-between fa-c">
        <div className="mb-5 ft_about_bd">
          <div className="mb-4">
            <a href="/">
              <img src={BhWhite} alt="bheart" width="180px" />
            </a>
          </div>
          <div className="ft_about">
            <p>Org. nr: 997094867</p>
            <p>Post: Saturnvegen 1, 6419 Molde </p>
            <p>Phone: +47 920 64 803</p>
            <p>Email: post@brave-heart.no</p>
          </div>
          <Row className="m-0 footerLogos">
            <img src={Norme} alt="norme" height="50px" />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/"
            >
              <img src={Inslg} alt="innsamlingskontrollen" width="50px" />
            </a>
          </Row>
        </div>
        <Newsletter />
      </div>
    )
  };
  return lang === "eng" ? about.eng : about.nor;
}
