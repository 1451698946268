import React from "react";
import { Image, Row } from "react-bootstrap";

import { LanguageContext } from "../../../context/language";

import john from "../files/John.jpg";
import heine from "../files/heine.png";
import svenung from "../files/Svenung.jpg";
import arild from "../files/ArildVikan.png";

export function Employee() {
  const context = React.useContext(LanguageContext);
  const { lang } = context;

  const content = {
    eng: (
      <div>
        <h1 className="textUnderlineRed">The Board</h1>
        <Row className="styreDiv m-0">
          <Row className="justify-content-center align-items-center">
            <div className="styrePerson">
              <Row className="align-items-center m-0 justify-content-center">
                <Image
                  src={svenung}
                  alt="styrePerson"
                  roundedCircle
                  loading="lazy"
                />
                <div className="staff_about">
                  <h3>Sveinung Henden</h3>
                  <p>
                    Sveinung Henden is Chairperson and the in Charge of the
                    economy in Brave Heart Foundation. He formerly worked as
                    anesthesiologist nurse. Today he is Chief Executive Officer
                    of several companies, working with investment and
                    properties. Since 2013, Sveinung has served Brave Heart
                    Foundation on voluntary basis, and he is passionate about
                    helping people in need. He is dedicated to building up
                    things, to create growth and sustainable development.
                  </p>
                </div>
              </Row>
            </div>
            <div className="styrePerson">
              <Row className="align-items-center m-0 justify-content-center">
                <Image
                  src={john}
                  alt="styrePerson"
                  roundedCircle
                  loading="lazy"
                />
                <div className="staff_about">
                  <h3>John Steinar Brækken</h3>
                  <p>
                    Managing director of the foundation since July 2023, and
                    currently studying Bachelor in Religion and Society in Oslo.
                    John Steinar had his first visit to the refugee camp in
                    2022, which resulted in a heart burning to help the people
                    living in Meheba refugee camp in Zambia.
                  </p>
                </div>
              </Row>
            </div>
            <div className="styrePerson">
              <Row className="align-items-center m-0 justify-content-center">
                <Image
                  src={arild}
                  alt="styrePerson"
                  roundedCircle
                  loading="lazy"
                />
                <div className="staff_about">
                  <h3>Arild Vikan</h3>
                  <p>
                    Board member of the Brave Heart Foundation since June 2023,
                    and daily project manager at Salmon Evolution AS. In 2023,
                    Arild experienced how Brave Heart changed lives and ignited
                    hope in Meheba. During the trip, he saw dedicated and local
                    staff who used their own experiences of need and poverty to
                    help thousands of people with modest means. Arild also
                    wanted to get involved in this.
                  </p>
                </div>
              </Row>
            </div>
            <div className="styrePerson">
              <Row className="align-items-center m-0 justify-content-center">
                <Image
                  src={heine}
                  alt="styrePerson"
                  roundedCircle
                  loading="lazy"
                />
                <div className="staff_about">
                  <h3>Heine G. Bakke</h3>
                  <p>
                    Board member of the Brave Heart Foundation since June 2023,
                    and is now retired. With previous experience from consulting
                    in finance and business development, he now wants to help
                    Brave Heart's work so that food and dignity can be given to
                    as many people as possible in Meheba refugee camp.
                  </p>
                </div>
              </Row>
            </div>
          </Row>
        </Row>
      </div>
    ),
    nor: (
      <div>
        <h1 className="textUnderlineRed">Styret</h1>
        <Row className="styreDiv">
          <Row className="justify-content-center m-0 align-items-center">
            <div className="styrePerson">
              <Row className="align-items-center m-0 justify-content-center">
                <Image
                  src={svenung}
                  alt="styrePerson"
                  roundedCircle
                  loading="lazy"
                />
                <div className="staff_about">
                  <h3>Sveinung Henden</h3>
                  <p>
                    Styreleder og økonomiansvarlig i Brave Heart Stiftelsen.
                    Tidligere anestesisykepleier. I dag daglig leder han flere
                    firma som driver med eiendomsutvikling og investeringer.
                    Engasjert i Brave Heart Stiftelsen som frivillig siden 2013
                    og brenner for å hjelpe folk i nød. Sveinung liker å bygge
                    ting, skape vekst og utvikling.
                  </p>
                </div>
              </Row>
            </div>
            <div className="styrePerson">
              <Row className="align-items-center m-0 justify-content-center">
                <Image
                  src={john}
                  alt="styrePerson"
                  roundedCircle
                  loading="lazy"
                />
                <div className="staff_about">
                  <h3>John Steinar Brækken</h3>
                  <p>
                    Daglig leder i stiftelsen siden juli 2023, og studerer i dag
                    Bachelor i Religion og Samfunn i Oslo. John Steinar fikk
                    sitt første besøk i flyktningleiren i 2022, som resulterte i
                    et hjerte som brenner for å hjelpe de menneskene som bor i
                    Meheba flyktningleir i Zambia.
                  </p>
                </div>
              </Row>
            </div>
            <div className="styrePerson">
              <Row className="align-items-center m-0 justify-content-center">
                <Image src={arild} alt="styrePerson" loading="lazy" />
                <div className="staff_about">
                  <h3>Arild Vikan</h3>
                  <p>
                    Styremedlem i Brave Heart Stiftelsen siden juni 2023, og til
                    daglig prosjektleder i Salmon Evolution AS. I 2023 fikk
                    Arild erfare hvordan Brave Heart forandret liv og tente håp
                    i Meheba. Under turen så han dedikerte og lokale ansatte som
                    brukte egne erfaringer fra nød og fattigdom til å hjelpe
                    tusenvis av mennesker med beskjedne midler. Dette ønsket
                    Arild også å engasjere seg i.
                  </p>
                </div>
              </Row>
            </div>
            <div className="styrePerson">
              <Row className="align-items-center m-0 justify-content-center">
                <Image src={heine} alt="styrePerson" loading="lazy" />
                <div className="staff_about">
                  <h3>Heine G. Bakke</h3>
                  <p>
                    Styremedlem i Brave Heart Stiftelsen siden juni 2023, og er
                    nå pensjonert. Med tidligere erfaring fra rådgivning innen
                    økonomi og bedriftsutvikling, ønsker han nå å hjelpe Brave
                    Hearts arbeid slik at mat og verdighet kan gis til flest
                    mulig mennesker i Meheba flyktningleir.
                  </p>
                </div>
              </Row>
            </div>
          </Row>
        </Row>
      </div>
    )
  };
  return lang === "eng" ? content.eng : content.nor;
}
