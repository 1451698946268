import React from 'react'
import { BsFillHeartFill } from 'react-icons/bs'
import { Formaal } from './formaal'

function Donate() {
    return (
        <div>
            <BsFillHeartFill className='heartIcon' />
            <div className='row justify-content-center m-0'>
                <div className='row formContainer justify-content-center m-3'>
                    <Formaal />
                </div>
            </div>
        </div>
    )
}

export default Donate
