import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Row } from 'react-bootstrap'

// related images
import sgigave from '../files/1.jpeg'
import sFundraiser from '../files/2.jpeg'
import sFrivilligZambia from '../files/3.jpg'
import sFrivilligNorge from '../files/4.jpg'
import sSpreBud from '../files/5.jpg'
import sSkoleFadder from '../files/6.jpg'
import sUniversiteter from '../files/7.jpg'
import sMissjonsReiser from '../files/8.jpg'
import sMenigheter from '../files/9.jpeg'
import sAdvocacy from  '../files/10.jpg'

export default function Main() {
    const context = React.useContext(LanguageContext)
    const { lang } = context
    const content = {
        eng: (
            <div>
                <h1>Get Involved</h1>
                <p>
                    There are many ways to get involved, and hosting a contribution means something!
                    Find what works best for you, and join in making a difference! 
                </p>
                <Row className='støttMain m-0'>
                    <Row className='støttDiv'>
                        <a href='/frivillig'>
                            <img src={sFrivilligNorge} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Volunteer in Norway </h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/frivillig'>
                            <img src={sFrivilligZambia} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Volunteer in Zambia</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sSpreBud} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Spread the word</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sSkoleFadder} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>School Sponsors</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sUniversiteter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Colleges And University</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sMissjonsReiser} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Mission trips</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sMenigheter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Menigheter</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sUniversiteter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Study travel</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/gigave'>
                            <img src={sgigave} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Give a gift</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/innsamling'>
                            <img src={sFundraiser} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Fundraisers</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/prosjekt'>
                            <img src={sAdvocacy} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Advocacy</h5>
                        </a>
                    </Row>
                </Row>
            </div>
        ),
        nor: (
            <div>
                <h1>Engasjer deg</h1>
                <p>
                    Det er mange måter å engasjere seg på, og vert bidrag betyr noe! 
                    Finn det som passer best til deg, og bli med på å gjøre en forskjell!
                </p>
                <Row className='støttMain m-0'>
                    <Row className='støttDiv'>
                        <a href='/frivillig'>
                            <img src={sFrivilligNorge} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Bli frivillig i Norge</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/frivillig'>
                            <img src={sFrivilligZambia} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Bli frivillig i Zambia</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sSpreBud} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Spre budskap</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sSkoleFadder} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Skole Fadder</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sUniversiteter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Hogskoler Og Universitet</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sMissjonsReiser} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Misjons Reiser</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sMenigheter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Menigheter</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sUniversiteter} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Studie Reiser</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/gigave'>
                            <img src={sgigave} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Gi en gave</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/innsamling'>
                            <img src={sFundraiser} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Fundraiser</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sAdvocacy} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='eager'/>  
                            <h5 className='stottText text-white'>Advocacy</h5>
                        </a>
                    </Row>
                </Row>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
