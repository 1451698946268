import React from 'react'
import { LanguageContext } from '../../context/language'
import Navbar from '../navbar'

export default function Comingsoon() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    return (
        <div>
            <Navbar/>
            <div className='webcontainer'>
                <div className='row justify-content-center m-0'>
                    {lang === 'eng' ? 
                        <div className='cm_div shadow'>
                            <h1>Page coming soon!</h1>
                            <p>We are currently working on this content and it will be available as soon as possible. Sorry for the inconvenience.</p>
                        </div> :
                        <div className='cm_div shadow'>
                            <h1>Kommer snart!</h1>
                            <p>Vi jobber for tiden med dette innholdet, og det vil være tilgjengelig så snart som mulig. Beklager bryet.</p>
                        </div>
                    }
                </div>
            </div>          
        </div>
    )
}
