import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Card, Accordion, Button } from 'react-bootstrap'

export function Accordions() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng: (
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="h4" eventKey="0">
                            Our Message
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            Our message is simple, yet powerful: “Let us remind you of who you really are.
                            You are valuable! No inhuman treatment you may have experienced can ever take away
                            your value, scratch on to your dignity or rob you from the special potential you carry
                            on your inside. You are created in the image of God himself, and you are very precious!
                            You are not going through this time alone. You are not alone and not forgotten. Not by
                            God, not by people around the world and not by us!” We are together!
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="h4" eventKey="1">
                            The Purpose of Brave Heart
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <ol>
                                <li>Strengthen, Serve and Support refugees, host communities, and other people in need.</li>
                                <li>Raise up from ruins of war, violence and persecution the forcibly displaced so that they may build their future, thrive and help others in the new communities they now live in.</li>
                                <li>Make people brave and equip them to love God and serve the people in need.</li>
                                <li>Support development, humanitarian aid and positive change for individuals and communities.</li>
                                <li>Promote peace, tolerance and fellowship between people, groups, cultures and religions.</li>
                                <li>Reach out to all people with the unconditional love of God.</li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="h4" eventKey="2">
                            The Values of Brave Heart
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <p>
                                Brave Heart has ZERO TOLERANCE for all forms of CORRUPTION, GENDER BASED VIOLENCE & DISCRIMINATION.
                                The Organization is founded on Christian values and upholds all values and principles of the
                                UN’s Human Rights Charters, and serves all people regardless of faith, ethnicity, nationality or
                                language. Brave Heart adheres to the principles of HUMANITY, IMPARTIALITY, NEUTRALITY & INDEPENDENCE
                                in all our programs, operations, partnerships and endeavors.
                            </p>
                            <p>Our core values are summarized in the following:</p>
                            <ol>
                                <li>
                                    <strong>Dignity:</strong> we remind people that no circumstance can take away their value or dignity. No torture, inhuman treatment, abuse, persecution, or loss can define who they are nor take away their worth.
                                </li>
                                <li><strong>Kindness:</strong> we exist to share extreme kindness to those who have experienced extreme evil. Love and kindness heals! </li>
                                <li><strong>Accountability:</strong> We are faithful stewards to our donors, partners and the people we serve. We strive to maintain Transparency & Integrity in our affairs. Read about our numbers here: <a target='_blank' href="https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/" rel="noreferrer">https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/</a></li>
                                <li><strong>Servant - leadership:</strong> We lead through servanthood, and by serving people in need and one another.</li>
                                <li><strong>Communication: </strong> We speak encouraging, positive, constructive words that carry life to one another while maintaining our position as a Strong Advocating Voice for those who cannot speak for themselves. </li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="h4" eventKey="3">
                            Programs and Operations
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <p>
                                Brave Heart Foundation’s Programs and Operations are aligned with the United Nations’
                                Sustainable Development Goals.  We currently work in Zambia and Norway to achieve the following goals:
                            </p>
                            <ol>
                                <li>Poverty Eradication (UNs SDG #1)</li>
                                <li>Ending Hunger & proving food security (UNs SDG #2)</li>
                                <li>Free Health Care (UNs SDG #3)</li>
                                <li>Inclusive Quality Education (UNs SDG #4)</li>
                                <li>Fight Gender Based Violence & Promote Gender Equality (UNs SGD #5)</li>
                                <li>Provide Clean Water & Sanitation for Meheba Refugee Community (UNs SDG #6) </li>
                                <li>Promote Job creation and economic growth (UNs SDG #8)</li>
                                <li>Eradicate inequality (UNs SDG #10)</li>
                                <li>Promote a responsible and balanced production and consumption (UNs SDG #12)</li>
                                <li>Taking care of the environment and act on Climate Change (UNs SDG #13)</li>
                                <li>Promote Justice & Peace Building (UNs SDG #16) </li>
                                <li>Partnering with local & international agencies to achieve these goals (UNs SDG #17)</li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        ),
        nor: (
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="h4" eventKey="0">
                            Vårt Budskap
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            Budskapet vårt er enkel, men også kraftig: «La oss minne deg om hvem du egentlig er. Du er verdifull! Ingen umenneskelig behandling du kan ha opplevd kan noen gang frata deg din verdi, skitne til din verdighet eller frarøve deg det spesielle potensialet du bærer på innsiden. Du er skapt i Guds bilde, og du er veldig dyrebar! Du går ikke gjennom denne tiden alene. Du er ikke alene og ikke glemt. Ikke av Gud, ikke av mennesker rundt om i verden, og ikke av oss! ” Vi står sammen.
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="h4" eventKey="1">
                            Formål
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <ol>
                                <li>Styrke, tjene og støtte flyktninger, vertssamfunn og andre mennesker i nød.</li>
                                <li>Reise opp de som føler seg knust, slik at de blir gjenopprettet, blomstrer og kan styrke andre.</li>
                                <li>Gjøre mennesker modige og utruste dem til å elske Gud og tjene mennesker i nød.</li>
                                <li>Støtte utvikling, bistand og positiv endring for personer og samfunn.</li>
                                <li>Fremme fred, toleranse og fellesskap mellom mennesker og grupper.</li>
                                <li>Nå de unådde med Guds kjærlighet.</li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="h4" eventKey="2">
                            Verdier av Brave Heart Stiftelsen
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <p>
                                Brave Heart har NULLTOLERANSE for alle former for korrupsjon, kjønnsbasert vold og diskriminering.
                                Organisasjonen er basert på kristne verdier og opprettholder alle verdier og prinsipper i FNs
                                menneskerettighetserklæring. Vi betjener alle mennesker uavhengig av tro, etnisitet, nasjonalitet eller
                                språk. Brave Heart Stiftelsen jobber etter de humanitære prinsippene UAVHENGIGHET, NØYTRALITET, UPARTISKHET og HUMANITET.
                            </p>
                            <p>Våre kjerneverdier kan oppsummeres i dette:</p>
                            <ol>
                                <li>
                                    <strong>Verdighet:</strong> Vi minner folk om at ingen omstendighet kan ta bort verdien eller skitne til deres verdighet. Ingen tortur, umenneskelig behandling, overgrep, forfølgelse, eller tap kan definere hvem de er og heller ikke ta bort deres verdi.
                                </li>
                                <li>
                                    <strong>Godhet:</strong> Vi eksisterer for å fremme ekstrem godhet mot de som har opplevd ekstrem ondskap. Godhet og kjærlighet har helbredende kraft!
                                </li>
                                <li>
                                    <strong>Åpenhet & tilregnelighet:</strong> Vi er trofast forvalter overfor givere, partnere og menneskene vi tjener. Vi streber etter å opprettholde åpenhet og integritet i våre saker. Les om våre tall her: <a href="https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/" target="_blank" rel="noopener noreferrer">https://www.innsamlingskontrollen.no/organisasjoner/brave-heart-stiftelsen/</a>
                                </li>
                                <li><strong>Tjenende lederskap:</strong> Vi leder gjennom å tjene mennesker i nød og å tjene hverandre.</li>
                                <li><strong>Kommunikasjon: </strong> Vi snakker oppmuntrende, positive, og konstruktive ord som gir liv til hverandre mens vi opprettholder vår posisjon som en sterk talsmann for dem som ikke kan snakke for seg selv.</li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="h4" eventKey="3">
                            Programmer og Operasjoner
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <ol>
                                <li>Utrydde fattigdom (FNs SDG #1)</li>
                                <li>Utrydde sult (FNs SDG #2)</li>
                                <li>Gi god helse og livskvalitet (FNs SDG #3)</li>
                                <li>Tilbud skolegang og kvalitet inkluderende utdanning mulighet for alle. (FNs SDG #4)</li>
                                <li>Fremme likestilling mellom kjønnene. (FNs SDG #5)</li>
                                <li>Tilbud rent vann og gode sanitærforhold. (FNs SDG #6)</li>
                                <li>Anstendig arbeid og økonomisk vekst. (FNs SDG #8)</li>
                                <li>Mindre ulikhet. (FNs SDG #10)</li>
                                <li>Ansvarlig forbruk og produksjon. (FNs SDG #12)</li>
                                <li>Stoppe klimaendringer. (FNs SDG #13)</li>
                                <li>Fred, rettferdighet og velfungerende institusjoner. (FNs SDG #16)</li>
                                <li>Samarbeid for å nå målene. (FNs SDG #17)</li>
                            </ol>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}