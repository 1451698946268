// REACT FRAMEWORK
import React from "react";

// THIRD PARTY COMPONENTS
import { Jumbotron, Row } from "react-bootstrap";

// REACT ICONS
import { IoIosArrowBack } from "react-icons/io";

// CUSTOM COMPONENTS
import Navbar from "../navbar";

export default function Personvern() {
  return (
    <div>
      <div className="psnv_Background">
        <Navbar />
      </div>
      <Jumbotron className="bkm_jumbo">
        <Row className="justify-content-center align-items-center">
          <div className="akt_div">
            <div className="ant-row">
              <a href="/" className="router notActive">
                Forsiden
              </a>
              <span className="router">
                <IoIosArrowBack /> Personvernerklæring
              </span>
            </div>
            <h1>Brave Heart Stiftelsen Personvern</h1>
            <h5 className="mb-4 mt-3">
              I Brave Heart Stiftelsen tar vi personvern og sikkerhet på alvor.
              Samtidig ønsker vi å gi deg en best mulig brukeropplevelse når du
              besøker nettsidene våre.
            </h5>
            Vi behandler personopplysninger i tråd med EUs personvernforordning
            (GDPR). Vi lagrer informasjon om hvordan du bruker tjenestene våre
            når det er nødvendig for at vi skal kunne gi deg best mulig
            betjening, som for eksempel når du utfører bestillinger, gir en
            gave, vi sender deg en leveranse, takkebrev og ved annen eventuell
            kontakt med deg. Vi bruker også disse opplysningene til å generere
            statistikk og forstå trender, i tillegg til å forbedre, tilby og
            videreutvikle våre prosjekter og tjenester. Som registrert i våre
            systemer har du krav til å få innsyn i dine personopplysninger og
            kreve, innenfor lovens rammer, at disse opplysningene blir rettet
            opp eller slettet dersom de er mangelfulle eller uriktige.
            <h5 className="mt-4">1. INNSAMLING AV PERSONLIGE OPPLYSNINGER</h5>
            Brave Heart Stiftelsen er behandlingsansvarlig for innsamling og
            bruk av personopplysninger, og behandler personopplysninger i tråd
            med personopplysningsloven. Med behandlingsansvar mener vi den som
            primært har ansvar og plikter etter personopplysningsloven.
            <h5 className="mt-4">
              2. HVA BRUKER BRAVE HEART PERSONOPPLYSNINGENE TIL?
            </h5>
            Brave Heart Stiftelsen samler inn personopplysninger, etter ditt
            samtykke, når du gir oss pengestøtte, melder deg på nyhetsbrev,
            registrerer deg som frivillig, når du melder deg på
            fundraisingsevent, når vi følger opp og har dialog med deg som giver
            eller støttespiller og når vi sender ut markedsføringsprodukter og
            annen informasjon om Brave Heart Stiftelsen. E-postadressen din
            og/eller mobilnummeret ditt samles inn slik at vi kan sende deg en
            bekreftelse på at bidraget ditt er mottatt – og for å informere deg
            om arbeidet vi gjør for pengene vi samler inn. Dersom du er fast
            giver i Brave Heart Stiftelsen, kan vi ved hjelp av
            personopplysninger forbedre brukeropplevelsen din på våre nettsider
            og gjøre markedsføringen vi sender ut mer relevant for deg. Brave
            Heart Stiftelsen behandler dine personlige opplysninger for
            markedsføringsformål etter gjeldende lover, inkludert lover om
            innhenting av samtykke for elektronisk markedsføring.
            <h5 className="mt-4">3. BEHANDLING AV PERSONLIGE OPPLYSNINGER</h5>
            <p>
              • Du vil bli varslet før vi samler inn opplysninger om deg, og du
              kan selv velge hva slags informasjon vi kan beholde og til hvilke
              formål.{" "}
            </p>
            <p>
              • Personlige opplysninger kan være navnet ditt, adresse,
              telefonnummer, e-postadresse, fødselsdato, giverinformasjon og
              betalingsdetaljer.{" "}
            </p>
            <p>
              • Dersom du støtter oss med et beløp pålydende mellom 500 og
              50.000 kroner, kan du ved å opplyse oss om personnummeret ditt få
              skattefradrag av giverbeløpet. Brave Heart Stiftelsen er lovpålagt
              å innhente personnummer ved skattefradrag og benytter personnummer
              for å sikre at støttespillere og givere får skattefradrag for sine
              gaver.{" "}
            </p>
            <p>
              • Vi utleverer ikke dine personopplysninger til andre, med unntak
              av lovpålagt innrapportering av skattefradrag til
              Skattedirektoratet.{" "}
            </p>
            <p>
              • Opplysningene lagres i vår giverdatabase Solidus levert av
              selskapet SOLIDUS AS. Vi sletter unødvendige opplysninger når vi
              ikke lenger trenger å oppbevare dem.
            </p>
            <p>
              • Brave Heart Stiftelsen er underlagt taushetsplikt og selger ikke
              personopplysninger til tredjepart. Vi behandler opplysninger om
              deg strengt konfidensielt.
            </p>
            <h5 className="mt-4">
              4. REGISTRERING, ENDRING AV INFORMASJON, AVREGISTRERING ETC.
            </h5>
            <p>
              • Det er frivillig å gi fra seg personopplysninger. Du kan få
              innsyn i hvilke opplysninger som er registrert om deg ved å sende
              en mail til post@bheart.no.
            </p>
            <p className="terms">
              • Personopplysninger innhentes ved registrering som fast giver,
              ved engangsgave til Brave Heart Stiftelsen eller ved påmelding til
              nyhetsbrev, informasjons -innsamlingsevent eller ved å melde deg
              inn som frivillig. Dette kan foregå via våre nettsteder, sms,
              vipps, e-post innsamlingsaksjoner på Facebook/Instagram, spleis
              eller via postal giro. Du har rett til å motta informasjon om
              innhentingen, bruken, behandlingen og beskyttelsen av dine
              personlige opplysninger. Dersom du ønsker at vi skal oppdatere,
              endre eller slette personlige opplysninger som vi har registrert
              om deg, eller hvis du ikke ønsker å motta flere meldinger fra oss,
              eller eventuelt har spørsmål angående de ovennevnte
              retningslinjer, kan du ta kontakt med oss på{" "}
              <a href="mailto:post@bheart.no">post@bheart.no</a>.
            </p>
            <h5 className="mt-4">5. SIKKERHET</h5>
            Brave Heart Stiftelsen har iverksatt en rekke sikkerhetstiltak for å
            sikre at våre interne rutiner samsvarer med sikkerhetsstandardene
            som kreves i henhold til gjeldende personvernlovgivning. Vi jobber
            alltid med å beskytte dine personlige opplysninger på best mulig
            måte.
            <h5 className="mt-3">Google Analytics</h5>
            Brave Heart Stiftelsen benytter Google Analytics til å måle og
            analysere besøksstatistikk på nettsiden vår. Informasjonen bruker vi
            for å videreutvikle innhold, funksjonalitet og forbedre
            brukeropplevelsen. Det samles ikke inn opplysninger som
            identifiserer deg som bruker.
            <h5 className="mt-3">Facebook</h5>
            Gjennom Facebooks annonseplattform og tilhørende informasjonskapsel,
            innhentes data om hvilke sider besøkende har sett på vårt nettsted,
            registrering av faste givere og enkeltgaver, hvordan brukere
            responderer på innlegg, sponset innhold og annonsering fra Brave
            Heart Stiftelsen på Facebook. Dette vil for eksempel være
            informasjon om klikk på en annonse, visning av en video samt om man
            eventuelt blir giver som resultat av en Facebook-annonse. Dataen
            benyttes til å tilpasse og målrette relevant annonsering.
            <h5 className="mt-4">6. SLETTING</h5>
            <p className="terms">
              Brave Heart Stiftelsen vil behandle og lagre dine personlige
              opplysninger så lenge det er nødvendig og tillatt under gjeldende
              personvernlovgivning. Hvis du ønsker mer informasjon, kan du ta
              kontakt med oss på{" "}
              <a href="mailto:post@bheart.no">post@bheart.no</a>.{" "}
            </p>
            <h5 className="mt-4">7. TVISTER</h5>
            Norsk lovgivning regulerer eventuelle omstendigheter eller tvister
            som kan oppstå i forbindelse med bruk av nettstedet.
            <h5 className="mt-4">8. PERSONVERNOMBUD OG KONTAKTINFORMASJON</h5>
            <p className="terms">
              Personvernombud for administrativ behandling av personopplysninger
              i Brave Heart Stiftelsen er Sveinung Henden – Mobilnr. 92064803
              eller mail: <a href="mailto:post@bheart.no">post@bheart.no</a>.
              Personvernombudet skal bistå den som er ansvarlig for
              virksomhetens behandling av personopplysninger
              (behandlingsansvarlige) i arbeidet med å ivareta personvernet til
              alle frivillige, ansatte og givere. Kontakt Brave Heart Stiftelsen
              giverservice på: Mobile: 40541180 E-post:{" "}
              <a href="mailto:post@bheart.no">post@bheart.no</a>.
            </p>
          </div>
        </Row>
      </Jumbotron>
    </div>
  );
}
