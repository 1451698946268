import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Button } from 'react-bootstrap'
import {
    FiFacebook,
    FiInstagram, FiTwitter,
} from 'react-icons/fi'

import { RiVimeoLine } from 'react-icons/ri'

export function Newsletter() {
    const context = React.useContext(LanguageContext)
    const { lang } = context
    const content = {
        eng: (
            <div className='footerNewsletter'>
                <h3 className='text-white footerHeader textUnderline pb-1 mb-4'>Subscribe to our newsletter</h3>
                <a style={{ width: '100%', height: 'fit-content' }} target='_blank' href="https://brave-heart.us21.list-manage.com/subscribe?u=4976ab9db19cc38a9ba358292&id=8331817662" rel="noreferrer">
                    <Button className='oButtons w-100' variant='outline-primary'>Subscribe</Button>
                </a>
                <div id='footerSocialMedia'>
                    <a href='https://twitter.com/bhstiftelsen'><span><FiTwitter /></span></a>
                    <a href='https://www.facebook.com/bheart.no'><span><FiFacebook /></span></a>
                    <a href='https://www.instagram.com/bheart.no/?igshid=1198kthuqt2h8'><span><FiInstagram /></span></a>
                    <a href='https://vimeo.com/342356502'><span><RiVimeoLine /></span></a>
                </div>
            </div>
        ),
        nor: (
            <div className='footerNewsletter'>
                <h3 className='text-white footerHeader textUnderline pb-1 mb-4'>Abonner på vårt nyhetsbrev</h3>
                <a style={{ width: '100%', height: 'fit-content' }} target='_blank' href="https://brave-heart.us21.list-manage.com/subscribe?u=4976ab9db19cc38a9ba358292&id=8331817662" rel="noreferrer">
                    <Button className='oButtons w-100' variant='outline-primary'>Abonner</Button>
                </a>
                <div id='footerSocialMedia'>
                    <a href='https://twitter.com/bhstiftelsen'><span><FiTwitter /></span></a>
                    <a href='https://www.facebook.com/bheart.no'><span><FiFacebook /></span></a>
                    <a href='https://www.instagram.com/bheart.no/?igshid=1198kthuqt2h8'><span><FiInstagram /></span></a>
                    <a href='https://vimeo.com/342356502'><span><RiVimeoLine /></span></a>
                </div>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}