import React from 'react'
import { LanguageContext } from '../../../context/language'
import {samarbeid} from '../constants/navigation'
import Navbar from '../../navbar'
import m17 from '../files/m17.png'


export default function Samarbeid() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div>            
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? samarbeid.eng : samarbeid.nor}
                    <div>
                        {lang === 'eng' ? <h1>The UN's sustainability goals are binding on all countries </h1> : <h1>FNs bærekraftsmål forplikter alle land</h1>}
                        <img src={m17} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        {lang === 'eng' ? 
                            <p>
                                The latest sustainability goal tells us something very important. Both foundations like us, 
                                organizations and private companies must be involved in the work. We can not do this alone, 
                                so we must work together - and individually, to achieve these goals.
                                We do our part of the charity work, and we constantly get confirmation that we are reaching 
                                out! But we do not give up, we will continue to grow and work further! 
                                Join us at the charity event then, we can promise you that just you, and your contribution, 
                                will be remembered and appreciated! 
                            </p> 
                            : 
                            <p>
                                Det siste bærekraftsmålet forteller oss noe veldig viktig. Både stiftelser som oss, 
                                organisasjoner og private selskaper må være med på arbeidet. Vi klarer ikke dette alene, 
                                derfor må vi jobbe sammen – og hver for oss, for å nå disse målene. 
                                Vi gjør vår del av dugnaden, og vi får stadig bekreftet at vi strekker til! Men 
                                vi gir oss ikke, vi skal fortsette å vokse og arbeide videre! 
                                Bli med oss på dugnaden da vel, vi kan love deg at akkurat deg, og ditt bidrag, blir husket og verdsatt! 
                            </p> 
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}