import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'

export const navigator = {
    eng: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'> <IoIosArrowBack />home</a>
        </div>
    ),
    nor: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'> <IoIosArrowBack />forsiden</a>
        </div>
    )
}

export const sponsor = {
    eng: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>home</a>
            <a href='/stottOss' className='router notActive'><IoIosArrowBack />support us</a>
            <span className='router'><IoIosArrowBack />become a sponsor</span>
        </div>
    ),
    nor: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/stottOss' className='router notActive'><IoIosArrowBack />støtt arbeidet</a>
            <span className='router'><IoIosArrowBack />bli fadder</span>
        </div>
    )
}

export const regular = {
    eng: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>home</a>
            <a href='/stottOss' className='router notActive'><IoIosArrowBack />support us</a>
            <span className='router'><IoIosArrowBack />become regular doner</span>
        </div>
    ),
    nor: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/stottOss' className='router notActive'><IoIosArrowBack />støtt arbeidet</a>
            <span className='router'><IoIosArrowBack />bli fastgiver</span>
        </div>
    )
}

export const main = {
    eng: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>home</a>
            <span href='/stottOss' className='router'><IoIosArrowBack />support us</span>
        </div>
    ),
    nor: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>forsiden</a>
            <span href='/stottOss' className='router'><IoIosArrowBack />støtt arbeidet</span>
        </div>
    )
}