import axios from "axios";
import React from "react";
const OrderContext = React.createContext();

function OrderProvider(props) {
  // state
  const [order, setOrder] = React.useState(null);
  const [agreement, setAgreement] = React.useState(null);

  // fetch agreement information
  async function collectAgreement(id) {
    try {
      const options = {
        method: "GET",
        url: "https://www.brave-heart.org/stripe-payment/get-payment-details",
        headers: { paymentIntentId: id }
      };
      // request
      const result = await axios(options).then(result => {
        if (result.data.error)
          return setOrder({
            error: {
              message: {
                nor: "Fant ikke bestillingsdetaljer, prøv igjen senere.",
                eng: "Could not find order details please try again later."
              }
            }
          });
        return result.data;
      });
      // response
      if (result) return setAgreement(result);
    } catch (error) {
      return setOrder({
        error: {
          message: {
            nor: "Fant ikke bestillingsdetaljer, prøv igjen senere.",
            eng: "Could not find order details please try again later."
          }
        }
      });
    }
  }

  // fetch order information
  async function collectOrder(id) {
    try {
      const options = {
        method: "GET",
        url: "https://www.brave-heart.org/stripe-payment/get-payment-details",
        headers: { paymentIntentId: id }
      };
      // request
      const result = await axios(options).then(result => {
        if (result.data.error)
          return setOrder({
            error: {
              message: {
                nor: "Fant ikke bestillingsdetaljer, prøv igjen senere.",
                eng: "Could not find order details please try again later."
              }
            }
          });
        return result.data;
      });
      // response
      if (result) setOrder(result);
    } catch (error) {
      return setOrder({
        error: {
          message: {
            nor: "Fant ikke bestillingsdetaljer, prøv igjen senere.",
            eng: "Could not find order details please try again later."
          }
        }
      });
    }
  }

  return (
    <OrderContext.Provider
      value={{ order, collectOrder, agreement, collectAgreement }}
    >
      {props.children}
    </OrderContext.Provider>
  );
}

export { OrderContext, OrderProvider };
