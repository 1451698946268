import React from 'react'
import { LanguageContext } from '../../../context/language'

export function About() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng: (
            <div>
                <h1>About Us</h1>
                <p>
                    Brave Heart Foundation (formerly known as Refugee Alliance Foundation) is an International Christian
                    not for profit organization working to strengthen, serve and support refugees, host community and other
                    people in need. The Organization works to share, with professionalism, extreme kindness to those who
                    have experienced extreme evil; to stand shoulder to shoulder with refugees; and together with the host
                    community and other governmental and non-governmental agencies, provide opportunities for refugees to
                    rebuild their lives from ruins of war & conflicts, strengthen mechanism for integration, and support
                    works to build prosperous communities where people coexists in harmony regardless of their cultural,
                    ethnic, religious or political orientation.
                </p>
                <p>
                    Brave Heart serves all people; stands up as an independent voice for those forced to flee, the poor and
                    other people in need; advocates for their rights and strengthen local community’s mechanism
                    (through capacity building) to tackle poverty, injustice and all forms of violence.
                    Brave Heart’s work is motivated by God’s unconditional love for people and we support sustainable
                    development, emergency aid, missions and positive change for individuals and communities regardless
                    of faith, race or background.
                </p>
            </div>
        ),
        nor: (
            <div>
                <h1>Om Oss</h1>
                <p>
                    Brave Heart (tidligere kjent som Refugee Alliance) er en internasjonal, kristen bistandsorganisasjon som har sitt hovedvirke i
                    Meheba flyktningleir i Nord-Zambia. Vi opplever som stiftelse at vi har et kall fra Gud til å vise omsorg og kjærlighet
                    til mennesker i vanskelige situasjoner. Vi ønsker å være de som svarer “ja” når mennesker i nød spør seg om det finnes noen som vil hjelpe dem.
                </p>
                <p>
                    Brave Heart tjener alle mennesker; står opp sammen med, og som en stemme for, de som er tvunget til å flykte,
                    for de som er fattige og for andre mennesker i nød. Vi ønsker å være talsmann for deres rettigheter, og vi ønsker å styrke lokalsamfunn som påvirkes av fattigdom, urettferdighet og vold. Arbeidet hos Brave Heart er inspirert av Guds betingelsesløse kjærlighet til mennesker, og vi ønsker å være en forlengelse av Guds kjærlighet til de som trenger det mest.
                    Vi ønsker alle som skulle ønske å ta del i dette arbeidet velkommen! For sammen kan vi hjelpe enda flere!
                </p>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
