import React from 'react'
import { LanguageContext } from '../../../context/language'
import { regular } from '../constants/navigator'
import Body from '../constants/regular'
import Navbar from '../../navbar'
import '../css/donations.css'

export default function Regular() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='blifastgiverBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>
            <div className='justify-content-center m-0 row'>
                <div className='pageDiv'>
                    {lang === 'eng' ? regular.eng : regular.nor}
                    <Body/>
                </div>
            </div>          
        </div>
    )
}
