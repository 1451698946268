import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function HopeClinicConstant() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  const content = {
    eng: (
      <div>
        <h1>Hope Clinic</h1>
        <h3>Project manager: Margrin Pirry</h3>
        <p>
          Today, we have a public medical clinic that delivers 365 days a year
          free health care for refugees living in areas linked to the clinic.
          The clinic also accepts patients from other areas in the Meheba refugee camp
          including local Zambians. We carry out tests and treat HIV, Malaria, Tuberculosis
          and other diseases, as well as that we provide follow-ups and medication as needed.
          We also accept women in labour, and have separate patient wards for women, children and men.
          We also have an ambulance which helps make our work more efficient, and which also
          delivers patients with needs that we cannot cover to Lumwana General Hospital.
        </p>
        <p>
          If you have expertise in health and want to contribute, just get in touch!
        </p>
      </div>
    ),

    nor: (
      <div>
        <h1>Hope Clinic</h1>
        <h3>Prosjektleder: Margrin Pirry </h3>
        <p>
          Vi har i dag en offentlig medisinsk klinikk som 365-dager i året leverer
          gratis helsehjelp til flyktninger som bor i områder knyttet til klinikken.
          Klinikken tar også imot pasienter fra andre områder i Meheba flyktningleir
          inkludert lokale Zambiere. Vi utfører tester og behandler HIV, Malaria, Tuberkulose
          og andre sykdommer, samt at vi gir oppfølginger og medisiner etter behov.
          Vi tar også imot fødende kvinner, og har egne pasientavdelinger for kvinner, barn og menn.
          Vi har også en ambulanse som er med å effektivisere arbeidet vårt, og som også
          leverer pasienter med behov som vi ikke kan dekke til Lumwana General Hospital.
        </p>
        <p> Klinikken behandler ca. 1000-1500 pasienter hver måned. </p>
        <p>Dersom du har kompetanse innenfor helse og ønsker å bidra, er det bare å ta kontakt! </p>
      </div>
    )
  }
  return lang === 'eng' ? content.eng : content.nor
}