import React               from 'react';
import Navbar              from '../navbar'
import { LanguageContext } from '../../context/language'
import { nodshjelp }       from './constants/navigation'
import Body                from './constants/nodhjelp'
import                     './css/support.css'

function Nodshjelp() {
    const context          = React.useContext(LanguageContext)
    const {lang}           = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='nødshjelpBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all. ' : 'Vårt oppdrag er å gi glede for alle.'}</p>  
            </div>
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? nodshjelp.eng : nodshjelp.nor}
                    <Body/>
                </div>
            </div> 
        </div>
    )
}

export default Nodshjelp
