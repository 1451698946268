import React from 'react'
import { LanguageContext } from '../../../context/language'
import NavBar from '../../navbar'
import { NavigatorHistory } from './navigator'

export default function History() {
    const context = React.useContext(LanguageContext)
    const { lang } = context
    const content = {
        eng: (
            <div className="ab-history">
                <h1>The Story of Brave Heart Foundation </h1>
                <p>
                    Brave Heart is a story about what God can do through those who surrender everything to
                    Him and follow him with courage and faith. It is a story of how God can use people, in the
                    midst of their brokenness, sickness and pain, to bring healing, comfort and restoration to others.
                    God loves to use what is small and broken in the eyes of the world, to do amazing things.
                    Because nothing is impossible with God!
                </p>
                <p>
                    In 2011, 20th of June, on the world refugee day, Refugee Alliance was founded by
                    Stephen Kaumba and Sina Saltbones. They sat by her kichen table and signed the founding papers.
                    Little did they know about what the next years would bring. Sina had known pastor Stephen for almost
                    a year, and been convinced that if there was one man in this world she should support, it was him.
                </p>

                <p>
                    Refugee Alliance started with pastor Stephens heart for the children and youth in Meheba.
                    And Sinas commitment to help him with that. He had lived together with them as a refugee in Meheba.
                    He promised when he moved to Norway that he would not forget them. And he did not. Pastor Stephen started
                    the project Bridge of Hope, and sent monthly support for 60 children in Meheba to go to school. He knew that
                    going to school would prevent them for getting involved in criminal activity and prevent the girls for being married off early.
                </p>
                <p>
                    Sina left her job as informasjonsleder in KIA, and started as a nurse in the cardiatic
                    intensive ward at the hospital. She also started her own catering business, Taste. This to be able
                    to send money to Refugee Alliance in Meheba. They started by sending 4000kr pr month – from Stephens
                    and Sinas private money. Mr. Salomon in Meheba was the first employee in RA. He followed up the children,
                    and 20 women who had received a loan of 100usd each in microloan. They got experienced people to sit
                    in the first board, led by Oddvar Espegren. Slowly more and more people got engaged mainly through Sina’s
                    network, through FaceBook and personal contact. Up until today FaceBook has been the main channel for
                    information and creating engagement around the work .
                </p>
                <p>
                    Feb 2012 was Sina’s first trip to Meheba. Together with pastor Stephen. 16 hours with bus from Lusaka to
                    Meheba. There they met Mr. Jean and Mr. Odila. They had worked with Forge, an American NGO that had recently
                    ended its work in Zambia. They were trained and experienced in peacebuilding and trauma care. Jean joined RA
                    and started the peace project. He was also appointed as teamleader. What Sina and Stephen saw all the way from
                    the start has been proven through the years up to today – That Mr. Jean is an amazing leader full of wisdom,
                    kindness and strength. Brave Heart would not be the same without him. Also Mr. Odila joined RA from the beginning.
                    He had left the camp and was well established in the city of Solwezy, earning good money in a construction company.
                    But God had spoken to him about going back to work with the traumatized, so when Sina and Stephen asked him if he would join
                    RA in Meheba, he emidiately said yes. He left the comfort of the city and moved back to the camp and to a simple hut.
                </p>
                <p>
                    Their pioneering work with peacebuilding and trauma care in block G opened the way for Refugee Alliance
                    to work in that community of Rwandese refugees. It was the most isolated area in Meheba, with much violence,
                    abuse and darkness. Government and other NGO did not dare to go in due to the situation there. And the authorities
                    half jokingly gave RA the old deserted clinic there to use. But Sina and pastor Stephen took the responsibility,
                    wanting to share the Ligth of God with the darkest corners of the world. They sat down with the community leaders,
                    on the floor and talked as equals. They wanted to hear the needs of the community and how RA could support them.
                    The biggest needs were health services. And Sina said. “We do not have the money. But we will go back to Norway
                    and see if we find it. But you have to pray for us. And you have to promise us that if we start a clinic here, you will
                    protect all our people that no harm will happen to us.” And they all stood up, shook their hands and gave that promise.
                </p>
                <p>
                    RA has from the start had a team of dedicated and well qualified people, with so much compassion and kindness.
                    And an honest and living faith in God. Up to this day this is an important trademark of Brave Heart.
                </p>
                <p>
                    Due to Stephens full time job, health and family situation, the work of RA was for several years mainly carried by
                    Sina. As we all know she poured herself in to it. Her money. Her time. Her energy. Night and day. 24/7/365 days a year.
                    She was leader, co-founder, preacher, fundraiser, organisor, administrator, financial director, application writer etc.
                </p>
                <p>
                    In 2013 RA got media coverage in Kristiansand when Sina got 2 ambulances for her 40th birthday. Earlier that year RA had
                    started its health project, and the need for emergency transport was big. God encouraged and intervened in many ways,
                    and some months later the two ambulances were in Meheba. They served well for a couple of years, but were not made
                    for African bush roads, and ended their service some years ago. Brave Heart is working hard to get a new adequate ambulance.
                </p>
                <p>
                    Already in 2013, Sina got seriously sick with ME (an illness of chronic severe fatigue) and astma. She was on
                    full sick leave for several months, and had to leave the job at the hospital. After some time she started working
                    little by little the days she had strength. some hours here and there in between her long hours in the bed. 10%
                    for a while, then 20%... Few have known how weak her body has been in long periods of time these years. But when
                    she got some strength, she poured it out in RA. She would often feel better during travels, although she has always
                    travelled with a blanket, having to lay down to rest on floors here and there.
                </p>
                <p>
                    2013 she was asked if RA could come and minister in Uganda. A cooperation with pastor Safari began and
                    trauma care was the focus among Congolese refugees  in Kampala and in Rwamwanja (on the boarder to Congo). The first
                    year through MORE Africa – a brazillian organisation, later with Light of the world evangelical church. On her
                    first trip to Uganda she went alone. She met 100 traumatized and abused women. And their desperate need and poverty
                    moved Sina with compassion. That was the start of the womens work and pastors conferences that have been held two
                    times a year up until January 2018. Together with a team Sina has led these conferences with teaching, preaching
                    and ministering – trauma healing, mission, peace building and conflict resolution, entrepeneurship. And having open
                    air small crusades. This work has been really impacting both individuals but also churches and communities in the camp.
                    Around 1500 have been reached each time, and 30-50 people have been saved each time, and been included in to the local
                    churches. The work in Uganda is now taken on by Marit Barlaup that has been part of the team, but she will continue
                    the womens work in Rww through Tabita GO.
                </p>
                <p>
                    In 2014 the work of renewing the old clinic that had been given to RA started. Using local capacity and labor,
                    the miracle of Hope Clinic started growing. In the fall of 2014 Sina was contacted by a missionary couple serving
                    in Irak about coming to help with the refugee crisis caused by the ISIS-invasion. They were flooded with people in
                    the churches, on the streets and they did not know what to do. There was chaos and the needs were huge. Sina said
                    RA does not have any money, but we can come and support you with our knowledge and experience in trauma care and
                    we can help you design a program for how to help. So New Years weekend 2014/2015 Sina and Gry Tangstad travelled
                    to Erbil to strengthen and support these missionaries. That winter there were made and distributed food bags
                    and warm blankets with RA- name and reminder “You are not alone. You are not forgotten.” While they were there
                    they heard about 7 girls that had been rescued from ISIS captivity, and they asked who was helping these traumatised
                    girl. The answer was no one. Sina and Gry could not live with that. And felt they had to take responsibility. We cannot
                    live in this age and just look at the horrors of evil. During the spring of 2015  a world class program of trauma care
                    for these women was designed lead by Helen Nordheim  as an answer to the need. June 2015 Gry Tangstad and Helen Nordheim
                    went to Irak and had a training for 15 women. Out of these 4 were picked to be the RA-team of Irak. In August 2015 Gry
                    went back to Irak alone for three months to pioneer the start up of the trauma centre. She did an amazing job. Later Sina,
                    Helen and other teams from Norway have come to train and partake in the activity of the centre. This work was at the
                    beginning of 2018 taken on by a local church in Dohuk, due to lack of funding in Brave Heart. The centre is now called Hope
                    Centre but runs the Brave Heart Trauma Program that they have used since the beginning.
                </p>
                <p>
                    In the beginning of 2015 RA took a big step and moved out from Sina’s livingroom to an office in
                    Dronningsgate in town. Sharing facilities with several other organisations, RA had now an office and
                    could also use a meeting hall. In march 2015 RA Mission Church was established. It came as an answer to a
                    cry of a Congolese refugee: “It was so hard in the war, but we got strength from God through the fellowship
                    in the church. It was so hard in the refugee camp, but also there we got strengthened by the fellowship in the
                    church. But Norway is the hardest, because here we have nowhere to be strengthened by God”.
                </p>
                <p>
                    Many many people had become involved through the years in different ways. Many travelled to Meheba,
                    Uganda and Irak, both from Norway, sweeden, spain, brazil, colombia – doctors, nurses, students from
                    GUS og Idrettshøyskolen, and many other volunteers. I will not start naming them here. All contributed
                    in different ways both on the field and with creating engasjement around in Norway! All the while Sina
                    was the one carrying the daily running and responsibility of RA. Up til may 2015. That was when the first
                    permanent employee joined RA in 25 %. And with great joy I can say that it was me! Later Felipe, Gunn Helen
                    and Marit also joined the staff, and from September 2017 Stephen has been working full time in Brave Heart.
                    We praise God for that!
                </p>
                <p>
                    September 2015 RA invited to the first SPA-evening for refugee women in Kristiansand. A beautiful women’s
                    ministry that gives hope, new peace and touch from God to tired women through an evening of kindness, massage and rest.
                    In 2015 as an answer to the desperate need for care for the abandoned precious girl Louisa, RA started a
                    childrens home for 4 children. It quickly grew to 6, and today we care for 11 precious children.
                    In October 2015 while sitting at the trauma centre in Dohuk, Sina got called by TARA womens magazine, saying that
                    she had been nominated to the award “Bravest woman of Norway 2015”. She won that award, and RA got known in wider
                    circles than before. Later in 2016 she was rewarded together with Gry Tangstad in the show “Norwegian Heroes”.
                </p>
                <p>
                    1.jan 2016 RA took a big step of faith and moved in to our own premissis where we gathered today are today.
                    It was a big financial commitment to start renting this place. But with an increased flow of refugees in
                    Norway, we had a need of place to work from. With much help from kind volunteers the place was painted and prepared.
                    The municipality and the government decided to support our work here in Norway, and their limited funding made
                    it possible for RA to be in this place.
                </p>
                <p>
                    The beginning of 2016 also marked a huge step in RA as the beautiful HOPE CLINIC was officially
                    opened in Meheba. The impossible had become reality. Without a single penny from any official
                    sources, but solely based on kindness and generosity from individuals and companies and churches,
                    this clinic has been a gift from God to the community in G and the surrounding areas. We are regarded
                    as the best run clinic in the whole area and serve over 1600 patients per month.
                </p>
                <p>
                    1.jan 2017 RA changed name and became Brave Heart. We love that name. It broadened the focus
                    – that it is not only refugees we serve, but with all kinds of brave hearts, and it shows that
                    we want to make peoples hearts brave again – no matter what they have experienced in life.
                    And still our reminder is the same: You are not alone. You are not forgotten.
                </p>
                <p>
                    In august 2017, as she was leaving our trauma centre in Irak, Sina got acute severe back
                    pain and collaps. She was hospitalized in Dubai for 5 days, and then flown back to Norway
                    where bedrest awaited here for several months. During this time of much reflection, prayer
                    and seeking God, it became clear that time had come to step down and hand over the leadership
                    of Brave Heart. That will officially be done today.
                </p>
                <p>
                    There is much in life that becomes different than we had thought or planned or foreseen.
                    That is true for all of us who are here, and also true for Brave Heart. We do not know for sure
                    what the future holds. But we know that with God we have hope! Much hope! Come what may.
                </p>
                <p>
                    As in the beginning of the story, the main focus of Brave Heart is now back on the work in
                    Meheba that God placed on Stephen’s heart.
                    In the last 10 years that have gone since 2011, Brave Heart has grown strong and big in Meheba.
                    We are the only refugee community based NGO that works in the camp and have a team of 65 staff
                    that daily strengthen, encourage and give hope to people in great need. We are now building
                    our local head office, finishing up the building of the children’s home,  the agriculture and
                    moringa project is growing, as well as the microbusiness projects. The clinic is serving the
                    community night and day with highly professional and kind staff. New volunteers are joining
                    and new opportunities opening. There is much hope!
                </p>
            </div>
        ),
        nor: (
            <div className="ab-history">
                <h1>Brave Heart Stiftelsen Historie </h1>
                <p>
                    Brave Heart is a story about what God can do through those who surrender
                    everything to Him and follow him with courage and faith. It is a story of how God can
                    use people, in the midst of their brokenness, sickness and pain, to bring healing, comfort
                    and restoration to others. God loves to use what is small and broken in the eyes of the world,
                    to do amazing things. Because nothing is impossible with God!
                </p>
                <p>
                    In 2011, 20th of June, on the world refugee day, Refugee Alliance was founded by Stephen Kaumba
                    and Sina Saltbones. They sat by her kitchen table and signed the founding papers. Little did they know
                    about what the next years would bring. Sina had known pastor Stephen for almost a year, and was convinced
                    that if there was one man in this world she should support, it was him.
                </p>

                <p>
                    Refugee Alliance started with pastor Stephens' heart for the children and youth in Meheba.
                    And Sinas commitment to help him with that. He had lived together with them as a refugee in Meheba.
                    He promised when he moved to Norway that he would not forget them. And he did not. Pastor Stephen
                    started the project Bridge of Hope, and sent monthly support for 60 children in Meheba to go to school.
                    He knew that going to school would prevent them from getting involved in criminal activity and prevent
                    the girls from being married off early.
                </p>
                <p>
                    Sina left her job as informasjonsleder in KIA, and started as a nurse in the cardiac intensive ward at
                    the hospital. She also started her own catering business, Taste. The catering business made her able to send
                    money to the Refugee Alliance in Meheba. They started by sending 4000 kr pr month – from Stephens and Sinas private money.
                    Mr. Salomon in Meheba was the first employee in RA. He followed up the children, and 20 women who had received a loan of
                    100usd each in microloan. They got experienced people to sit in the first board, led by Oddvar Espegren.
                    Slowly more and more people got engaged mainly through Sina’s network, through FaceBook and personal contact.
                    Up until today FaceBook has been the main channel for information and creating engagement around the work.
                </p>
                <p>
                    Feb 2012 was Sina’s first trip to Meheba. Together with pastor Stephen. 16 hours by bus from Lusaka to Meheba.
                    There they met Mr. Jean and Mr. Odila. They had worked with Forge, an American NGO that had recently ended its work
                    in Zambia. They were trained and experienced in peacebuilding and trauma care. Jean joined RA and started the peace project,
                    and was appointed as team leader. What Sina and Stephen saw all the way from the start has been proven through the
                    years up to today – That Mr. Jean is an amazing leader full of wisdom, kindness and strength. Brave Heart would not
                    be the same without him. Also Mr. Odila joined RA from the beginning. He had left the camp and was well
                    established in the city of Solwezi, earning good money in a construction company. But God had spoken to him about
                    going back to work with the traumatized, so when Sina and Stephen asked him if he would join RA in Meheba, he
                    immediately said yes. He left the comfort of the city and moved back to the camp and to a simple hut.
                </p>
                <p>
                    Their pioneering work with peacebuilding and trauma care in block G opened the way for Refugee Alliance to work
                    in that community of Rwandese refugees. It was the most isolated area in Meheba, with much violence, abuse and darkness.
                    The government and other NGOs were not working in that area due to the situation there. The authorities gave
                    RA the old deserted clinic there to use. Sina and pastor Stephen took the responsibility, wanting to share the light
                    of God with the darkest corners of the world. They sat down with the community leaders, on the floor and talked as equals.
                    They wanted to hear the needs of the community and how RA could support them. The biggest needs were health services.
                    And Sina said. “We do not have the money, but we will go back to Norway and see if we can raise it.
                    But you have to pray for us, and you have to promise us that if we start a clinic here, you will protect
                    all our people that no harm will happen to us.” And they all stood up, shook their hands and gave that promise.
                </p>
                <p>
                    RA has from the start had a team of dedicated and well qualified people, with so much
                    compassion and kindness. And an honest and living faith in God. Up to this day this is an important
                    trademark of the foundation that today is called Brave Heart.
                </p>
                <p>
                    Due to Stephen's full time job, health and family situation, the work of RA was for several years mainly
                    carried by Sina. As we all know she poured herself into it. Her money. Her time. Her energy. Night and day.
                    24/7/365 days a year. She was the leader, co-founder, preacher, fundraiser, organizer, administrator, financial
                    director, application writer etc.
                </p>
                <p>
                    In 2013 RA got media coverage in Kristiansand when Sina got 2 ambulances for her 40th birthday. Earlier that year RA had started its health project, and the need for emergency transport was big. God encouraged and intervened in many ways, and some months later the two ambulances were in Meheba. They served well for a couple of years, but were not made for African bush roads, and ended their service some years ago. Brave Heart has recently been able to get a new Toyota Land Cruiser that will hopefully be driving patients in the next decade.
                </p>
                <p>
                    Already in 2013, Sina got seriously sick with ME (an illness of chronic severe fatigue) and asthma. She was on full sick leave for several months, and had to leave the job at the hospital. After some time she started working little by little the days she had strength. Some hours here and there in between her long hours in the bed. 10% for a while, then 20%... Few have known how weak her body has been in long periods of time these years. But when she got some strength, she poured it into RA. She would often feel better during travels, although she has always traveled with a blanket, having to lay down to rest on floors here and there.
                </p>
                <p>
                    In 2013 Sina was asked if RA could come and minister in Uganda. A cooperation with pastor Safari began and trauma care was the focus among Congolese refugees in Kampala and in Rwamwanja (on the border to Congo). The first year through MORE Africa – a brazilian organization, later with Light of the world evangelical church. On her first trip to Uganda she went alone. She met 100 traumatized and abused women. And their desperate need and poverty moved Sina. That was the start of the women's work and pastors conferences that have been held two times a year up until January 2018. Together with a team Sina has led these conferences with teaching, preaching and ministering – trauma healing, mission, peace building, conflict resolution and entrepreneurship. She would also have small open air crusades. This work has been really impacting both individuals but also churches and communities in the camp. Around 1500 have been reached each time, and 30-50 people have been saved each time, and been included into the local churches. The work in Uganda is now taken on by Marit Barlaup who has been part of the team, but she will continue the women's work in Rww through Tabita GO.
                </p>
                <p>In 2014 the work of renewing the old clinic that had been given to RA started. Using local capacity and labor, the miracle of Hope Clinic started growing.</p>
                <p>
                    In the fall of 2014 Sina was contacted by a missionary couple serving in Iraq about coming to help with the refugee crisis caused by the ISIS-invasion. They were flooded with people in the churches, on the streets, and they did not know what to do. There was chaos and there was a lot of need. Sina said RA does not have any money, but we can come and support you with our knowledge and experience in trauma care and we can help you design a program for how to help. So on New Year's weekend 2014/2015 Sina and Gry Tangstad traveled to Erbil to strengthen and support these missionaries. That winter there were made and distributed food bags and warm blankets with RA. People were told that; “You are not alone. You are not forgotten.” While they were there they heard about 7 girls that had been rescued from ISIS captivity, and they asked who was helping these traumatized girls. The answer was; “no one”. Sina and Gry could not live with that, and felt they had to take responsibility. We cannot live in this age and just look at the horrors of evil. During the spring of 2015 a world class program of trauma care for these women was designed and led by Helen Nordheim as an answer to that need. June 2015 Gry Tangstad and Helen Nordheim went to Iraq and had training for 15 women. Out of these 4 were picked to be the RA-team of Iraq. In August 2015 Gry went back to Iraq alone for three months to pioneer the start up of the trauma center. She did an amazing job. Later Sina, Helen and other teams from Norway have come to train and partake in the activity of the center. This work was at the beginning of 2018 taken on by a local church in Dohuk, due to lack of funding in BraveHeart. The center is now called Hope Centre, but they run the Brave Heart Trauma Program that they have used since the beginning.
                </p>
                <p>
                    In the beginning of 2015 RA took a big step and moved out from Sina’s living room to an office in Dronningensgate in Kristiansand. Sharing facilities with several other organizations, RA now had an office and could also use a conference room. In march 2015 RA Mission Church was established. It came as an answer to a cry of a Congolese refugee: “It was so hard in the war, but we got strength from God through the fellowship in the church. It was so hard in the refugee camp, but also there we got strengthened by the fellowship in the church. But Norway is the hardest, because here we have nowhere to be strengthened by God”.
                </p>
                <p>
                    Many many people had become involved through the years in different ways. Many traveled to Meheba, Uganda and Iraq, both from Norway, Sweden, Spain, Brazil, Colombia – doctors, nurses, students from GUS and Idrettshøyskolen, and many other volunteers. All the while Sina was the one carrying the daily running and responsibility of RA. Up until May 2015. That was when the first permanent employee joined RA in 25 %. And with great joy I can say that it was me! Later Felipe, Gunn Helen and Marit also joined the staff, and from September 2017 Stephen was working full time in BraveHeart. We praise God for that!
                </p>
                <p>In September 2015 RA invited to the first SPA-evening for refugee women in Kristiansand. A beautiful women’s ministry that gives hope, new peace and touch from God to tired women through an evening of kindness, massage and rest.</p>
                <p>
                    In 2015 as an answer to the desperate need for care for the abandoned precious girl Louisa, RA started a children's home for 4 children. It quickly grew to 6, and today we care for 11 precious children. In October 2015 while sitting at the trauma center in Dohuk, Sina got called by TARA women's magazine, saying that she had been nominated to the award “Bravest woman of Norway 2015”. She won that award, and RA got known in wider circles than before. Later in 2016 she was rewarded together with Gry Tangstad in the show “Norwegian Heroes”.
                </p>
                <p>
                    On the 1st of Jan 2016 RA took a big step of faith and moved into our own premises where we gathered daily. It was a big financial commitment to start renting this place. But with an increased flow of refugees in Norway, we had a need for a place to work from. With much help from kind volunteers the place was painted and prepared. The municipality and the government decided to support our work here in Norway, and their help made it possible for RA to be in this place.
                </p>
                <p>
                    The beginning of 2016 also marked a huge step in RA as the beautiful HOPE CLINIC was officially opened in Meheba. The impossible had become reality. Without a single penny from any official sources, but solely based on kindness and generosity from individuals and companies and churches, this clinic has been a gift from God to the community in G and the surrounding areas. We are regarded as the best run clinic in the whole area and serve over 1600 patients per month.
                </p>
                <p>
                    On the 1st of Jan 2017 RA changed its name and became Brave Heart. We love that name. It broadened the focus – that it is not only refugees we serve, but with all kinds of brave hearts, and it shows that we want to make people's hearts brave again – no matter what they have experienced in life. And still our reminder is the same: “You are not alone. You are not forgotten”.
                </p>
                <p>
                    In august 2017, as she was leaving our trauma center in Iraq, Sina got acute severe back pain and collapsed. She was hospitalized in Dubai for 5 days, and then flown back to Norway where bedrest awaited her for several months. During this time of much reflection, prayer and seeking God, it became clear that the time had come for her to step down and hand over the leadership of Brave Heart. There is much in life that becomes different than we had thought or planned or foreseen. That is true for all of us who are here, and also true for Brave Heart. We do not know for sure what the future holds. But we know that with God we have hope! Much hope! Come what may.
                </p>

                <p>
                    As in the beginning of the story, the main focus of Brave Heart is now back on the work in Meheba that God placed on Stephen’s heart. In the last 12 years that have gone since 2011, Brave Heart has grown strong and big in Meheba. We are the only refugee community based NGO that works in the camp and have a team of 61 staff that daily strengthens, encourages and gives hope to people in great need. We are now finishing our local head office, the agriculture and moringa project is growing, as well as the microbusiness projects. The clinic is serving the community night and day with highly professional and kind staff. New volunteers are joining and new opportunities opening. There is much hope!
                </p>
            </div>
        )
    }
    return (
        <div>
            <NavBar />
            <div className='webcontainer'>
                <div>
                    <NavigatorHistory />
                    {lang === 'eng' ? content.eng : content.nor}
                </div>
            </div>
        </div>
    )
}
