import styled from 'styled-components'
export const HeaderImage = styled.div`
  position: relative;
  z-index: 1;
  padding: 0 !important;
  margin: 0 !important;
  background-image: ${props => props.img ? `url(${props.img})`: ''};
  height: 100vh !important;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  & p {
    position: absolute;
    resize: horizontal;
    max-width: 600px;
    right: 5ch;
    bottom: 2ch;
    color: white;
    background-color: #009EE0;
    padding: 1rem;
    margin: 1rem;
  }

  @media only screen and (max-width: 590px) {
    & p { display: none; }
  }
`

export const CampaignBox = styled.div`
  resize: horizontal;
  min-width: 200px;
  max-width: 350px;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;


  & img {
    width: 100%;
    height: 100%;
  }

  & p {
    padding: 8px;
  }

  & a {
    display: inline-block;
    text-decoration: none;
    color: white; 
    width: 100%;  
  }

  & button {
    color: white;
    width: 100%;
    background-color: #009ee0;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
  }
`