import React from 'react'
import Navbar from '../navbar'
import { LanguageContext } from '../../context/language'
import { main } from './constants/navigation'

import m1 from './files/m1.png'
import m2 from './files/m2.png'
import m3 from './files/m3.png'
import m4 from './files/m4.png'
import m5 from './files/m5.png'
import m6 from './files/m6.png'
import m10 from './files/m10.png'
import m12 from './files/m12.png'
import m13 from './files/m13.png'
import m15 from './files/m15.png'
import m16 from './files/m16.png'
import m17 from './files/m17.png'

import './css/maal.css'

function Barekraftigmal() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    
    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>
                    
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? main.eng : main.nor}
                    <div>
                        <h1>{lang === 'eng' ? 'Sustainability goals - our long-term goals' : 'Bærekraftsmål - vårt langsiktig mål'}</h1>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere praesentium illo laboriosam alias ipsam illum, recusandae molestias! Ut repudiandae officiis ipsa, eum reiciendis nemo molestiae sapiente recusandae voluptas, iusto nostrum.
                        <div className='row m-0'>
                            <a href='/bærekraftsmål/utryddefattidom'>
                                <img src={m1} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/utryddesult'>
                                <img src={m2} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/godhelse'>
                                <img src={m3} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/godutdanning'>
                                <img src={m4} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/likestilling'>
                                <img src={m5} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/rentvann'>
                                <img src={m6} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/mindreulikhet'>
                                <img src={m10} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/ansvarligforbruk'>
                                <img src={m12} alt="fn_icons" wwidth='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/stoppeklimaendringene'>
                                <img src={m13} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/livetpåland'>
                                <img src={m15} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/rettiferdighetogvelfungerende'>
                                <img src={m16} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                            <a href='/bærekraftsmål/samarbeid'>
                                <img src={m17} alt="fn_icons" width='150px' height='150px' className='m-2 bkt_img' loading='lazy'/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Barekraftigmal
