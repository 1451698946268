import React from 'react'
import Navbar from '../navbar'
import { LanguageContext } from '../../context/language'
import Main from './constants/main'
import { main } from './constants/navigator'
import './css/bedrift.css'

function Partner() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bedriftsBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>
            <div className='justify-content-center m-0 row'>
                <div className='pageDiv'>
                    {lang === 'eng' ? main.eng : main.nor}
                    <Main/>
                </div>
            </div>
        </div>
    )
}

export default Partner
