import React from "react";

import { Jumbotron, Row } from "react-bootstrap";
import { FooterLinks } from "./constants/links";
import { About } from "./constants/about";
import { Copyright } from "./constants/copyright";

import "./css/footer.css";

function Footer() {
  return (
    <Jumbotron className="footerContainer m-0">
      <Row className="justify-content-center m-0">
        <div className="FTB">
          <About />
          <FooterLinks />
          <Copyright />
        </div>
      </Row>
    </Jumbotron>
  );
}

export default Footer;
