import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function MenigheterConstant() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  const content = {
    eng: (
      <div>
        <h1>Cooperation with local congregations</h1>
        <p>
          The local congregations are an important contributor to our work, as they help meet the needs of people in the camp. We also believe that the hope that faith gives is important for people who have experienced a lot of evil, so we also want to give people the opportunity to hear the message about Jesus, and the hope that it gives us. We believe that there is power in the name of Jesus, and God's love that changes lives.
        </p>
      </div>
    ),
    nor: (
      <div>
        <h1>
          Samarbeid med lokal menigheter
        </h1>
        <p>
          Menighetene lokalt er en viktig bidragsyter til arbeidet vårt, da de er med på å dekke behov som mennesker i leiren har. Vi tror også at håpet som troen gir er viktig for mennesker som har opplevd mye ondskap, så vi ønsker også derfor å gi mennesker muligheten til å høre budskapet om Jesus, og det håpet som det gir oss. Vi tror at det er kraft i Jesus navn, og Guds kjærlighet som forandrer liv.
        </p>
      </div>
    )
  }
  return lang === 'eng' ? content.eng : content.nor
}
