import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Image } from 'react-bootstrap'

import fidel from '../files/Fidel.png'

export default function Road44Constant() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  const content = {
    nor: (
      <div>
        <h1>Road 44</h1>
        <Image src={fidel} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
        <h3>Prosjektleder: Fidel</h3>

        <p>
          Dette er et eldresenter som vi tok over etter FN, og vi har i dag 24 eldre som bor der. Dette prosjektet handler om å ære og ta vare på de eldre i leiren mot slutten av livene deres. De har kanskje ikke familie som kan ta vare på dem, og blir dermed utelatt fra samfunnet slik at de ikke ville fått den hjelpen de trenger. Vi gir dem gjennom dette prosjektet mat, husrom og evt den helsehjelpen de trenger. Vi har også eldre som vi besøker jevnlig rundt om i leiren som har egne plasser å bo.
        </p>

      </div>
    ),
    eng: (
      <div>
        <h1>Road 44</h1>
        <Image src={fidel} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
        <h3>Project manager: Fidel</h3>
        <p>
          This is an elderly center that we took over after the UN, and we currently have 24 elderly people living there. This project is about honoring and caring for the elderly in the camp towards the end of their lives. They may not have family who can take care of them, and are thus left out of society so that they would not get the help they need. Through this project, we provide them with food, accommodation and possibly the health care they need. We also have elderly people who we visit regularly around the camp who have their own places to live.
        </p>
      </div>
    )

  }

  return lang === 'eng' ? content.eng : content.nor
}
