import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Image } from 'react-bootstrap'

import thomas from '../files/Thomas.png';

export default function BarnehjemConstant() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng: (
            <div>
                <h1>Education</h1>
                <Image src={thomas} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
                <h3>Project manager: Thomas</h3>

                <p>
                    We truly believe that education is something that is necessary to be able to turn lives around
                    people who live in Meheba, which is why we help support schooling for pupils
                    from primary school all the way up to university. Previously, we supported students with
                    annual school fee, but schooling for children has now become free in Zambia.
                    The children are still not allowed to come to school without a uniform, books and equipment, which they must provide themselves.
                    So today this is what we are helping to cover so that children in Meheba can have the opportunity to go to school.
                    We also have some for whom we cover university, and this is something we want to do more of in the future.
                </p>

                <p>
                    There is also a long distance between schools in the camp, which means that not everyone has access to school,
                    and some have to walk as far as 18km to get to school. To help improve this, we are, among other things, helping to build a school
                    together with the Japanese embassy in Zambia, which will be completed during the winter of 23/24.
                </p>

            </div>
        ),

        nor: (
            <div>
                <h1>Utdanning</h1>
                <Image src={thomas} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
                <h3>Prosjektleder: Thomas</h3>

                <p>
                    Vi tror virkelig at utdanning er noe som er nødvendig for å kunne snu livene til
                    mennesker som bor i Meheba, og derfor er vi med å støtte skolegang for elever
                    fra barneskole og helt opp til universitetet. Tidligere støttet vi elever med
                    årsavgift for skole, men skolegang for barn har nå blitt gratis i Zambia.
                    Barna får likevel ikke komme på skolen uten uniform, bøker og utstyr som de må skaffe selv.
                    Så nå i dag er det dette vi er med å dekke slik at barn i Meheba kan ha mulighet til å gå på skole.
                    Vi har også noen som vi dekker universitet for, og dette er noe vi ønsker å gjøre mere av i fremtiden.
                </p>
                <p>
                    Det er også langt mellom skolene i leiren som gjør at ikke alle har tilgang
                    til skole, og noen må gå så langt som 18km for å komme til skolen. For å
                    være med å utbedre dette er vi blant annet med på å bygge en skole sammen med Japans
                    ambassade i Zambia, som skal ferdigstilles i løpet av vinteren 23/24.
                </p>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}