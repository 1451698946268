import React from 'react'
import { LanguageContext } from '../../../context/language'
import { IoIosArrowBack } from 'react-icons/io'

export function Navigator() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng: (
            <div>
                <div className='ant-row'>
                    <a href='/' className='router notActive'>home</a>
                    <span className='router'><IoIosArrowBack /> about us</span>
                </div>
            </div>
        ),
        nor: (
            <div>
                <div className='ant-row'>
                    <a href='/' className='router notActive'>forside</a>
                    <span className='router'><IoIosArrowBack />om oss</span>
                </div>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}

export function NavigatorHistory() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng: (
            <div>
                <div className='ant-row'>
                    <a href='/' className='router notActive'>home</a>
                    <a href='/omOss'><span className='router notActive'><IoIosArrowBack />about us</span></a>
                    <span className='router'><IoIosArrowBack /> history</span>
                </div>
            </div>
        ),
        nor: (
            <div>
                <div className='ant-row'>
                    <a href='/' className='router notActive'>forside</a>
                    <a href='/omOss'><span className='router notActive'><IoIosArrowBack />om oss</span></a>
                    <span className='router'><IoIosArrowBack />historie</span>
                </div>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
