import React from 'react'
import Navbar from '../../navbar'
import { LanguageContext } from '../../../context/language'
import { utdanning } from '../constants/navigation'
import Body from '../constants/utdanning'
import '../css/projects.css'

export default function UtdanningProject() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='utdanningBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? utdanning.eng : utdanning.nor}
                    <Body/>
                </div>
            </div>   
        </div>
    )
}
