import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'

export const main = {
    eng: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>home</a>
            <span className='router'><IoIosArrowBack />corporate collaboration</span>
        </div>
    ),
    nor: (
        <div className='ant-row'>
            <a href='/' className='router notActive'>forsiden</a>
            <span className='router'><IoIosArrowBack />bedriftssamarbeid</span>
        </div>
    )
}