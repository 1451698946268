import React, { useState } from 'react'
import { Row, Jumbotron, Carousel } from 'react-bootstrap'
import { IoIosArrowBack } from 'react-icons/io'
import Navbar from '../../navbar'

// PROJECT IMAGES
import img1 from '../../../media/images/microloan kvinner/1.jpeg'
import img2 from '../../../media/images/microloan kvinner/2.jpg'
import img3 from '../../../media/images/microloan kvinner/3.jpeg'
import img4 from '../../../media/images/microloan kvinner/4.jpeg'
import img5 from '../../../media/images/microloan kvinner/5.jpeg'
import img6 from '../../../media/images/microloan kvinner/6.jpg'
import img7 from '../../../media/images/microloan kvinner/7.jpg'


export default function Microloan() {
    // Index State.
    const [index, setIndex] = useState(0)

    // Index Change.
    const handleSelect = function (selectedIndex, e) {
        setIndex(selectedIndex)
    }

    return (
        <div>
            <Navbar transparent={false} />
            <Jumbotron className='singleProjectJumbo'>
                <div className='projectCarousel'>
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        <Carousel.Item key={1}>
                            <img className='w-100 d-block' src={img5} alt="First slide" />
                        </Carousel.Item>
                        <Carousel.Item key={2}>
                            <img className="d-block w-100" src={img7} alt="First slide" />
                        </Carousel.Item>
                        <Carousel.Item key={3}>
                            <img className="d-block w-100" src={img2} alt="First slide" />
                        </Carousel.Item>
                        <Carousel.Item key={4}>
                            <img className="d-block w-100" src={img4} alt="First slide" />
                        </Carousel.Item>
                    </Carousel>
                </div>
                <Row className='justify-content-center'>
                    <Row className='projectBody'>
                        <div className='aboutProject'>
                            <div className='ant-row'>
                                <a href='/' className='router notActive'>Forsiden</a>
                                <span className='router'><IoIosArrowBack /> Mikrolån til kvinner</span>
                            </div>
                            <h1>Mikrolån til kvinner</h1>
                            <p>For oss i Brave Heart Stiftelsen er det viktig å utrydde fattigdom, bekjempe ulikhet og fremme verdighet. For å få til dette, har vi prosjekt med mikrolån til kvinner. Her støtter vi en gruppe kvinner som har opplevd ekstremt ondskap. Vårt mål er å bidra til verdig liv og styrke forståelsen av at de er verdifulle.</p>
                            <p>Våre arbeidere i Meheba flyktningleir møter mange ulike mennesker hver dag. Brave Heart Stiftelsen er en trygg plass, vi har stor tillit i befolkningen, og er opptatt av å ha gode, respektfulle og trygge relasjoner til menneskene vi møter. I disse relasjonene møter vi kvinner som velger å åpne seg for oss og fortelle sin livshistorie. Vi ønsker å hjelpe dem ved å gi praktisk godhet ikke bare ord, samt å vise måter for å bearbeide traumer. Herifra blir kvinnene valgt.</p>
                            <Row className='justify-content-center'>
                                <img src={img1} alt="mlk" height="400px" style={{ objectFit: 'cover' }} className='m-2 rounded' loading='eager' />
                                <img src={img6} alt="mlk" height="400px" style={{ objectFit: 'cover' }} className='m-2 rounded' loading='eager' />
                            </Row>
                            <p>Vi kombinerer mikrolån med traumearbeid. Kvinnene går på et 14 dagers kurs som består av traumearbeid og entreprenørskap. Kurset gir forståelse av hvordan håndtere og starte business, og hva som må til for å få en vellykket bedrift. Våre ansatte med kunnskap om traume og traumebasert omsorg er med på å gi en forståelse av hva som skjer med kroppen etter at en har opplevd traumatiske hendelser som det å være på flukt, miste sine kjære eller voldtekt. Det er viktig for oss å få fram at reaksjonene de har fått er en naturlig reaksjon på en livstruende opplevelse og at det er mulig å bli trygg igjen og at vi kan bidra å hjelpe med å jobbe gjennom det. De siste to dagene av kurset består av en til en sessions, før de til slutt får mikrolånet. For å få mikrolånet, må de ha tatt kurset, og vise interesse for å få til en business. Businessen skal hjelpe kvinnene til å få råd til mat og skolegang til barna. Vi hjelper dem til å se en fremtid selv om de er usikre på hvor lenge den varer i leiren. For selv om de ikke vet om det er ett eller 20 år de blir boende i leiren, er det viktig å få levd livet sitt til det ytterste uansett situasjon.</p>
                            <Row className='justify-content-center'>
                                <img src={img3} alt="mlk" height="400px" width="100%" style={{ objectFit: 'cover' }} className='m-2 rounded' loading='lazy' />
                            </Row>
                            <p>Kvinnene får tett oppfølging ukentlig, både av traume- og entreprenørskapsgruppen. Mikrolånet er et lån som må betales tilbake i løp av åtte måneder. De første tre månedene krever vi ingen tilbakebetaling. Kvinnene selv er med på å bestemme hvordan de har lyst å tilbakebetale disse pengene, om det er hver måned eller annenhver. Etter at de åtte månedene har gått og pengene er tilbakebetalt, finner vi en ny gruppe av 20 kvinner som får lov å begynne med sitt kurs og mikrolån.</p>
                        </div>
                    </Row>
                </Row>

            </Jumbotron>
        </div>
    )
}
