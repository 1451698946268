import React from 'react'
import { LanguageContext } from '../../../context/language'
import { godhelse } from '../constants/navigation'
import Navbar from '../../navbar'
import m3 from '../files/m3.png'

export default function Godhelse() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div>    
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? godhelse.eng : godhelse.nor}
                    <div>
                        {lang === 'eng' ? <h1>Good health and quality of life</h1> : <h1>God helse og livskvalitet</h1>}
                        <img src={m3} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        { lang === 'eng' ?
                            <p>
                                Everyone has the right to good and safe health, and this sustainability goal aims at just that.
                                In (YEAR) we started the work of building a clinic in Meheba. Here everyone in Meheba,
                                no matter who they are - good and safe help! It is open 24 hours a day, and fully funded by us. 
                                <a href="/helse">Here </a> you can read more about this project. 
                            </p>
                        :
                            <p>
                                Alle har rett til god og sikker helse, og dette bærekraftmålet sikter seg inn på akkurat det. 
                                I (ÅRSTALL) begynte vi arbeidet med å bygge en klinikk i Meheba. Her får alle i Meheba, 
                                uansett hvem de er – god og trygg hjelp! Den er døgnåpen, og fullstendig finansiert av oss.  
                                <a href="/helse"> Her</a> kan du lese mer om dette prosjektet. 
                            </p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}