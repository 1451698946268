import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Row } from 'react-bootstrap'

// related images
import prosjekt from '../files/prosjekt.JPG'
import partnere from '../files/parterne.PNG'
import sponsorship from '../files/sponsorship.jpeg'
import bli from '../files/louis.jpg'

export default function Main() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    const content = {
        eng: (
            <div>
                <h1>Corporate Collaboration</h1>
                <p>
                    Did you know that your business can make a big difference?
                    Whether you want a separate project or support one of the ongoing projects.
                    It may feel like a simple contribution, but for the people of Meheba Refugee Camp, it means the whole world. 
                </p>
                <Row className='w-100 m-0'>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={bli} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='lazy'/>  
                            <h5 className='stottText text-white'>How to become a partner</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={prosjekt} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='lazy'/>  
                            <h5 className='stottText text-white'>Select project</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sponsorship} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='lazy'/>  
                            <h5 className='stottText text-white'>Sponsorship</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={partnere} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='lazy'/>  
                            <h5 className='stottText text-white'>Our partners</h5>
                        </a>
                    </Row>
                </Row>
            </div>
        ), 
        nor: (
            <div>
                <h1>Bedriftssamarbeid</h1>
                <p>
                    Viste du at bedriften din kan være med å gjøre en stor forskjell! 
                    Enten om dere ønsker et eget prosjekt eller støtte et av prosjektene som er pågående. 
                    Det føles kanskje som et enkelt bidrag, men for menneskene i Meheba Flyktningleir betyr det hele verden.
                </p>
                <Row className='w-100 m-0'>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={bli} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='lazy'/>  
                            <h5 className='stottText text-white'>Hvordan å bli partner</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={prosjekt} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='lazy'/>  
                            <h5 className='stottText text-white'>Velg prosjekt</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={sponsorship} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='lazy'/>  
                            <h5 className='stottText text-white'>Sponsorship</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/coming-soon'>
                            <img src={partnere} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover'}} loading='lazy'/>  
                            <h5 className='stottText text-white'>Våre partnere</h5>
                        </a>
                    </Row>
                </Row>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
