import React from 'react'
import { LanguageContext } from '../../../context/language'
import {klima} from '../constants/navigation'
import Navbar from '../../navbar'
import m13 from '../files/m13.png'

export default function Klima() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div>       
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? klima.eng : klima.nor}  
                    <div>
                        {lang === 'eng' ? <h1>Stop climate change</h1> : <h1>Stoppe klimaendringene</h1> }
                        <img src={m13} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        { lang === 'eng' ? 
                            <p>
                                The world is changing greatly, and we have seen great changes as a result of 
                                high consumption and the industrial revolution. We do not want to be part of 
                                the problem, but rather part of the solution! That is why we are so concerned 
                                with the sustainability goals, responsible production and consumption.
                                We work with this goal through all our projects, this is the core of our desire to be sustainable.       
                            </p> 
                            :
                            <p>
                                Verden er i stor endring, og vi har sett store endringer som følge av høyt 
                                forbruk og den industrielle revolusjon. Vi ønsker ikke å være en del av problemet, 
                                men heller en del av løsningen! Derfor er vi så opptatte av bærekraftsmålene, 
                                ansvarlig produksjon og forbruk.  
                                Vi arbeider med dette målet gjennom alle prosjektene våre, dette er kjernen 
                                av vårt ønske om å være bærekraftige. 
                            </p>
                        }
                    </div>
                </div>
            </div>    
        </div>
    )
}