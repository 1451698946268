import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Image } from 'react-bootstrap'

import melance from '../files/Melance.png'

export default function PeaceProjectConstant() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  const content = {
    nor: (
      <div>
        <h1>Peace Prosjekt</h1>
        <Image src={melance} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
        <h3>Prosjektleder: Melance </h3>

        <p>
          Dette er spydspissen av arbeidet vi gjør. Dette arbeidet er kartleggende for å se hvilke behov mennesker i leiren har, og hvordan vi kan møte dem gjennom de forskjellige prosjektene vi har. Gjennom Peace project gir vi også traumebehandling, kurs og terapi. Vi jobber med å forebygge voldshendelser i leiren, og vi jobber for helbredelse gjennom menneskelig kontakt, og tror at Gud gjennom dette prosjektet bygger opp igjen nedbrutte mennesker som kanskje ikke så noen fremtid før de ble fanget opp av arbeiderne våre. Vi deler også ut mat gjennom dette prosjektet, og jobber mot et mål om at ingen skal dø av sult.
        </p>

      </div>
    ),
    eng: (
      <div>
        <h1>Peace Project</h1>
        <Image src={melance} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
        <h3>Project manager: Melance </h3>
        <p>
          This is the spearhead of the work we do. This work is mapping to see what needs people in the camp have, and how we can meet them through the various projects we have. Through the Peace project, we also provide trauma treatment, courses and therapy. We work to prevent incidents of violence in the camp, and we work for healing through human contact, and believe that through this project God rebuilds broken people who may not have seen a future until they were caught by our workers. We also distribute food through this project, and work towards a goal that no one should die of hunger.
        </p>
      </div>
    )

  }

  return lang === 'eng' ? content.eng : content.nor
}
