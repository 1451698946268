import React from 'react'
import { BiDonateHeart } from 'react-icons/bi'
import { RiFundsLine } from 'react-icons/ri'
import { MdChildCare } from 'react-icons/md'
import { VscGift } from 'react-icons/vsc'
import { AiOutlineMedicineBox } from 'react-icons/ai'
import { FaRegHandshake } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap'

// støtt oss menu
export const StottOssMenu = {
    eng:
        <div className="drop-content">
            <Row className='justify-content-center align-items-center'>
                <a href='/fastgiver' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BiDonateHeart className='iconSettings' />
                        <Col>
                            <h6>Become a regular doner</h6>
                            <p>Create change for refugees</p>
                        </Col>
                    </Row>
                </a>
                <a href='/fadder' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdChildCare className='iconSettings' />
                        <Col>
                            <h6>Become a sponsor</h6>
                            <p>Provide assistance to children</p>
                        </Col>
                    </Row>
                </a>
                <a href='https://www4.solidus.no/SolidusUser/placeorder/85344C54-3279-4A0D-9DF4-FBFAE2B46313' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <VscGift className='iconSettings' />
                        <Col>
                            <h6>Give a gift</h6>
                            <p>Give a gift to people in need</p>
                        </Col>
                    </Row>
                </a>
                <a href='/innsamling' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <RiFundsLine className='iconSettings' />
                        <Col>
                            <h6>Start a fundraiser</h6>
                            <p>Participate in fundraising</p>
                        </Col>
                    </Row>
                </a>
                <a href='/partner' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaRegHandshake className='iconSettings' />
                        <Col>
                            <h6>Become a partner</h6>
                            <p>Be a Brave-Heart partner</p>
                        </Col>
                    </Row>
                </a>
                <a href='/nodshjelp' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <AiOutlineMedicineBox className='iconSettings' />
                        <Col>
                            <h6>Support emergency aid fund</h6>
                            <p>Give emergency support</p>
                        </Col>
                    </Row>
                </a>
            </Row>
        </div>,
    no:
        <div className="drop-content">
            <Row className='justify-content-center align-items-center'>
                <a href='/fastgiver' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BiDonateHeart className='iconSettings' />
                        <Col>
                            <h6>Bli fastgiver</h6>
                            <p>Skape forandring for flyktninger</p>
                        </Col>
                    </Row>
                </a>
                <a href='/fadder' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdChildCare className='iconSettings' />
                        <Col>
                            <h6>Bli fadder</h6>
                            <p>Gi hjelp til barn</p>
                        </Col>
                    </Row>
                </a>
                <a href='https://www4.solidus.no/SolidusUser/placeorder/85344C54-3279-4A0D-9DF4-FBFAE2B46313' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <VscGift className='iconSettings' />
                        <Col>
                            <h6>Gi en gave</h6>
                            <p>Gi til mennesker i nød</p>
                        </Col>
                    </Row>
                </a>
                <a href='/innsamling' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <RiFundsLine className='iconSettings' />
                        <Col>
                            <h6>Start en innsamling</h6>
                            <p>Delta i våre innsamling aksjoner</p>
                        </Col>
                    </Row>
                </a>
                <a href='/partner' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaRegHandshake className='iconSettings' />
                        <Col>
                            <h6>Bli patner</h6>
                            <p>Bli en Brave-Heart partner</p>
                        </Col>
                    </Row>
                </a>
                <a href='/nodshjelp' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <AiOutlineMedicineBox className='iconSettings' />
                        <Col>
                            <h6>Støtt vårt nødhjelpsfond</h6>
                            <p>Gi nødstøtte</p>
                        </Col>
                    </Row>
                </a>
            </Row>
        </div>
}