import React from 'react'
import { LanguageContext } from '../../../context/language'
import { sponsor } from '../constants/navigator'
import Body from '../constants/sponsor'
import { Button } from 'react-bootstrap'
import Navbar from '../../navbar'
import '../css/donations.css'

export default function Sponsor() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    return (
        <div>
            <Navbar />
            <div className='navdisplace'>
                <div className='blifadderBackground' />
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>
            <div className='justify-content-center m-0 row'>
                <div className='pageDiv'>
                    {lang === 'eng' ? sponsor.eng : sponsor.nor}
                    <Body />
                    <a className='text-blue' href='http://www4.solidus.no/SolidusUser/placeorder/11C6FC2E-D84A-4555-975B-2023270CA4B0'>
                        <Button className='oButtons dOptionButton' variant='outline-primary'>{lang === 'eng' ? 'Sign a giro agreement with Vipps or BankID' : 'Signerer avtale giro med Vipps eller BankID'}</Button>
                    </a>
                </div>
            </div>
        </div>
    )
}
