import React             from 'react'
import { OrderProvider } from '../../context/vippsOrder'
import Order             from './pages/orderDetails'

export default function Wrapper(props) {
    // order id param
    const {orderId} = props.match.params

    return (
        <OrderProvider>
            <Order orderId={orderId}/>
        </OrderProvider>
    )
}