import React from 'react'
import { LanguageContext } from '../../context/language'
import { DesktopNav } from './constants/desktop'
import { MobileNav } from './constants/mobile'
import { IoIosMenu } from 'react-icons/io'
import logo from './files/bheart.svg'
import './css/nav.css'

function Navbar() {
    const [visible, setVisible] = React.useState(false)
    const showMobileDrawer = function () { setVisible(true) }
    const onCloseMobileDrawer = function () { setVisible(false) }
    const context = React.useContext(LanguageContext)
    const { lang, setEnglish, setNorwegian } = context

    return (
        <div>
            <div id='Navbar'>
                <DesktopNav />
                <div className='mRow row m-0'>
                    <div className='row justify-content-between align-items-center m-0 mobileNavContainer'>
                        <div className='Logo'><a href='/'><img src={logo} alt='Logo' height={'80px'} width={'155px'} /></a></div>
                        <div className='row m-0 align-items-center'>
                            {lang === 'eng' ?
                                <div className="language text-white" onClick={() => setNorwegian('nor')}>Nor</div> :
                                <div className="language text-white" onClick={() => setEnglish('eng')}>Eng</div>
                            }
                            <div className='menuIcon' id='menuIcon'><IoIosMenu className='hambugerIcon text-white' onClick={showMobileDrawer} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <MobileNav visible={visible} onCloseMobileDrawer={onCloseMobileDrawer} />
        </div>
    )
}

export default Navbar