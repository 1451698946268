import React from "react";
import { OrderContext } from "../../../context/stripeOrder";
import Navbar from "../../navbar";
import { AgreementSuccess } from "./agreementSuccess";

export default function Order(props) {
    // context
    const context = React.useContext(OrderContext);
    const { agreement, collectAgreement } = context;
    const { agreementId } = props;
    const getAgrement = () => {
        if (agreementId) return collectAgreement(agreementId);
    };

    // get agreement
    React.useEffect(() => {
        getAgrement();
    });

    return (
        <div>
            <Navbar />
            <div className="webcontainer">
                <div className="pageDiv">
                    {agreement && agreement.error ? (
                        <div>
                            <h1 className="textUnderlineRed">Oi dette skulle ikke skje.</h1>
                            <p>Betalingen var stoppet, vennligst prøv igjen seinere.</p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            Doloremque inventore asperiores repudiandae fugit repellendus
                            error id ab sunt veritatis, voluptate veniam eligendi sit odit ex,
                            tempora dolor commodi ullam ipsum!
                        </div>
                    ) : agreement ? (
                        <AgreementSuccess />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
