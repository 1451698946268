import React from "react";
import { Button } from "react-bootstrap";
import { Result } from "antd";
import { OrderContext } from "../../../context/vippsOrder";
import Navbar from "../../navbar";
import coverImg from "../files/1.png";
import "../css/vipps.css";
import { LanguageContext } from "../../../context/language";

function Success(props) {
    const langContext = React.useContext(LanguageContext)
    const { lang } = langContext

    // order id
    const { orderId } = props;

    // context
    const context = React.useContext(OrderContext);
    const { order, collectOrder } = context;
    const getOrder = () => {
        if (orderId) return collectOrder(orderId);
    };

    // get order
    React.useEffect(() => {
        getOrder();
    });

    return (
        <div>
            <Navbar />
            <div className="webcontainer">
                <div className="justify-content-center m-0">
                    <div className="vp_sdiv text-center">
                        {order && order.transactionLogHistory ? (
                            <div>
                                {order.transactionLogHistory[0].operation === "RESERVE" ||
                                    order.transactionLogHistory[0].operation === "CAPTURE" ? (
                                    <div>
                                        <Result className="mt-4" status="success" />
                                        <h1>Tusen takk for din støtte.</h1>
                                        <div className="mb-3">
                                            <small>
                                                {" "}
                                                Du har gitt{" "}
                                                {order.transactionSummary.capturedAmount / 100 + " Kr."}
                                            </small>
                                        </div>
                                        {lang === 'eng' ?
                                            "Your support will make a big difference for the people in Meheba. Thank you for standing with us in giving them the help they need and deserve!"
                                            : "Din støtte vil være med på å bety en stor forskjell for menneskene i Meheba. Takk for at vi kan stå sammen i å gi dem den hjelpen de trenger og fortjener!"
                                        }
                                        <div className="row justify-content-center p-2 m-2">
                                            <img
                                                src={coverImg}
                                                width="90%"
                                                height="400px"
                                                style={{ objectFit: "cover", borderRadius: "4px" }}
                                                alt="paymentSucess"
                                                loading="lazy"
                                            />
                                        </div>
                                        <a href="/arbeid">
                                            <Button className="oButtons">
                                                {" "}
                                                Se våre prosjekter.{" "}
                                            </Button>
                                        </a>
                                    </div>
                                ) : order.transactionLogHistory[0].operation === "CANCEL" ? (
                                    <div className="vp_sdiv text-center">
                                        <Result className="mt-4" status="info" />
                                        <h3 className="text-red">
                                            Oi, dette skulle helst ikke ha skjedd!
                                        </h3>
                                        <p className="terms">
                                            Vi beklager, men det ser ut til å ha oppstått en feil i
                                            forbindelse med din registrering. Ta gjerne kontakt med
                                            oss direkte på e-post <a href="mailto">post@bheart.no</a>{" "}
                                            eller ring oss på telefon +47 40541180 alle dager. Om du
                                            vil kan du også prøve igjen litt senere. Hilsen Brave
                                            Heart Stiftelsen
                                        </p>
                                        <a href="/gigave">
                                            <Button className="oButtons">Prøv igjen.</Button>
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                        ) : order && order.error ? (
                            <div>
                                <div className="vp_sdiv text-center">
                                    <Result className="mt-4" status="error" />
                                    <h1>Oppstått en feil.</h1>
                                    <p className="terms">
                                        Betaling ble kansellert av kunde eller server. Vennligst
                                        prøv igjen seinere eller kontakt oss på telefon 40541180
                                        eller epost via <a href="mailto">post@bheart.no</a>{" "}
                                    </p>
                                    <a href="/gigave">
                                        <Button className="oButtons">Prøv igjen.</Button>
                                    </a>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Success;
