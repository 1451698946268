import React from 'react'
import { MdPeopleOutline } from 'react-icons/md'
import { VscProject } from 'react-icons/vsc'
import { BiUserPlus, BiDonateHeart } from 'react-icons/bi'
import { Row, Col } from 'react-bootstrap'

// bedrifts menu
export const bedriftsSamarbeidMenu = {
    eng:
        <div className="drop-content">
            <Row className='justify-content-center align-items-center'>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BiUserPlus className='iconSettings' />
                        <Col>
                            <h6>How to become a partner</h6>
                            <p>Register to join us</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <VscProject className='iconSettings' />
                        <Col>
                            <h6>Choose a project</h6>
                            <p>Select a project to sponsor</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BiDonateHeart className='iconSettings' />
                        <Col>
                            <h6>Sponsorship</h6>
                            <p>How sponsorship works</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdPeopleOutline className='iconSettings' />
                        <Col>
                            <h6>Our Partners</h6>
                            <p>See our registered partners </p>
                        </Col>
                    </Row>
                </a>
            </Row>
        </div>,
    no:
        <div className="drop-content">
            <Row className='justify-content-center align-items-center'>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BiUserPlus className='iconSettings' />
                        <Col>
                            <h6>Hvordan å bli patner</h6>
                            <p>Registrer som partner</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <VscProject className='iconSettings' />
                        <Col>
                            <h6>Velg et prosjekt</h6>
                            <p>Velg et prosjekt du vil sponse</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BiDonateHeart className='iconSettings' />
                        <Col>
                            <h6>Sponsoravtale</h6>
                            <p>Hvordan sponsing fungerer</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdPeopleOutline className='iconSettings' />
                        <Col>
                            <h6>Våre partnere</h6>
                            <p>Se våre registrerte partnere</p>
                        </Col>
                    </Row>
                </a>
            </Row>
        </div>
}