import React from 'react'
import Navbar from '../../navbar'
import { OrderContext } from '../../../context/vippsOrder'
import { AgreementDetails } from './agreementDetails'

export default function AgreementStatus() {
    // id
    const agrId = localStorage.getItem('agrId') ? localStorage.getItem('agrId') : null

    // context
    const context = React.useContext(OrderContext)
    const { agreement, collectAgreement } = context
    const getAgrement = () => { return collectAgreement(agrId) }

    // get order
    React.useEffect(() => {
        getAgrement()
    })


    return (
        <div>
            <Navbar />
            <div className='webcontainer'>
                {agreement ? <AgreementDetails status={agreement.status} price={agreement.price} productDescription={agreement.productDescription} /> : null}
            </div>
        </div>
    )
}