import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function MisjonConstant() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    const content = {
        eng: (
            <div>
                <h1>Mission</h1>
                <h3>Employees: 1 </h3>
                <p className='text-red'>Pastor: bilde</p>
                <p>
                    Brave Heart Stiftesen is a Christian organization. It means our faith
                    is the reason why the work exists. Our values are rooted in them
                    Christian values where Jesus is our role model for everything we do. Jesus went
                    not around the world and just talking, but he felt heartfelt compassion
                    poor, sick and unhappy. That is why it is important for us in the Brave Heart Foundation
                    to do something when we see need. We will see those who are sick, see those who are afraid, see those who
                    are filthy, see those who are without hope. 
                </p>
                <p>
                    The Brave Heart Foundation is for everyone who needs us. We discriminate
                    not someone with our help. We help everyone regardless of faith, status, orientation,
                    or life situation.  
                </p>
                <p>
                    In 2018, our first mission church in Zambia was opened. Our employees and
                    projects serve the people of Meheba. Our goodness is credible ..... 
                </p>
                <p>
                    The mission project is financially supported in its entirety by a congregation in Kristiansand. 
                </p>
                <p>
                    We attend services, distribute Bibles, hold conferences for children, youth, church leaders and pastors. 
                </p>

                <p className='text-red'>Bilde av teltkirken , video av korsang/lovsang </p>
            </div>
        ),
        nor: (
            <div>
                <h1>Misjon</h1>
                <h3>Ansatte: 1</h3>
                <p className='text-red'>Pastor: bilde</p>
                <p>
                    Brave Heart Stiftesen er en kristen organisasjon. Det betyr at vår tro 
                    er grunnen til at arbeidet eksisterer. Våre verdier er forankret i de 
                    kristne verdiene hvor Jesus er vårt forbilde for alt vi gjør. Jesus gikk 
                    ikke rundt i verden og bare snakket, men han følte inderlig medfølelse med 
                    fattige, syke og ulykkelige. Derfor er det viktig for oss i Brave Heart Stiftelsen 
                    å gjøre noe når vi ser nød. Vi skal se de som er syke, se de som er redde, se de som 
                    er møkkete, se de som er uten håp.
                </p>
                <p>
                    Brave Heart Stiftelsen er til for alle som trenger oss. Vi diskriminerer 
                    ikke noen med vår hjelp. Vi hjelper alle uansett tro, status, legning, 
                    eller livssituasjon.  
                </p>
                <p>
                    I 2018 ble vår første misjonskirke i Zambia åpnet. Våre ansatte og 
                    prosjekter betjener befolkningen i Meheba. Vår godhet er troverdig .....
                </p>
                <p>
                    Misjonsprosjektet støttes økonomisk i sin helhet av en menighet i Kristiansand.
                </p>
                <p>
                    Vi deltar på gudstjenester, deler ut bibler, holder konferanser for barn , ungdom, kirkeledere og pastorer. 
                </p>

                <p className='text-red'>Bilde av teltkirken , video av korsang/lovsang </p>
            </div>
        )
    }

    return lang === 'eng' ? content.eng : content.nor
}
