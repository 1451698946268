import React               from 'react'
import { LanguageContext } from '../../../context/language'

export function Copyright() {
    const context          = React.useContext(LanguageContext)
    const { lang }         = context

    const rights = {
        eng: (
            <div className='row m-0 justify-content-center align-items-center mt-4'>
                <div className='ff-copy m-3'>
                    <small className='footerCopyright'>
                        Brave Heart Stiftelsen {new Date().getFullYear()} Copyright © All Rights Reserved. 
                    </small>
                    <span>
                        <a href='/personvernerklæring' className='footerCopyright text-white textUnderline m-1'>Privacy</a>
                    </span>
                    <span><a href='/salgsbetingelser' className='footerCopyright text-white textUnderline m-1'>Terms of sale</a></span>
                </div>
            </div>
        ),
        nor: (
            <div className='row m-0 justify-content-center align-items-center mt-4'>
                <div className='ff-copy m-3'>
                    <small className='footerCopyright'>
                        Brave Heart Stiftelsen {new Date().getFullYear()} Copyright © Alle rettigheter forbeholdt. 
                    </small>
                    <span>
                        <a href='/personvernerklæring' className='footerCopyright text-white textUnderline m-1'>Personvernerklæring</a>
                    </span>
                    <span><a href='/salgsbetingelser' className='footerCopyright text-white textUnderline m-1'>Salgsbetingelser</a></span>
                </div>
            </div>
        )
    }
    return lang === 'eng' ? rights.eng : rights.nor
}
