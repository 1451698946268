import React from 'react'
import {LanguageContext} from '../../context/language'
import { main } from './constants/navigation'
import Navbar from '../navbar'
import Body from './constants/main'

function Projects() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='projectsBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Girls in Meheba, Zambia.' : 'Jenter i Meheba, Zambia.'}</p>
            </div>
            
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? main.eng : main.nor}
                    <Body/>
                </div>              
            </div>
            
        </div>
    )
}

export default Projects
