import React from 'react'
const LanguageContext = React.createContext()

function LanguageProvider(props){
    // default language
    const lg = localStorage.getItem('lang')

    // state
    const [lang, setLang] = React.useState(lg ? lg : 'nor')

    // norsk
    const setNorwegian = function() {
        setLang('nor') }

    // norwegian
    const setEnglish = function() {
        setLang('eng') }
    
    React.useEffect(() => {
        localStorage.setItem('lang', lang)
    }, [lang])

    return (
        <LanguageContext.Provider value={{lang, setEnglish, setNorwegian}}>
            {props.children}
        </LanguageContext.Provider>
    )    
}

export { LanguageContext, LanguageProvider }
