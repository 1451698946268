import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Image } from 'react-bootstrap'
import sagees from '../files/Sagees.png'

export default function JobbConstant() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const content = {
        eng: (
            <div>
                <h1>Job creation and entrepreneurship</h1>
                <Image src={sagees} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
                <h3>Project manager: Sagees</h3>
                <p>We truly believe that providing job opportunities can help change the future of people. With the 59 employees we have through our various projects alone, there are several hundred people who are affected by the ripple effects. Those who receive wages from us get the opportunity to support their families, and money also goes into the local community when they have the opportunity to buy more of what they need. Such ripple effects help to build the community inside the camp.</p>
                <p>We also want to teach entrepreneurship, and in some cases help to support the start-up of businesses that can help strengthen the local community in the camp.</p>
            </div>
        ),
        nor: (
            <div>
                <h1>Jobbskaping og entreprenørskap</h1>
                <Image src={sagees} alt='utdanning' fluid roundedCircle loading='lazy' width={'200px'} />
                <h3>Prosjektleder: Sagees</h3>
                <p>
                    Vi tror virkelig at det å gi jobbmuligheter kan være med på å endre fremtiden til mennesker. Alene med de 59 ansatte vi har gjennom de forskjellige prosjektene våre er det flere hundre mennesker som blir påvirket av ringvirkningene. De som får lønninger av oss får muligheten til å forsørge familiene sine, og penger går også inn i lokalsamfunnet når de har mulighet til å kjøpe mer av det de trenger. Slike ringvirkninger er med på å bygge samfunnet inne i leiren.
                </p>
                <p>
                    Vi ønsker også å lære bort entreprenørskap, og er i noen tilfeller med på å gi støtte til oppstart av bedrifter som kan være med å styrke lokalsamfunnet i leiren.
                </p>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
