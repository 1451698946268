import React from 'react'
import { LanguageContext } from '../../../context/language'
import {rentvann} from '../constants/navigation'
import Navbar from '../../navbar'
import m6 from '../files/m6.png'

export default function Rentvann() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div>             
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>  
                    {lang === 'eng' ? rentvann.eng : rentvann.nor}                  
                    <div>
                        { lang === 'eng' ? <h1>Clean water and good sanitation</h1> : <h1>Rent vann og gode sanitærforhold</h1>}
                        <img src={m6} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        {lang === 'eng' ? 
                            <p>
                                This sustainability goal is closely linked to Sustainability Goal number 3. 
                                In addition, this is related to increasing the standard of living in the camp, 
                                for our part. By raising the standard of living in the camp, creating security 
                                and safety for the people of Meheba, we are more and more able to also develop 
                                other sanitary conditions for our friends. We develop knowledge and solutions, 
                                among other things, through this <a href="/coming-soon">(Link 3)</a> project.
                                The same goes for drinking water, because even though there are many wells in Meheba. 
                                Not everyone is equally good, but if we work together and continue to develop together, we will achieve this goal! 
                            </p> 
                            : 
                            <p>
                                Dette bærekraftmålet er tett knyttet til Bærekraftmål nummer 3. 
                                I tillegg er dette knyttet til å øke levestandarden i leiren, for vår del. 
                                Ved å øke levestandarden i leiren, skape trygghet og sikkerhet for menneskene 
                                i Meheba blir vi mer og mer i stand til å også utvikle andre sanitærforhold for 
                                vennene våre. Vi utvikler kunnskap og løsninger blant annet gjennom dette <a href="/coming-soon">(Link 3)</a> prosjektet.   
                                Det samme gjelder drikkevann, for selv om det er mange brønner i Meheba. Er ikke alle like gode, 
                                men om vi jobber sammen og fortsetter å utvikle oss sammen vil vi nå dette målet! 
                            </p> 
                        }
                    </div>                    
                </div>
            </div>            
        </div>
    )
}