import React from 'react'
import { FaChalkboardTeacher, FaFemale } from 'react-icons/fa'
import { RiHeartPulseLine, } from 'react-icons/ri'
import { FiShield, } from 'react-icons/fi'
import { HiOutlineUserGroup, } from 'react-icons/hi'
import { BiHomeAlt, } from 'react-icons/bi'
import { GiPeaceDove, } from 'react-icons/gi'
import { AiOutlineHeart } from 'react-icons/ai'
import { MdWork, MdSportsBasketball, MdBusiness } from 'react-icons/md'
import { Row, Col } from 'react-bootstrap'

// vårt arbeid menu
export const VårtArbeidMenu = {
    eng:
        <div className="drop-content">
            <Row className='justify-content-center align-items-center'>
                <a href='/hopeClinic' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <RiHeartPulseLine className='iconSettings' />
                        <Col>
                            <h6>Hope Clinic</h6>
                            <p>Good health and quality of life</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FiShield className='iconSettings' />
                        <Col>
                            <h6>Child protection</h6>
                            <p>Safe homes for children</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <HiOutlineUserGroup className='iconSettings' />
                        <Col>
                            <h6>Integration work</h6>
                            <p>Hard work and unity</p>
                        </Col>
                    </Row>
                </a>
                <a href='/utdanning' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaChalkboardTeacher className='iconSettings' />
                        <Col>
                            <h6>Education</h6>
                            <p>Accessbility to education</p>
                        </Col>
                    </Row>
                </a>
                <a href='/purehome' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BiHomeAlt className='iconSettings' />
                        <Col>
                            <h6>Pure Hope Children's Home</h6>
                            <p>Providing shelter for children</p>
                        </Col>
                    </Row>
                </a>
                <a href='/peace' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <GiPeaceDove className='iconSettings' />
                        <Col>
                            <h6>Peace Project</h6>
                            <p>Trauma treatment and therapy</p>
                        </Col>
                    </Row>
                </a>
                <a href='/road44' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <AiOutlineHeart className='iconSettings' />
                        <Col>
                            <h6>Road 44</h6>
                            <p>Care for the elderly</p>
                        </Col>
                    </Row>
                </a>
                <a href='/jenteinternat' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaFemale className='iconSettings' />
                        <Col>
                            <h6>Girls boarding school</h6>
                            <p>Education for girls</p>
                        </Col>
                    </Row>
                </a>
                <a href='/jobb' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdWork className='iconSettings' />
                        <Col>
                            <h6>Job creation and entrepreneurship</h6>
                            <p>Job opportunities</p>
                        </Col>
                    </Row>
                </a>
                <a href='/sport' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdSportsBasketball className='iconSettings' />
                        <Col>
                            <h6>Sports</h6>
                            <p>Support for sports</p>
                        </Col>
                    </Row>
                </a>
                <a href='/menigheter' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdBusiness className='iconSettings' />
                        <Col>
                            <h6>Cooperation with local congregations</h6>
                        </Col>
                    </Row>
                </a>
                <a href='/ngo' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdBusiness className='iconSettings' />
                        <Col>
                            <h6>Cooperation with local authorities and other NGOs</h6>
                        </Col>
                    </Row>
                </a>
            </Row>
        </div>,
    no:
        <div className="drop-content">
            <Row className='justify-content-center align-items-center'>
                <a href='/hopeClinic' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <RiHeartPulseLine className='iconSettings' />
                        <Col>
                            <h6>Hope Clinic</h6>
                            <p>God helse og livskvalitet</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FiShield className='iconSettings' />
                        <Col>
                            <h6>Barnebeskyttelse</h6>
                            <p>Safe homes for barn</p>
                        </Col>
                    </Row>
                </a>
                <a href='/coming-soon' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <HiOutlineUserGroup className='iconSettings' />
                        <Col>
                            <h6>Integrasjonsarbeid</h6>
                            <p>Hardt arbeid og samhold</p>
                        </Col>
                    </Row>
                </a>
                <a href='/utdanning' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaChalkboardTeacher className='iconSettings' />
                        <Col>
                            <h6>Utdanning</h6>
                            <p>Tilgjengelighet til utdanning</p>
                        </Col>
                    </Row>
                </a>
                <a href='/purehome' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <BiHomeAlt className='iconSettings' />
                        <Col>
                            <h6>Pure Hope Children's Home</h6>
                            <p>Skaffe hjem til barn</p>
                        </Col>
                    </Row>
                </a>
                <a href='/peace' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <GiPeaceDove className='iconSettings' />
                        <Col>
                            <h6>Peace Prosjekt</h6>
                            <p>Traumebehandling og terapi</p>
                        </Col>
                    </Row>
                </a>
                <a href='/road44' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <AiOutlineHeart className='iconSettings' />
                        <Col>
                            <h6>Road 44</h6>
                            <p>Eldreomsorg</p>
                        </Col>
                    </Row>
                </a>
                <a href='/jenteinternat' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <FaFemale className='iconSettings' />
                        <Col>
                            <h6>Jenteinternat</h6>
                            <p>Utdanning for jenter</p>
                        </Col>
                    </Row>
                </a>
                <a href='/jobb' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdWork className='iconSettings' />
                        <Col>
                            <h6>Jobbskaping og entreprenørskap</h6>
                            <p>Jobbmuligheter</p>
                        </Col>
                    </Row>
                </a>
                <a href='/sport' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdSportsBasketball className='iconSettings' />
                        <Col>
                            <h6>Idrett</h6>
                            <p>Støtte til idrett</p>
                        </Col>
                    </Row>
                </a>
                <a href='/menigheter' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdBusiness className='iconSettings' />
                        <Col>
                            <h6>Samarbeid med lokal menigheter</h6>
                        </Col>
                    </Row>
                </a>
                <a href='/ngo' className="megaLinks">
                    <Row className='megaLinkItem'>
                        <MdBusiness className='iconSettings' />
                        <Col>
                            <h6>Samarbeid med lokale myndigheter og andre ngo</h6>
                        </Col>
                    </Row>
                </a>
            </Row>
        </div>
}