import React from "react"
import { CampaignImage, ClinicCampaignContainer, CampaignBody } from "./elements"
import { LanguageContext } from "../../../context/language"
import Navbar from '../../navbar'
import skole from './skole.JPG'

export default function EducationCampaign() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  return (
    <div>
      <Navbar />
      <CampaignBody>
        <CampaignImage>
          <img src={skole} alt="skole" />
        </CampaignImage>
        {lang === 'eng' ?
          <ClinicCampaignContainer>
            <h1 className='textUnderlineRed'>This year’s most important Christmas gift!</h1>
            <div>
              <a className='campaign' href="/gigave"><button>Donate one time.</button></a>
              <a className='campaign' href="https://www2.solidus.no/ASrd/RegistrerAvtale.aspx?ID=tfhRPK1++sPn88/oqYXr0A=="> <button>Become a regular doner here.</button> </a>
            </div>
            <p>
              Through Brave Heart Foundation you can give, may be the most important Christmas gift have given so far: Education to a refugee child. A gift with meaning. A gift that lasts a lifetime!
              By providing formal Education to refugee youth we are breaking the cycle of poverty and building a sustainable and prosperous Meheba community.
              When you choose to be a sponsor, you donate 300kr each month and this cutters for tuition fees, boarding school fees, health care and school uniform. Sponsored refugee youth get also to be mentored and followed up weekly by our local staff in Meheba.
              Once you become a sponsor, you will receive a photo of the child you sponsor, their background story and family situation, and you embark  on a journey to where shuttered dreams are rekindled and come life again.
            </p>

            <p>
              In conflicts zones and remote refugee camps like Meheba, children and particularly girls are the most affected and pay the highest price. Sexual violence, forced teenage marriage, and other gender related violence constitute a barrier for girls’ development and shutter their dreams. For some the teenage girl is the house manager as she keeps up the house chores, while for the other she is already a bride. Your monthly contribution will help to break this vicious cycle , promote girls worth and dignity, while strengthening Brave Heart  Foundation’s capacity to advocate for and protect the girl child in Meheba refugee camp.
              Partner with us today to create sustainable change and opportunities for girls.
              Read more about the sponsorship program <a href="/fadder"> here </a>.
            </p>

            <p>
              We know that education saves lives. Your monthly donation will give the child knowledge, empowerment and opportunity to attain self-sufficiency. Thus achieving SDG 1, 2, 3, 4, 8 etc.
              If you wish to give one time donation for a refugee child or many to attend school, you can easily do by Vipps here or through our gift account 30002603086
              For Every gift of 500 or more, you have the right to tax deduction. Learn more <a href="/personvernerklæring"> here</a>.
              Did you know that Meheba refugee camp is one of the biggest refugee camps in the world and has the size of Singapore? It is host to close to 35,000 refugees and people of concern and is divided in eight blocks from A to H. Learn more about Meheba <a href="/"> here</a>.
            </p>
          </ClinicCampaignContainer> :
          <ClinicCampaignContainer>
            <h1 className='textUnderlineRed'>Årets kanskje viktigste julegave!</h1>
            <div>
              <a className='campaign' href="/gigave"><button>Gi en gang.</button></a>
              <a className='campaign' href="https://www2.solidus.no/ASrd/RegistrerAvtale.aspx?ID=tfhRPK1++sPn88/oqYXr0A=="> <button>Bli en fastgiver her. </button> </a>
            </div>
            <p>
              I gjennom stiftelsen Brave Heart kan du gi Årets kanskje viktigste julegave, som er skolegang til et barn.
              Skolegang er veien ut av fattigdom og du kan være med hjelpe barn og ungdom i flyktningleiren Meheba.
              Ved å bli fadder og støtte med 300kr i mnd dekker du studieavgift, skolemateriell, mat, internat, helse og oppfølging til et barn eller ungdom.
              Hvis du blir fadder får du et bilde og litt informasjon om fadderbarnet og livet i Meheba.
            </p>

            <p>
              Det er særlig viktig å gi unge jenter muligheten til å fortsette med skolegangen og bo på internat når de blir tenåringer.
              Mange jenter anses som arbeidskraft eller giftes bort når de når tenårene. Vær med å Styrke verdigheten til barn og ungdom i Meheba ved å bli fadder i dag.
              Du kan lese mer om fadderprogrammet <a href="/fadder"> her</a>.  Vi vet at utdanning redder liv. Din månedlige gave gir barn skolegang,
              kunnskapen til å bekjempe og unngå fattigdom, holde seg friske og gir håp for framtiden.
              Hvis du ikke har mulighet til å bli fadder vil likevel en enkeltgave bety noe for et eller flere barn som ønsker å gå på skole.
            </p>

            <p>
              For deg som ikke kjenner til Meheba Flyktningleir i Zambia, så er det en av verdens største flyktningleir hvor det bor ca 33 000 mennesker.
              Eneste mulighet til å komme ut av leiren er via FN, å få opphold i annet land, noe kun 87 mennesker gjorde i fjor, eller hvis en har utdannelse
              og får arbeidstillatelse. Du kan lese mer om arbeidet til Brave Heart i Meheba <a href="/"> her</a>.
            </p>
          </ClinicCampaignContainer>
        }
      </CampaignBody>
    </div>
  )
}