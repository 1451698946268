import React from 'react'
import { LanguageContext } from '../../../context/language'
import { utdanning } from '../constants/navigation'
import Navbar from '../../navbar'
import m4 from '../files/m4.png'

export default function Godutdanning() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>       
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? utdanning.eng : utdanning.nor}
                    <div>
                        {lang === 'eng' ? <h1>Good education</h1> : <h1>God utdanning</h1>}
                        <img src={m4} alt="fn_icons" width='200px' height='200px' className='m-2'loading='lazy'/>
                        {lang === 'eng' ? 
                            <p>
                                60 million children around the world do not have access to a good education as we are so lucky to have,
                                here in Norway. In Meheba there are schools, but education is not free in Zambia. Therefore support
                                we many children and young people, sponsor their education! Education opens many doors, and we believe that
                                Education is not only a door into new and exciting knowledge - but also a door into the future.
                                Where students from Meheba in the future can help other children in need. 
                            </p> 
                            : 
                            <p>
                                60 millioner barn omkring i verden har ikke tilgang til god utdannelse slik vi er så heldige å ha, 
                                her i Norge. I Meheba finnes det skoler, men utdannelse er ikke gratis i Zambia. Derfor støtter 
                                vi mange barn og unge, sponser deres utdannelse! Utdannelse åpner mange dører, og vi mener at 
                                utdannelse ikke bare er en dør inn i ny og spennende kunnskap – men også en dør inn i fremtiden. 
                                Hvor elevene fra Meheba i fremtiden, kan hjelpe andre barn i nød.  
                            </p> 
                        }
                    </div>
                </div>
            </div>  
        </div>
    )
}