import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Row } from 'react-bootstrap'

export function FooterLinks() {
    const context = React.useContext(LanguageContext)
    const { lang } = context

    const links = {
        eng: (
            <Row className='m-0'>
                <div className='footerBlock'>
                    <h3 className='footerHeader textUnderline pb-1'>Our work</h3>
                    <p><a className='orangeHover' href='/hopeClinic'>hope clinic</a></p>
                    <p><a className='orangeHover' href='/utdanning'>education</a></p>
                    <p><a className='orangeHover' href='/purehome'>pure hope children's home</a></p>
                    <p><a className='orangeHover' href='/peace'>peace project</a></p>
                    <p><a className='orangeHover' href='/road44'>road 44</a></p>
                    <p><a className='orangeHover' href='/jobb'>job creation and entrepreneurship</a></p>
                    <p><a className='orangeHover' href='/jenteinternat'>girls boarding school</a></p>
                    <p><a className='orangeHover' href='/sport'>Support for sports</a></p>
                </div>
                <div className='footerBlock'>
                    <h3 className='footerHeader textUnderline pb-1'>Support us</h3>
                    <p><a className='orangeHover' href='https://www4.solidus.no/SolidusUser/placeorder/85344C54-3279-4A0D-9DF4-FBFAE2B46313'>give support now</a></p>
                    <p><a className='orangeHover' href='/fadder'>become a sponsor</a></p>
                    <p><a className='orangeHover' href='/fastgiver'>become a regular doner</a></p>
                    <p><a className='orangeHover' href='/innsamling'>start a fundraiser</a></p>
                    <p><a className='orangeHover' href='/nodshjelp'>support emergency aid fund</a></p>
                </div>
                <div className='footerBlock'>
                    <h3 className='footerHeader textUnderline pb-1'>Get involved</h3>
                    <p><a className='orangeHover' href='/frivillig'>volunteer in Norge</a></p>
                    <p><a className='orangeHover' href='/frivillig'>volunteer in Zambia</a></p>
                    <p><a className='orangeHover' href='/ngo'>Cooperation with local authorities and other NGOs</a></p>
                    <p><a className='orangeHover' href='/menigheter'>Cooperation with local congregations</a></p>
                </div>
                <div className='footerBlock'>
                    <h3 className='footerHeader textUnderline pb-1'>Give a gift</h3>
                    <p className='orangeHover footerText'>gift account: 30002603086</p>
                    <p className='orangeHover footerText'>vipps: 105075</p>
                    <p><a className='orangeHover' href='https://www2.solidus.no/ASrd/RegistrerAvtale.aspx?ID=tfhRPK1++sPn88/oqYXr0A=='>avtalegiro</a></p>
                    <p><a className='orangeHover' href='/stottOss'>support us</a></p>
                </div>
            </Row>
        ),
        nor: (
            <Row className='m-0'>
                <div className='footerBlock'>
                    <h3 className='footerHeader textUnderline pb-1'>Vårt arbeid</h3>
                    <p><a className='orangeHover' href='/hopeClinic'>hope clinic</a></p>
                    <p><a className='orangeHover' href='/utdanning'>utdanning</a></p>
                    <p><a className='orangeHover' href='/purehome'>pure hope children's home</a></p>
                    <p><a className='orangeHover' href='/peace'>peace project</a></p>
                    <p><a className='orangeHover' href='/road44'>road 44</a></p>
                    <p><a className='orangeHover' href='/jobb'>jobbskaping og entreprenørskap</a></p>
                    <p><a className='orangeHover' href='/jenteinternat'>jenteinternat</a></p>
                    <p><a className='orangeHover' href='/sport'>Støtte til idrett</a></p>
                </div>
                <div className='footerBlock'>
                    <h3 className='footerHeader textUnderline pb-1'>Støtt arbeidet</h3>
                    <p><a className='orangeHover' href='https://www4.solidus.no/SolidusUser/placeorder/85344C54-3279-4A0D-9DF4-FBFAE2B46313'>gi gave</a></p>
                    <p><a className='orangeHover' href='/fadder'>bli fadder</a></p>
                    <p><a className='orangeHover' href='/fastgiver'>bli fastgiver</a></p>
                    <p><a className='orangeHover' href='/innsamling'>start innsamling</a></p>
                    <p><a className='orangeHover' href='/nodshjelp'>støtte nødhjelpsfond</a></p>
                </div>
                <div className='footerBlock'>
                    <h3 className='footerHeader textUnderline pb-1'>Engasjer deg</h3>
                    <p><a className='orangeHover' href='/frivillig'> bli frivillig i Norge </a></p>
                    <p><a className='orangeHover' href='/frivillig'>bli frivillig i Zambia</a></p>
                    <p><a className='orangeHover' href='/ngo'>Samarbeid med lokale myndigheter og andre ngo</a></p>
                    <p><a className='orangeHover' href='/menigheter'>Samarbeid med lokal menigheter </a></p>
                </div>
                <div className='footerBlock'>
                    <h3 className='footerHeader textUnderline pb-1'>Gi en gave</h3>
                    <p className='orangeHover footerText'>gave konto: 30002603086</p>
                    <p className='orangeHover footerText'>vipps: 105075</p>
                    <p><a className='orangeHover' href='https://www2.solidus.no/ASrd/RegistrerAvtale.aspx?ID=tfhRPK1++sPn88/oqYXr0A=='>avtalegiro</a></p>
                    <p><a className='orangeHover' href='/stottOss'>støtt oss</a></p>
                </div>
            </Row>
        )
    }
    return lang === 'eng' ? links.eng : links.nor
}
