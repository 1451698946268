import React from "react";
import { LanguageContext } from "../../../context/language";
import { Button } from "react-bootstrap";

export default function InnsamlingContent() {
    const context = React.useContext(LanguageContext);
    const { lang } = context;

    const content = {
        eng: (
            <div>
                <div>
                    <h1>Start Fundraizing</h1>
                    The work the Brave Heart Foundation does in Meheba is possible because
                    we receive large and small donations for our work. In addition to a
                    large voluntary effort, it means a lot to us if more people can hold a
                    fundraising campaign, do a bazaar or create an event where the income
                    goes to the work. Start your own birthday fundraiser, challenge your
                    congregation to give a collection, or do a garage sale for friends and
                    acquaintances while doing something important for others. Feel free to
                    contact us if you need marketing materials, photos or information to
                    start a fundraiser.
                </div>
                <a href="https://www.facebook.com/pg/bheart.no/fundraisers/?create&source=nonprofit_short_url&source_data[referral_source]=">
                    <Button className="oButtons dOptionButton" variant="outline-primary">
                        Start a fundraiser
                    </Button>
                </a>
            </div>
        ),
        nor: (
            <div>
                <div>
                    <h1>Start Innsamling</h1>
                    Arbeidet Brave Heart Stiftelsen gjør i Meheba er mulig fordi vi mottar
                    store og små donasjoner til vårt arbeid. I tillegg til en stor
                    frivillig innsats betyr det svært mye for oss om flere kan holde en
                    innsamlingsaksjon, gjøre en basar eller skape en event hvor inntektene
                    går til arbeidet. Start din egen bursdagsinnsamling, utfordre
                    menigheten din til å gi en kollekt, eller gjør et garasjesalg for
                    venner og kjente samtidig som du gjør noe viktig for andre. Ta gjerne
                    kontakt med oss om du trenger markedsmateriell, bilder eller
                    informasjon for å få igang en innsamling.
                </div>
                <a href="https://www.facebook.com/pg/bheart.no/fundraisers/?create&source=nonprofit_short_url&source_data[referral_source]=">
                    <Button className="oButtons dOptionButton" variant="outline-primary">
                        Start innsamling nå
                    </Button>
                </a>
            </div>
        )
    };
    return lang === "eng" ? content.eng : content.nor;
}
