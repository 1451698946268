import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function SafehomeConstant() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    const content = {
        eng: (
            <div>
                <h1>Safe home for girls</h1>
                <h3>Employees: 1</h3>
                <h3>Participants: 2</h3>

                <p>
                    The Meheba refugee community houses many refugees who have experienced war.
                    Very many have survived inhuman acts of war and suffering and bearing
                    this with it as wounds or trauma. Many traumatized people become alcoholics and
                    this destroys society by many people dying early, violence in close relationships,
                    abuse, neglect, poor finances, lack of education, unemployment
                    and poverty. In this vicious circle, girls are sexually abused. Girls can
                    be sold, or brutally assaulted and raped. When we in the Brave Heart Foundation
                    heard about this we could not help but do something. In 2019, we found girls at 9
                    years, and girls of 12 years who were not only brutally abused by several men, but also
                    expelled from the family. Sometimes children get lost in Meheba. Outcast children can walk like that
                    Fair Game. This evil must stop!  
                </p>

                <p>
                    The Brave Heart Foundation has established one at a secret address in Zambia
                    safe home for particularly vulnerable girls. The project is new and the needs are
                    enormous. In talks with national authorities, we have been challenged to establish
                    one of three centers for raped women and children.  
                </p>

                <p>We need regular donors and large partners, we must deliver on this promise.</p>

            </div>
        ),
        nor: (
            <div>
                <h1>Trygt hjem for jenter</h1>
                <h3>Ansatte: 1</h3>
                <h3>Deltakere: 2</h3>

                <p>
                    Meheba flyktningesamfunn huser mange flyktninger som har opplevd krig. 
                    Svært mange har overlevd umenneskelige krigshandlinger og lidelse og bærer dette 
                    med seg som sår eller traumer. Mange traumatiserte blir alkoholisert og dette 
                    ødelegger samfunnet ved at mange dør tidlig, det blir mye vold i nære relasjoner,
                    mishandling, neglisjering, dårlig økonomi, fravær av utdanning, stor arbeidsløshet og 
                    fattigdom. I denne onde spiralen blir mange jenter dessverre misbrukt og opplever brutale overgrep. 
                    Jenter kan bli solgt, eller brutalt overfalt og voldtatt. Da vi i Brave Heart Stiftelsen 
                    fikk høre om dette, kunne vi ikke la være å gjøre noe. I 2019 fant vi jenter på 9 år, og 
                    jenter på 12 år som ikke bare var brutalt misbrukt av flere menn, men også utstøtt 
                    fra familien. Av og til blir barn borte i Meheba. Utstøtte barn kan gå rundt som fritt vilt. 
                    Denne ondskapen må stoppe! 
                </p>

                <p>
                    Brave Heart Stiftelsen har på hemmelig adresse i Zambia opprettet 
                    et trygt hjem for spesielt utsatte jenter. Prosjektet et nytt og behovene 
                    er enorme. I samtaler med nasjonale myndigheter er vi blitt utfordret til å 
                    etablere ett av tre One-stop sentre i Zambia. Dette er sentre for voldtatte kvinner 
                    og barn som Zambiske myndigheter, FN og IOM samarbeider om, og de ber Brave Heart 
                    være med som leder og drifter av ett av disse nasjonale sentrene.  
                </p>

                <p>Vi trenger faste givere og store partnere skal vi klare dette.</p>

            </div>
        )
    }
    return  lang === 'eng' ? content.eng : content.nor
}
