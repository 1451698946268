import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'

export const innsamling = {
    eng: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>home</a>
            <a href='/stottOss' className='router notActive'><IoIosArrowBack />support us</a>
            <span className='router'><IoIosArrowBack />start fundraising</span>
        </div>
    ),
    nor: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/stottOss' className='router notActive'><IoIosArrowBack />støtt arbeidet</a>
            <span className='router'><IoIosArrowBack />start innsamling</span>
        </div>
    )
}

export const nodshjelp = {
    eng: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>home</a>
            <a href='/stottOss' className='router notActive'><IoIosArrowBack />support us</a>
            <span className='router'><IoIosArrowBack />support emergency aid funds</span>
        </div>
    ),
    nor: (
        <div className='row m-0 align-items-center'>
            <a href='/' className='router notActive'>forsiden</a>
            <a href='/stottOss' className='router notActive'><IoIosArrowBack />støtt arbeidet</a>
            <span className='router'><IoIosArrowBack />Støtt vårt nødhjelpsfond</span>
        </div>
    )
}