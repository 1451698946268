import React from "react";
import { LanguageContext } from "../../../context/language";
import { Button } from "react-bootstrap";

export default function NodshjelpContent() {
    const context = React.useContext(LanguageContext);
    const { lang } = context;

    const content = {
        eng: (
            <div>
                <div>
                    <h1>Support Emergency Aid Fund</h1>
                    Among the 30,000 refugees in the Meheba refugee camp and in all other
                    communities, there will always be some who find it extra difficult.
                    Examples are children who become orphans, family crises, the elderly
                    who are unable to take care of themselves, hunger, alcoholism and
                    trauma to name a few. The Brave Heart Foundation exists for those who
                    experience extreme distress and then we can not run projects in Meheba
                    if we do not manage to provide an offer to those who have the most
                    difficulty. Next day, our employees receive help from mothers in
                    crisis, young people who are struggling, ill or we see or hear about
                    incidents that need urgent action. Every week we distribute food and
                    supplies to very many people living in extreme poverty. In some cases,
                    we are called in to resolve family crises where children may need a
                    new home or where the camp cabin has collapsed in the heavy rain. Our
                    help today is very limited as our resources are not enough. If you
                    really want to have a positive impact on people in need their lives, a
                    donation to our relief fund is very good. It is very painful for us to
                    reject people in need.
                </div>
                <a href="https://www4.solidus.no/SolidusUser/placeorder/1C2B9815-21ED-459B-973B-424FB30E4995" target="_blank" rel="noopener noreferrer">
                    <Button className="oButtons dOptionButton" variant="outline-primary">
                        Support emergency fund
                    </Button>
                </a>
            </div>
        ),
        nor: (
            <div>
                <div>
                    <h1>Støtt Vårt Nødhjelpsfond</h1>
                    Blant de 30.000 flyktningene i Meheba flyktningleir og i alle samfunn
                    forøvrig vil det alltid være noen som har det ekstra vanskelig.
                    Eksempel kan være barn som blir foreldreløse, familiekriser, eldre som
                    ikke klarer ta vare på seg selv, sult, alkoholisme og traume for å
                    nevne noe. Brave Heart Stiftelsen eksisterer for de som opplever
                    ekstrem nød og da kan vi ikke drive prosjekter i Meheba om vi ikke
                    greier å gi et tilbud til de som har det vanskeligst. Neste daglig får
                    våre ansatte bønn om hjelp fra mødre i krise, ungdom som sliter, syke
                    eller vi ser eller hører om hendelser som trenger akutte tiltak.
                    Ukentlig deler vi ut mat og forsyninger til svært mange som lever i
                    ekstrem fattigdom. I en del tilfeller blir vi tilkalt for å løse opp i
                    familiekriser der kanskje barn må få et nytt hjem eller hvor
                    leirhytten har rast sammen i det kraftige regnværet. Vår hjelp er i
                    dag svært begrenset da våre ressurser ikke strekker til. Hvis du
                    virkelig ønsker å ha positiv innvirkning på mennesker i nød sitt liv
                    er en donasjon til vårt nødhjelpsfond veldig bra. Det er veldig vondt
                    for oss å avvise mennesker i nød.
                </div>
                <a href="https://www4.solidus.no/SolidusUser/placeorder/1C2B9815-21ED-459B-973B-424FB30E4995" target="_blank" rel="noopener noreferrer">
                    <Button className="oButtons dOptionButton" variant="outline-primary">
                        Støtt nødhjelpfond
                    </Button>
                </a>
            </div>
        )
    };
    return lang === "eng" ? content.eng : content.nor;
}
