import React from "react";
import { LanguageProvider } from "./context/language";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// pages
import HomePage from "./components/home/";
// import StottArbeid from "./components/donation/payments/gift";
import Fadder from "./components/donation/payments/sponsor";
import Fastgiver from "./components/donation/payments/regular";
import Omoss from "./components/omoss";

import BhHistorie from "./components/omoss/constants/history";
import StottOss from "./components/donation";
import Engasjer from "./components/engasjerdeg";
import Footer from "./components/footer";
import ComingSoon from "./components/404/comingsoon";
import Notfound from "./components/404";
import Projects from "./components/vartarbeid";
import Innsamling from "./components/supportWork/startinnsamling";
import Nodshjelp from "./components/supportWork/nodshjelp";
import Partner from "./components/bedriftssamarbeid";
import Frivillig from "./components/engasjerdeg/constants/frivillig";
import VolunteerSuccess from "./components/engasjerdeg/frivilligSuccess";
import Personvern from "./components/personvern";
import Barekraftig from "./components/barekraftigmal";
import Terms from "./components/salgsbetingelser";
import Order from "./components/vipps/orderdetailsWrapper";

// projects
import Microloan from "./components/vartarbeid/projects/microloan";
import Barnehjem from "./components/vartarbeid/projects/barnehjem";
import Helse from "./components/vartarbeid/projects/helse";
import Utdanning from "./components/vartarbeid/projects/utdanning";
import Jobb from "./components/vartarbeid/projects/jobbskapnning";
import Misjon from "./components/vartarbeid/projects/misjon";
import SafeHome from "./components/vartarbeid/projects/safehome";
import HopeClinic from "./components/vartarbeid/projects/hopeClinic";
import Road44Project from "./components/vartarbeid/projects/road44";
import PeaceProject from "./components/vartarbeid/projects/peace";
import PureHomeProject from "./components/vartarbeid/projects/pureHome";
import JenteinternatProject from "./components/vartarbeid/projects/jenteinternat";
import Idrett from "./components/vartarbeid/projects/idrett";
import Menigheter from "./components/vartarbeid/projects/menigheter";
import Ngo from "./components/vartarbeid/projects/ngo";

// vipps
import Agreement from "./components/vipps/pages/agreement";
import AgreementStatus from "./components/vipps/agreementWrapper";

// stripe
import StripeOrder from "./components/stripe/orderWrapper";
import StripeAgreement from "./components/stripe/agreementWrapper";

// Clinic Campaign
import CampaignsIntro from "./components/campaigns";
import EducationCampaign from "./components/campaigns/clinic/campaign";
// import Clinic from "./components/campaigns/clinic";

// UN SDGs
import Samarbeid from "./components/barekraftigmal/pages/samarbeid";
import Fred from "./components/barekraftigmal/pages/fred";
import Livet from "./components/barekraftigmal/pages/livetland";
import Klima from "./components/barekraftigmal/pages/klima";
import Ansvarlig from "./components/barekraftigmal/pages/ansvarligforbruk";
import Mindreulikhet from "./components/barekraftigmal/pages/mindreulikhet";
import RentVann from "./components/barekraftigmal/pages/rentvann";
import Likestilling from "./components/barekraftigmal/pages/likestilling";
import Godutdanning from "./components/barekraftigmal/pages/godutadnning";
import GodHelse from "./components/barekraftigmal/pages/godhelse";
import Utrydfattidom from "./components/barekraftigmal/pages/utryddefattigdom";
import Utrydsult from "./components/barekraftigmal/pages/uttryddesult";

// main css
import "./App.css";

function App() {
  return (
    <div className="App">
      <LanguageProvider>
        {/* <Clinic /> */}
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage} />
            {/* <Route exact path="/gigave" component={StottArbeid} /> */}
            <Route exact path="/fastgiver" component={Fastgiver} />
            <Route exact path="/omOss" component={Omoss} />

            <Route exact path="/brave-heart-historie" component={BhHistorie} />
            <Route exact path="/fadder" component={Fadder} />
            <Route exact path="/engasjer" component={Engasjer} />
            <Route exact path="/coming-soon" component={ComingSoon} />
            <Route exact path="/frivillig" component={Frivillig} />

            <Route exact path="/mikrolån_til_kvinner" component={Microloan} />
            <Route exact path="/barnehjem" component={Barnehjem} />
            <Route exact path="/helse" component={Helse} />
            <Route exact path="/utdanning" component={Utdanning} />
            <Route exact path="/jobb" component={Jobb} />
            <Route exact path="/misjon" component={Misjon} />
            <Route exact path="/safe-home" component={SafeHome} />
            <Route exact path="/hopeClinic" component={HopeClinic} />
            <Route exact path="/road44" component={Road44Project} />
            <Route exact path="/peace" component={PeaceProject} />
            <Route exact path="/purehome" component={PureHomeProject} />
            <Route
              exact
              path="/jenteinternat"
              component={JenteinternatProject}
            />
            <Route exact path="/sport" component={Idrett} />
            <Route exact path="/menigheter" component={Menigheter} />
            <Route exact path="/ngo" component={Ngo} />

            <Route exact path="/stottOss" component={StottOss} />
            <Route exact path="/innsamling" component={Innsamling} />
            <Route exact path="/nodshjelp" component={Nodshjelp} />
            <Route exact path="/arbeid" component={Projects} />
            <Route exact path="/partner" component={Partner} />

            <Route exact path="/vipps-subscriptions" component={Agreement} />
            <Route exact path="/vipps/fall-back/:orderId" component={Order} />
            <Route exact path="/vipps-agreement" component={AgreementStatus} />
            <Route
              exact
              path="/stripe-order/:orderId"
              component={StripeOrder}
            />
            <Route
              exact
              path="/stripe-agreement/:agreementId"
              component={StripeAgreement}
            />
            <Route exact path="/personvernerklæring" component={Personvern} />
            <Route exact path="/salgsbetingelser" component={Terms} />
            <Route
              exact
              path="/volunteer-success"
              component={VolunteerSuccess}
            />

            {/* DECREAPTED ROUTES ============= */}
            <Route exact path="/campaigns" component={CampaignsIntro} />

            <Route
              exact
              path="/campaigns/education"
              component={EducationCampaign}
            />

            <Route exact path="/bærekraftsmaal" component={Barekraftig} />
            <Route
              exact
              path="/bærekraftsmål/samarbeid"
              component={Samarbeid}
            />
            <Route
              exact
              path="/bærekraftsmål/rettiferdighetogvelfungerende"
              component={Fred}
            />
            <Route exact path="/bærekraftsmål/livetpåland" component={Livet} />
            <Route
              exact
              path="/bærekraftsmål/stoppeklimaendringene"
              component={Klima}
            />
            <Route
              exact
              path="/bærekraftsmål/ansvarligforbruk"
              component={Ansvarlig}
            />
            <Route
              exact
              path="/bærekraftsmål/mindreulikhet"
              component={Mindreulikhet}
            />
            <Route exact path="/bærekraftsmål/rentvann" component={RentVann} />
            <Route
              exact
              path="/bærekraftsmål/likestilling"
              component={Likestilling}
            />
            <Route
              exact
              path="/bærekraftsmål/godutdanning"
              component={Godutdanning}
            />
            <Route exact path="/bærekraftsmål/godhelse" component={GodHelse} />
            <Route
              exact
              path="/bærekraftsmål/utryddesult"
              component={Utrydsult}
            />
            <Route
              exact
              path="/bærekraftsmål/utryddefattidom"
              component={Utrydfattidom}
            />
            <Route path="*" component={Notfound} />
          </Switch>
        </Router>
        <Footer />
      </LanguageProvider>
    </div>
  );
}

export default App;
