import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function BarnehjemConstant() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    const content = {
        nor: (
            <div>
                <h1>Barnehjem</h1>
                <h3>Status 2021: 19 barn, 4 ansatte, en sosionom</h3>

                <p>
                    I vårt arbeid i Meheba flyktningesamfunn kommer vi ofte over barn som ikke har det bra. Noen barn har mistet sine foreldre. Noen barn har foreldre som ikke har mulighet til å gi trygghet og omsorg. Noen barn har nedsatt funksjonsevne og lever under svært vanskelige forhold og uten mulighet for et verdig liv. Noen barn blir overlatt til seg selv.  
                </p>

                <p>
                    I Brave Heart Stiftelsen klarer vi ikke å se på dette uten å gjøre noe. Derfor startet vi et barnehjem i 2014 for å gi et tilbud til barn som hadde det ekstremt vanskelig.
                </p>

                <p>
                    I FN´s barnekonvensjon defineres følgende: 
                    <ul>
                        <li>Barn har rett til liv og helse </li>
                        <li>Barn har rett til skolegang og utvikling </li>
                        <li>Barn har rett til omsorg og beskyttelse </li>
                        <li>Barn har rett til deltakelse og innflytelse </li>
                    </ul>
                </p>

                <p>
                    Konvensjonen gir alle barn og unge under 18 år rett til å ha en trygg og god oppvekst, uansett hvem de er og hvor de bor. Vi i Brave Heart Stiftelsen tar dette på alvor og står opp og kjemper for barnas rettigheter.
                </p>

                <p>
                    I 2018 så vi behovet for et større hjem for våre barn og startet å bygge Pure Hope Children`s Home. Bygget har gutte- og jente avdeling, personalrom, fellesareal, lekeareal, trygge omgivelser, gode sanitærfasiliteter, innlagt strøm og vann, og lekeplass.
                </p>

            </div>
        ),
        eng: (
            <div>
                <h1>Children home</h1>
                <h3>Status 2021: 19 children, 4 employees, a social worker </h3>

                <p>
                    In our work in the Meheba refugee community, we often come across children who are not well.
                    Some children have lost their parents. Some children have parents who do not have the opportunity to provide security and care.
                    Some children have disabilities and live very challenging lives and without the possibility of a dignified life.
                    Some children are left to fend for themselves.  
                </p>

                <p>
                    In the Brave Heart Foundation, we can not look at this without doing something.
                    Therefore, we started an orphanage in 2014 to provide an offer to children who were having an extremely difficult time. 
                </p>

                <p>
                    The UN Convention on the Rights of the Child defines the following: 
                    <ul>
                        <li>Children have the right to life and health</li>
                        <li>Children have the right to education and development</li>
                        <li>Children have the right to care and protection</li>
                        <li>Children have the right to participation and influence</li>
                    </ul>
                </p>

                <p>
                    The convention gives all children and young people under the age of 18 the right to a safe and good upbringing, regardless of who they are and where they live.
                    We in the Brave Heart Foundation take this seriously and stand up and fight for children's rights. 
                </p>

               
                <p>
                    In 2018, we saw the need for a larger home for our children and started building the Pure Hope Children's Home.
                    The building has a boys 'and girls' department, staff rooms, common areas, play areas, safe surroundings, good sanitary facilities,
                    installed electricity and water, and playground. 
                </p>

            </div>
        )

    }

    return lang === 'eng' ? content.eng : content.nor
}
