import React from 'react'
import { LanguageContext } from '../../../context/language'
import { Row } from 'react-bootstrap'

// related images
import sgigave from '../files/gigave.jpeg'
import sfastgiver from '../files/fastgiver.jpeg'
import sfadder from '../files/fadder.jpg'
import sNodshjelpFond from '../files/nodshjelp.jpeg'
import sFundraiser from '../files/fundraiser.jpg'
import sBedrifts from '../files/bedrift.jpeg'

export default function Main() {
    const context = React.useContext(LanguageContext)
    const { lang } = context
    const content = {
        eng: (
            <div>
                <h1>Support Us</h1>
                <p>
                    There are many forgotten refugee camps around the world, Meheba refugee camp is one of them!
                    This is where we in the Brave Heart Foundation come in, our goal is to help 30.000 people who live in the camp.
                    Together with your help we can make a big difference!
                </p>
                <Row className='w-100 m-0'>
                    <Row className='støttDiv'>
                        <a href='https://www4.solidus.no/SolidusUser/placeorder/85344C54-3279-4A0D-9DF4-FBFAE2B46313'>
                            <img src={sgigave} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Give a gift</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/fastgiver'>
                            <img src={sfastgiver} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Become a regular doner</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/fadder'>
                            <img src={sfadder} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Become a sponsor</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/innsamling'>
                            <img src={sFundraiser} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Fundraiser</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/partner'>
                            <img src={sBedrifts} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Corporate collaboration</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/nodshjelp'>
                            <img src={sNodshjelpFond} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Support Emergency Fund</h5>
                        </a>
                    </Row>
                </Row>
            </div>
        ),
        nor: (
            <div>
                <h1>Støtt Arbeidet</h1>
                <p>
                    Det er mange glemte flyktningeleirer rundt om i verden, Meheba flyktningleir er en av dem!
                    Det er her vi i Brave Heart Stiftelsen kommer inn, vårt mål er å hjelpe ca 30.000 mennesker som bor i leiren.
                    Sammen med din hjelp kan vi gjøre store forskjeller!
                </p>
                <Row className='w-100 m-0'>
                    <Row className='støttDiv'>
                        <a href='https://www4.solidus.no/SolidusUser/placeorder/85344C54-3279-4A0D-9DF4-FBFAE2B46313'>
                            <img src={sgigave} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Gi en gave</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/fastgiver'>
                            <img src={sfastgiver} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Bli fastgiver</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/fadder'>
                            <img src={sfadder} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Bli fadder</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/innsamling'>
                            <img src={sFundraiser} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Fundraiser</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/partner'>
                            <img src={sBedrifts} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Bedriftssamarbeid</h5>
                        </a>
                    </Row>
                    <Row className='støttDiv'>
                        <a href='/nodshjelp'>
                            <img src={sNodshjelpFond} className="stottImages" alt='sgigave' height="280px" width="280px" style={{ objectFit: 'cover' }} loading='lazy' />
                            <h5 className='stottText text-white'>Støtt Nødhjelp Fond</h5>
                        </a>
                    </Row>
                </Row>
            </div>
        )
    }
    return lang === 'eng' ? content.eng : content.nor
}
