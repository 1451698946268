import React                from 'react'
import { LanguageContext }  from '../../../context/language'
import Navbar               from '../../navbar'
import                      '../css/vipps.css'

export default function Agreements() {
    // context 
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='webcontainer'>
                { lang === 'eng' ? 
                    <div className='vp_sdiv'>
                        <h1 className='textUnderlineRed'>Vipps Subscription Agreements</h1> 
                        <p className='mt-4'>
                            For more information, status, and changes to your vipps subscription, please contact us via the phone at +47 405 41 180. 
                            Or contact us via email on <a href="mailto:post@bheart.no">post@bheart.no</a>.
                        </p>
                    </div> :
                    <div className='vp_sdiv'>
                        <h1 className='textUnderlineRed'>Vipps Abonnementsavtaler</h1> 
                        <p className='mt-4'>
                            For mer informasjon, status og endringer i vipps-abonnementet, vennligst kontakt oss via telefon på +47 405 41 180. 
                            Eller kontakt oss via e-post på <a href="mailto:post@bheart.no">post@bheart.no</a>.
                        </p>
                    </div>
                }
            </div>          
        </div>
    )
}
