// REACT FRAMEWORK
import React from 'react'
import { LanguageContext } from '../../../context/language'
import {fred} from '../constants/navigation'
import Navbar from '../../navbar'
import m16 from '../files/m16.png'

export default function Utryddesult() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='bkt_Background'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p> 
            </div> 
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? fred.eng : fred.nor}
                    {lang === 'eng' ? <h1>Peace, justice and well-functioning institutions </h1> : <h1>Fred, rettiferdighet og velfungerende institusjoner</h1>}
                    <div>
                        <img src={m16} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>
                        {lang === 'eng' ? 
                            <p>
                                We are also concerned with peace and justice. This sustainability goal tells 
                                us that we must stop abuses against people and human trafficking. We must also 
                                work for peace, through equality and good relations in and between countries. 
                                In addition, all people must have access to legal protection.
                                Fortunately, Meheba's residents have access to justice, but many of them have 
                                fled war and serious abuses. That is why we are concerned with creating peace and 
                                security within the camp, because our friends in Meheba have the right to peace. We 
                                strongly want to give them that, which is why we have these <a href="/coming-sson">(LINK 5)</a> projects underway! 
                            </p>
                            : 
                            <p>
                                Vi er også opptatt av fred og rettferdighet. Dette bærekraftsmålet forteller oss 
                                at vi må stanse overgrep mot mennesker og menneskehandel. Vi må også jobbe for fred, 
                                gjennom likhet og gode relasjoner i og mellom land. I tillegg skal alle mennesker ha tilgang til rettsvern.  
                                Heldigvis har Mehebas innbyggere tilgang til rettsvern, men mange av dem har flyktet 
                                fra krig og alvorlige overgrep. Derfor er vi opptatte av å skape ro og trygghet innenfor leiren, 
                                for vennene våre i Meheba har rett til å få fred. Det ønsker vi sterkt å gi dem, 
                                derfor har vi disse <a href="/coming-soon">(LINK 5)</a> prosjektene i gang! 
                            </p> 
                        }  
                    </div>                  
                </div>
            </div>
        </div>
    )
}