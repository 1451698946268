import React from 'react'
import { LanguageContext } from '../../../context/language'

export default function SportsConstant() {
  const context = React.useContext(LanguageContext)
  const { lang } = context

  const content = {
    eng: (
      <div>
        <h1>Support for sports</h1>
        <p>
          Sport is actually something that is very important, also in a refugee camp. For people who have lost a lot, it is important to be able to gather around the good things in life, and we believe that sport is one of these. Sport spreads joy and provides unity in the camp. The Brave Heart Foundation, in collaboration with Molde Football Club, has therefore distributed several hundred uniforms and footballs, and has also helped to support local teams so that they have been able to play league matches in Zambia. One thing we are proud of is that together with support from the Brave Heart Foundation, Meheba Academy has actually made it up to the Zambian 1st division, which gives a lot of joy and commitment when there are matches in the camp.
        </p>
      </div>
    ),
    nor: (
      <div>
        <h1>Støtte til idrett</h1>
        <p>
          Idrett er faktisk noe som er kjempe viktig, også i en flyktningleir. For mennesker som har mistet mye, er det viktig å kunne samles rundt de gode tingene i livet, og vi tror at idrett er en av disse. Idrett sprer glede, og gir samhold i leiren. Brave Heart Stiftelsen har derfor i samarbeid med Molde Fotballklubb utdelt flere hundre drakter, fotballer, og har også vært med på å støtte lokale lag slik at de har kunnet spille ligakamper i Zambia. En stolthet vi har er at sammen med støtte fra Brave Heart Stiftelsen, så har Meheba Academy faktisk kommet seg opp til Zambisk 1. divisjon, som gir mye glede og engasjement når det er kamper i leiren.
        </p>
      </div>
    )
  }
  return lang === 'eng' ? content.eng : content.nor
}
