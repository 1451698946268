import React from "react";
import { OrderContext } from "../../../context/stripeOrder";
import Navbar from "../../navbar";

export default function Order(props) {
    // context
    const context = React.useContext(OrderContext);
    const { order, collectOrder } = context;

    // state and order
    const { orderId } = props;
    const getOrder = () => {
        if (orderId) return collectOrder(orderId);
    };

    // get order
    React.useEffect(() => {
        getOrder();
    });

    return (
        <div>
            <Navbar />
            <div className="webcontainer">
                <div className="pageDiv">
                    {order && order.error ? (
                        <div>
                            <h1 className="textUnderlineRed">Oi dette skulle ikke skje.</h1>
                            <p>Betalingen var stoppet, vennligst prøv igjen seinere.</p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            Doloremque inventore asperiores repudiandae fugit repellendus
                            error id ab sunt veritatis, voluptate veniam eligendi sit odit ex,
                            tempora dolor commodi ullam ipsum!
                        </div>
                    ) : order ? (
                        <div>
                            <h1 className="textUnderlineRed">Tusen takk for din støtte</h1>
                            <p>{order.description}</p>
                            <p>Du har gitt {order.amount / 100} Kr.</p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
                            molestiae natus, possimus hic reprehenderit cum laboriosam
                            explicabo repellendus quaerat, in sunt culpa aspernatur cumque
                            perspiciatis sint corporis numquam unde doloremque.
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
