import React from 'react'
import { fattigdom } from '../constants/navigation'
import { LanguageContext } from '../../../context/language'
import Navbar from '../../navbar'
import m1 from '../files/m1.png'

export default function Utryddefattigdom() {
    const context = React.useContext(LanguageContext)
    const {lang} = context

    return (
        <div>
            <Navbar/>
            <div className='navdisplace'>
                <div className='povertyBackground'/>
                <p className='fotoText'>{lang === 'eng' ? 'Our mission is to bring joy to all.' : 'Vårt oppdrag er å gi glede for alle.'}</p>
            </div>        
            <div className='row justify-content-center m-0'>
                <div className='pageDiv'>
                    {lang === 'eng' ? fattigdom.eng : fattigdom.nor}
                    <div>
                        {lang === 'eng' ? <h1>Eradicate poverty</h1> : <h1>Utrydde fattigdom</h1>}
                        <img src={m1} alt="fn_icons" width='200px' height='200px' className='m-2' loading='lazy'/>                        
                        { lang === 'eng' ? 
                            <p>
                                In 2018, the UN concluded that 770 million people live in extreme poverty.
                                Fortunately, this number has halved since 1990, but there is still a long way to go to eradicate poverty.
                                There is a lot of poverty in Meheba, and we are of the opinion that we must help them
                                wonderful people in Meheba to get out of it. With our help, including through
                                the work we are going to tell about below, we think it is possible! 
                            </p> : 
                            <p>
                                I 2018 konkluderte FN med at 770 millioner mennesker lever i ekstrem fattigdom. 
                                Dette tallet er heldigvis blitt halvert siden 1990, men det er enda et stykke å gå for å utrydde fattigdom.  
                                Det finnes mye fattigdom i Meheba, og vi er av den oppfattelsen at vi må hjelpe de 
                                fantastiske menneskene i Meheba å komme seg ut av den. Med vår hjelp, blant annet gjennom 
                                arbeidet vi skal fortelle om under, mener vi det er mulig! 
                            </p>
                        }                      
                    </div>
                </div>
            </div>   
        </div>
    )
}
