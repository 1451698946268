// REACT LIBRARY FRAMEWORK
import React from "react";

// THIRD PARTY COMPONENTS
import { Row } from "react-bootstrap";

// REACT ICONS
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi";
import { RiVimeoLine } from "react-icons/ri";

// COMPONENT
function Socialmedia() {
  return (
    <Row className="socialMediaIcons">
      <a href="https://twitter.com/bhstiftelsen">
        <span>
          <FiTwitter />
        </span>
      </a>
      <a href="https://www.facebook.com/bheart.no">
        <span>
          <FiFacebook />
        </span>
      </a>
      <a href="https://www.instagram.com/bheart.no/?igshid=1198kthuqt2h8">
        <span>
          <FiInstagram />
        </span>
      </a>
      <a href="https://vimeo.com/342356502">
        <span>
          <RiVimeoLine />
        </span>
      </a>
    </Row>
  );
}

export default Socialmedia;
