import React from 'react'
import { Result } from 'antd'
import { LanguageContext } from '../../context/language'
import Navbar from '../navbar'
import './css/404.css'

function Notfound() {
    const context = React.useContext(LanguageContext)
    const {lang} = context
    return (
        <div>
            <Navbar/>
            <div className='webcontainer'>
                <div className='row justify-content-center m-0'>
                    <div className='NOTFOUND'>
                        <h1>404</h1>
                        <Result
                            status="404"
                            title={lang === 'eng' ? 'Sorry, the page you are looking for does not exist.': "Beklager, siden du leter etter eksisterer ikke. " }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notfound
