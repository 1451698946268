import React               from 'react'
import { LanguageContext } from '../../../context/language'

export function AgreementSuccess() {
    const context          = React.useContext(LanguageContext)
    const { lang }         = context
    const content          = {
        eng: (
            <div>
                <h1 className='textUnderlineRed'>Velkommen som støttespiller</h1>
                <h3>Tusen takk for at du har valg å bli fastgiver for å hjelpe flyktninger i nød.</h3>
                <p>Du vil om kort tid motta en velkomstpakke fra Brave Heart Stiftelsen i posten. Som fastgiver betaler et fast beløp hver måned gjennom ditt registrert debit eller kredittkort. Du vil motta informasjon fra vårt arbeid i flyktningleir Meheba i Zambia, slik at du kan se at pengene dine kommer frem og få eksempler på hva de brukes til.</p>
                <p>Ni av ti kroner, altså 90 prosent, går til å oppfylle Brave Heart stiftelsens oppdrag, nemlig å hjelpe mennesker på flukt fra krig og konflikt. Med din støtte i ryggen vil flere mennesker på flukt få den hjelpen de har krav på som medmennesker og du er med i kampen om utrydde fattigdom, bekjempe  alle former av ulikheter og urett, og fremme verdighet.</p>
                <ul>
                    <li>Du bidrar til å gi flyktninger gratis helsebehandling, traumabearbeiding og omsorg for enslige eldre i leiren.</li>
                    <li>Du bidrar til å skaffe rent vann og gi matsikkerhet, og forhindrer dermed sykdom og sult i leiren.</li>
                    <li>Du bidrar til rettshjelp slik at jenter som har blitt utsatt for grove seksuelle overgrep og voldtekt kan få advokats bistand, bo på omsorgssenter og få rehabilitering tjenester.</li>
                    <li>Sist men ikke minst, bidrar du til utdanning av barn og unge, noe som gir håp og trygghet for fremtiden.</li>
                </ul>
                <h3>SKATTEFRADRAG FOR BIDRAG OVER 500 KR</h3>
                <p>
                    Bidrag over 500 kroner i løpet av et kalenderår til Brave Heat Stiftelsen gir rett til skattefradrag. 
                    (Maksimalt beløp for skattefradrag er 30 000 kroner i året). Hvis du registrerer ditt fødselsnummer vil 
                    din gave automatisk bli sendt til ligningsmyndighetene. Kontakt oss gjerne for å gjøre dette på telefon 40541180 (Hverdager mellom 09:00 og 15:00)
                </p>
            </div>
        ),
        nor: (
            <div>
                <h1 className='textUnderlineRed'>Velkommen som støttespiller</h1>
                <h3>Tusen takk for at du har valg å bli fastgiver for å hjelpe flyktninger i nød.</h3>
                <p>Du vil om kort tid motta en velkomstpakke fra Brave Heart Stiftelsen i posten. Som fastgiver betaler et fast beløp hver måned gjennom ditt registrert debit eller kredittkort. Du vil motta informasjon fra vårt arbeid i flyktningleir Meheba i Zambia, slik at du kan se at pengene dine kommer frem og få eksempler på hva de brukes til.</p>
                <p>Ni av ti kroner, altså 90 prosent, går til å oppfylle Brave Heart stiftelsens oppdrag, nemlig å hjelpe mennesker på flukt fra krig og konflikt. Med din støtte i ryggen vil flere mennesker på flukt få den hjelpen de har krav på som medmennesker og du er med i kampen om utrydde fattigdom, bekjempe  alle former av ulikheter og urett, og fremme verdighet.</p>
                <ul>
                    <li>Du bidrar til å gi flyktninger gratis helsebehandling, traumabearbeiding og omsorg for enslige eldre i leiren.</li>
                    <li>Du bidrar til å skaffe rent vann og gi matsikkerhet, og forhindrer dermed sykdom og sult i leiren.</li>
                    <li>Du bidrar til rettshjelp slik at jenter som har blitt utsatt for grove seksuelle overgrep og voldtekt kan få advokats bistand, bo på omsorgssenter og få rehabilitering tjenester.</li>
                    <li>Sist men ikke minst, bidrar du til utdanning av barn og unge, noe som gir håp og trygghet for fremtiden.</li>
                </ul>
                <h3>SKATTEFRADRAG FOR BIDRAG OVER 500 KR</h3>
                <p>
                    Bidrag over 500 kroner i løpet av et kalenderår til Brave Heat Stiftelsen gir rett til skattefradrag. 
                    (Maksimalt beløp for skattefradrag er 30 000 kroner i året). Hvis du registrerer ditt fødselsnummer vil 
                    din gave automatisk bli sendt til ligningsmyndighetene. Kontakt oss gjerne for å gjøre dette på telefon 40541180 (Hverdager mellom 09:00 og 15:00)
                </p>
            </div>
        ),
    }
    return lang === 'eng' ? content.eng : content.nor
}
